import RedirectTo from 'RedirectTo'
import 'antd/dist/antd.css'
import { getAuthToken } from 'helpers'
import fetchHelper from 'helpers/FetchHelper'
import { languages } from 'language/config'
import { PrivateLayout, PublicLayout } from 'layouts'
import Page404 from 'modules/404'
import AccountActivities from 'modules/AccountActivities'
import AccountActivity from 'modules/AccountActivity'
import TrackerEdit from 'modules/ManngeChangeTrackerEdit'
import AccountSetting from 'modules/acc'
import ForgotPassword from 'modules/auth/pages/ForgotPassword'
import ResetPassword from 'modules/auth/pages/ResetPassword'
import SignIn from 'modules/auth/pages/Signin'
import SignupV2 from 'modules/auth/pages/Signup-v2'
import VerifyCode from 'modules/auth/pages/VerifyCode'
import ComingSoon from 'modules/comingSoon'
import Contact from 'modules/contact'
import Dashboard from 'modules/dashboard'
import ProjectHistory from 'modules/history'
import Home from 'modules/home'
import PendingSubs from 'modules/pendingSubs'
import PrimaryKYC from 'modules/primaryKYC'
import ProjectAction from 'modules/projectAction'
import ProjectList from 'modules/projectList'
import ProjectManage from 'modules/projectManage'
import Notification from 'modules/projectNotification'
import SelectPackage from 'modules/selectPackage'
import Settings from 'modules/settings'
import WalletEVM from 'modules/walletEVM'
import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getListCountry } from 'state/app/actions'
import { useLanguage } from 'state/language/hooks'
import { getProfile } from 'state/user/actions'
import { getChainList, getCurrency, getWalletAddress } from 'state/wallets/actions'
import './assets/css/global.scss'
import { G_SITE_KEY } from 'constants/index'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const isLogin = () => {
  const authToken = getAuthToken()
  if (authToken) {
    fetchHelper.addDefaultHeader('Authorization', `Bearer ${authToken}`)
  }
  return Boolean(authToken)
}

const DefaultRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PublicLayout
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...rest} isLogin={isLogin()}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PublicLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogin() ? (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/wallet', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PrivateLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

function App() {
  const dispatch = useDispatch()
  const { language, setLanguage } = useLanguage()

  // useConfigRecaptcha()
  const hadValidToken = isLogin()
  useEffect(() => {
    setLanguage(language)
  }, [language])

  useEffect(() => {
    if (hadValidToken) {
      dispatch(getChainList())
      dispatch(getListCountry())
      dispatch(getProfile())
    }
  }, [dispatch, hadValidToken])

  /**
   * Fetch wallet
   */
  useEffect(() => {
    if (hadValidToken) {
      dispatch(getCurrency({ page: 1, pageSize: 100 }))
      dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
    }
  }, [dispatch, hadValidToken])

  return (
    <>
      <ToastContainer />
      <GoogleReCaptchaProvider
        reCaptchaKey={G_SITE_KEY}
        scriptProps={{
          async: true, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <IntlProvider locale={language || 'en'} messages={languages[language]}>
          <Router>
            <Switch>
              {/* All */}
              {/* <DefaultRoute exact path="/" component={SignIn} /> */}
              <DefaultRoute exact path="/404" component={Page404} />
              <DefaultRoute exact path="/coming-soon" component={ComingSoon} />

              {/* Public */}
              <DefaultRoute exact path="/register" component={SignupV2} />

              {/* <DefaultRoute exact path="/register" component={SignUp} /> */}
              <PublicRoute exact path="/login" component={SignIn} />
              <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
              <PublicRoute exact path="/reset-password" component={ResetPassword} />
              <PublicRoute exact path="/verify-code" component={VerifyCode} />
              {/* <PublicRoute exact path="/new-password" component={NewPassword} /> */}
              {/* <PublicRoute exact path="/staking" component={HomeStaking} /> */}

              {/* Private */}
              {/* <PrivateRoute exact path="/account" component={Account} /> */}
              <PrivateRoute exact path="/contact" component={Contact} />
              {/* <PrivateRoute exact path="/private-sale" component={PrivateSalePage} /> */}
              {/* <PrivateRoute exact path="/wallet-test" component={Wallet} /> */}
              {/* <PrivateRoute exact path="/swap" component={Swap} /> */}
              {/* <PrivateRoute exact path="/wallet/deposit/:id" component={Deposit} />
            <PrivateRoute exact path="/wallet/history" component={History} />
            <PrivateRoute exact path="/wallet/withdraw/:id" component={Withdraw} /> */}
              {/* <PrivateRoute exact path="/stake/stake-history" component={StakeHistory} /> */}
              {/* <PrivateRoute exact path={['/stake', '/stake/:id']} component={Stake} /> */}
              {/* <PrivateRoute exact path="/security" component={Settings} /> */}
              <PrivateRoute exact path="/security/primary-kyc" component={PrimaryKYC} />
              <PrivateRoute exact path="/security/account-activity" component={AccountActivity} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/settings" component={AccountSetting} />
              <PrivateRoute exact path="/settings/security" component={Settings} />
              <PrivateRoute exact path="/settings/account-activities" component={AccountActivities} />
              <PrivateRoute exact path="/project" component={ProjectList} />
              <PrivateRoute exact path="/project/start" component={SelectPackage} />
              <PrivateRoute exact path="/project/manage/:id" component={ProjectManage} />
              <PrivateRoute exact path="/account" component={AccountSetting} />
              <PrivateRoute exact path="/project/address" component={TrackerEdit} />
              <PrivateRoute exact path="/project/history" component={ProjectHistory} />
              <PrivateRoute exact path="/affiliate" component={WalletEVM} />
              <PrivateRoute exact path="/project/webhook/:id" component={Notification} />
              <PrivateRoute exact path="/project/telegram/:id" component={Notification} />
              {/* <PrivateRoute exact path="/project/start/:id" component={ProjectManagePackage} />
            <PrivateRoute exact path="/project/expire" component={ProjectManagePackage} /> */}
              <PrivateRoute exact path="/project/:detail" component={ProjectAction} />
              {/* <PrivateRoute exact path="/project/create" component={ProjectManagePackage} /> */}
              {/* <PrivateRoute exact path="/project/update/:id" component={SelectPackage} /> */}
              <PrivateRoute exact path="/project/create" component={ProjectAction} />
              <PrivateRoute exact path="/subscription/:action" component={PendingSubs} />
              {/* Other */}
              <Route path="" component={RedirectTo} />
            </Switch>
          </Router>
        </IntlProvider>
      </GoogleReCaptchaProvider>
    </>
  )
}

export default App
