import { all, takeLatest, call, put } from 'redux-saga/effects'
import qs from 'query-string'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'
import * as Types from './constants'
import * as actions from './actions'

/* ===================================== */
function getPrivateSaleListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/packageSale/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getPrivateSaleList({ payload }) {
  try {
    const { data } = yield call(getPrivateSaleListFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getPrivateSaleListSuccess(data.data))
    }
  } catch (error) {
    console.error('getPrivateSaleList', error)
  }
}
/* ===================================== */
function getPrivateSaleDetailFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/orderPrivateSale/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getPrivateSaleDetail({ payload }) {
  try {
    const { data } = yield call(getPrivateSaleDetailFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getPrivateSaleDetailSuccess(data.data))
    } else {
      yield put(actions.getPrivateSaleDetailSuccess(null))
    }
  } catch (error) {
    yield put(actions.getPrivateSaleDetailSuccess(null))
    console.error('getPrivateSaleDetail', error)
  }
}
/* ===================================== */
function buyPrivateSaleFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/orderPrivateSale/buy`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* buyPrivateSale({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(buyPrivateSaleFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    if (cbe) cbe(error)
    console.error('buyPrivateSale', error)
  }
}
/* ===================================== */
function getPrivateSaleTransactionFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/orderPrivateSale/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getPrivateSaleTransaction({ payload }) {
  try {
    const { data } = yield call(getPrivateSaleTransactionFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getPrivateSaleTransactionSuccess(data.data))
    } else {
      yield put(actions.getPrivateSaleTransactionSuccess(null))
    }
  } catch (error) {
    yield put(actions.getPrivateSaleTransactionSuccess(null))
    console.error('getPrivateSaleTransaction', error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(Types.GET_PRIVATE_SALE_LIST, getPrivateSaleList),
    takeLatest(Types.GET_PRIVATE_SALE_DETAIL_SUCCESS, getPrivateSaleDetail),
    takeLatest(Types.BUY_PRIVATE_SALE, buyPrivateSale),
    takeLatest(Types.GET_PRIVATE_TRANSACTION, getPrivateSaleTransaction),
  ])
}
