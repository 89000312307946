import { all, takeLatest, call, put } from 'redux-saga/effects'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'
import * as types from './constants'
import * as actions from './actions'

function subscriptionPackage(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/subscription`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([res, status]) => ({ data: res, status }))
}

function* subscription({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(subscriptionPackage, payload)
    if (data.code === 200) {
      if (cbs) cbs(data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('create package', error)
    if (cbe) cbe(error)
  }
}
function approveSubscriptionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/approveSubscription`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([res, status]) => ({ data: res, status }))
}

function* approveSubscription({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(approveSubscriptionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('create package', error)
    if (cbe) cbe(error)
  }
}
function upgradeSubscriptionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/upgrade`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([res, status]) => ({ data: res, status }))
}

function* upgradeSubscription({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(upgradeSubscriptionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('create package', error)
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.SUBSCRIPTION, subscription),
    takeLatest(types.APPROVE_SUBSCRIPTION, approveSubscription),
    takeLatest(types.UPGRADE_SUBSCRIPTION, upgradeSubscription),
  ])
}
