import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.07008 3V5.51375M15.9299 3V5.51375M3.83327 15.2485C3.38891 13.3097 3.38891 11.292 3.83327 9.35323C4.44858 6.66865 6.49673 4.57251 9.11985 3.94279C11.0142 3.48802 12.9858 3.48802 14.8801 3.94279C17.5033 4.57251 19.5514 6.66865 20.1667 9.35323C20.6111 11.292 20.6111 13.3097 20.1667 15.2485C19.5514 17.9331 17.5033 20.0292 14.8801 20.6589C12.9858 21.1137 11.0142 21.1137 9.11986 20.6589C6.49673 20.0292 4.44858 17.9331 3.83327 15.2485Z"
      stroke="#2E6DFF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M3.83327 15.2485C3.38891 13.3097 3.38891 11.292 3.83327 9.35323C4.44858 6.66865 6.49673 4.57251 9.11985 3.94279C11.0142 3.48802 12.9858 3.48802 14.8801 3.94279C17.5033 4.57251 19.5514 6.66865 20.1667 9.35323C20.6111 11.292 20.6111 13.3097 20.1667 15.2485C19.5514 17.9331 17.5033 20.0292 14.8801 20.6589M8.07008 3V5.51375M15.9299 3V5.51375"
      stroke="#003e9d"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7.5 10.8571L8.5 11.7143L10.5 10M7.5 15.1429L8.5 16L10.5 14.2857M13 11.2857H16.5M13 15.5714H16.5"
      stroke="#2E6DFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Icon
