import React from 'react'
import { Button } from 'antd'
import './styles.scss'
import { Link } from 'react-router-dom'

const Project = () => (
  <div className="dashboard-project-container">
    <div className="proj-left">
      <div className="proj-img">
        <img src="images/dashboard/Vector.png" alt="" />
      </div>
      <div className="proj-info">
        <div className="proj-info_title">
          <span>Start New Project</span>
        </div>
        <div className="proj-info_text">
          <span>$0</span>
        </div>
        <div className="proj-info_desc">
          <span>Start tracking address transfer</span>
        </div>
      </div>
    </div>
    <div className="proj-right">
      <Button type="primary">
        <Link to="/project">
          <span className="btn-proj-txt">Start</span>
        </Link>
      </Button>
    </div>
  </div>
)

export default Project
