import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import { DECIMALS } from 'constants/index'
import { EyeClosedIcon, EyeIcon, EyeOpenIcon, EyeSleepIcon } from 'widgets/Svg'
import { isNumber, roundNumber } from 'helpers'

const TotalBalance = ({ baseToken, ...props }) => {
  const { currency } = useSelector((state) => state.wallets)
  const [show, setShow] = useState(false)
  const totalBalance = useMemo(() => {
    if (currency) {
      // return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
      const Usd = currency.find((i) => i.code === 'USD')
      return Usd.balance
    }
    return undefined
  }, [currency])
  // console.log(totalBalance)
  return (
    <div className="total-balance" {...props}>
      <div onClick={() => setShow((prev) => !prev)} role="presentation">
        <span>
          {show && isNumber(totalBalance) ? (
            <>
              <CurrencyFormat
                value={roundNumber(totalBalance / 10 ** DECIMALS)}
                displayType="text"
                thousandSeparator
                prefix={window.innerWidth >= 568 ? 'Balance: $ ' : '$'}
              />
            </>
          ) : (
            <div className="hide-balance">
              <p className="balance-txt">Balance: $</p> <span style={{ marginTop: '7px', display: 'block' }}>******</span>
            </div>
          )}
        </span>
        {show ? <EyeOpenIcon /> : <EyeClosedIcon />}
      </div>
    </div>
  )
}

export default TotalBalance
