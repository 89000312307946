import * as types from './constants'

export const getAddressList = (payload) => ({
  type: types.GET_ADDRESS_LIST,
  payload,
})
export const getAddressListSuccess = (payload) => ({
  type: types.GET_ADDRESS_LIST_SUCCESS,
  payload,
})
export const createAddress = (payload, cbs, cbe) => ({
  type: types.CREATE_ADDRESS,
  payload,
  cbs,
  cbe,
})
export const createAddressSuccess = (payload) => ({
  type: types.CREATE_ADDRESS_SUCCESS,
  payload,
})
export const deleteAddress = (payload, cbs, cbe) => ({
  type: types.DELETE_ADDRESS,
  payload,
  cbs,
  cbe,
})
