import * as types from './constants'

export const getInvoiceList = (payload) => ({
  type: types.GET_INVOICE_LIST,
  payload,
})
export const getInvoiceListSuccess = (payload) => ({
  type: types.GET_INVOICE_LIST_SUCCESS,
  payload,
})
