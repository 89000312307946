import * as types from './constants'

export const uploadFile = (payload, cbs, cbe) => ({
  type: types.UPLOAD_FILE,
  payload,
  cbs,
  cbe,
})

export const fileData = null
