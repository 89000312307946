/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import ProfileAccount from 'components/ProfileAccount'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import TotalBalance from '../TotalBalance'
import './style.scss'
import BackLink from 'components/BackLink'
import { MenuClosedIcon, MenuOpenIcon } from 'widgets/Svg'
import { Select } from 'antd'
import { useLanguage } from 'state/language/hooks'
import { OPTIONS_LANG } from 'language/config'

const PrivateHeader = ({ links, isMobile, isLogin, showMenu, toggleMenu }) => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.user)
  const history = useHistory()
  const { currency } = useSelector((state) => state.wallets)
  const baseToken = useMemo(() => currency?.find((item) => item.code === 'ZUKI'), [currency])

  const pathName = history.location.pathname.split('/')

  const pathNameTitle = () => {
    if (pathName.includes('stake-history')) {
      return (
        <>
          <div className="header-go-back">
            <BackLink label={<h2 style={{ fontSize: '20px', whiteSpace: 'nowrap' }}>Staking History</h2>} href="/stake" />
          </div>
        </>
      )
    }
    if (pathName.includes('stake')) {
      return <h2>Stake</h2>
    }
    if (pathName.includes('primary-kyc')) {
      return (
        <>
          <div className="header-go-back">
            <BackLink label={<h2>Primary KYC</h2>} href="/security" />
          </div>
        </>
      )
    }
    if (pathName.includes('account-activity')) {
      return (
        <>
          <div className="header-go-back">
            <BackLink label={<h2 style={{ fontSize: '20px', whiteSpace: 'nowrap' }}>Account Activity</h2>} href="/security" />
          </div>
        </>
      )
    }
    if (pathName.includes('security')) {
      // return <h2>Security</h2>
    }
    if (pathName.includes('history')) {
      return (
        <>
          <div className="header-go-back">
            <BackLink label={<h2>History</h2>} href="/wallet" />
          </div>
        </>
      )
    }
    if (pathName.includes('deposit')) {
      return (
        <>
          <div className="header-go-back">
            <BackLink label={<h2>Deposits</h2>} href="/wallet" />
          </div>
        </>
      )
    }
    if (pathName.includes('withdraw')) {
      return (
        <>
          <div className="header-go-back">
            <BackLink label={<h2>Withdraw</h2>} href="/wallet" />
          </div>
        </>
      )
    }
    // if (pathName.includes('wallet')) {
    //   return <h2>Wallet</h2>
    // }
    if (pathName.includes('account')) {
      return <h2>Account</h2>
    }
    if (pathName.includes('private-sale')) {
      return <h2>Private Sale</h2>
    }
  }
  const optionSelect = OPTIONS_LANG.map((item) => item)
  const { language, setLanguage } = useLanguage()
  const handleChange = (value) => {
    setLanguage(value)
  }

  return (
    <header className="private-header-mobile">
      <div className="header-left">
        <Link to="/">
          <div className="panel-logo">
            <img style={{ width: '100%' }} src="/images/logo-text.png" title="Evm Tracker" alt="Evm Tracker" />
          </div>
        </Link>
      </div>
      <div className="header-right">
        <TotalBalance baseToken={baseToken} style={{ marginRight: window.innerWidth >= 768 ? '23px' : '4px' }} />
        {isMobile && (
          <div className="background-cover-img">
            <span>
              <img
                src={userInfo?.profile?.avatar ? userInfo?.profile?.avatar : '/images/user-default.png'}
                alt=""
                title=""
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = '/images/user-default.png'
                }}
              />
            </span>
          </div>
        )}

        <ProfileAccount hideName={isMobile} style={{ marginLeft: window.innerWidth >= 768 ? '23px' : '10px' }} />

        {/* {isMobile && <MenuClosedIcon onClick={toggleMenu} />} */}
        {/* {isMobile && showMenu && (
          <div className="collapse-icon">
            <input type="checkbox" id="menu" checked={showMenu} onChange={toggleMenu} />
            <label htmlFor="menu" className="icon">
              <div className="menu" />
            </label>
          </div>
        )} */}
        {!isMobile && (
          <div className="header-logo">
            <img style={{ width: '28px' }} src="/images/languages.png" title="Eng UK" alt="Eng UK" />
            {/* <span style={{ fontWeight: 700, color: '#0F1B36', fontSize: '16px' }}>Eng UK</span> */}

            <Select
              onChange={handleChange}
              dropdownClassName="language-setting"
              defaultValue={language}
              style={{
                width: 60,
              }}
            >
              {optionSelect.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.id}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      </div>
      <div className="menu-content-mobile" data-visible={showMenu.toString()}>
        {isMobile && showMenu && (
          <div className="collapse-icon">
            <input type="checkbox" id="menu" checked={showMenu} onChange={toggleMenu} />
            <label htmlFor="menu" className="icon">
              <div className="menu" />
            </label>
          </div>
        )}
        <div className="menu-body">
          <ul className="menu-link">
            {links.map((item, index) => {
              const isHttp = item?.href?.startsWith('http')
              const Tag = isHttp ? 'a' : Link
              const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
              return (
                <li
                  key={`menu-mb-${index}`}
                  className={history.location.pathname === item.href ? 'active' : ''}
                  role="presentation"
                  onClick={toggleMenu}
                >
                  <Tag {...propsLink} rel="noreferrer">
                    {item.label}
                  </Tag>
                </li>
              )
            })}
          </ul>
          {!isLogin && (
            <div className="menu-actions-mobile">
              <Link to="/register" onClick={toggleMenu}>
                <ButtonSecondary
                  size="larger"
                  style={{
                    color: history.location.pathname === '/register' ? '#000' : '#fff',
                    background: history.location.pathname === '/register' ? '#fff' : 'transparent',
                  }}
                >
                  Sign up
                </ButtonSecondary>
              </Link>
              <Link to="/login" onClick={toggleMenu}>
                <ButtonPrimary
                  size="larger"
                  style={{
                    color: history.location.pathname === '/login' ? '#000' : '#fff',
                    background: history.location.pathname === '/login' ? '#fff' : 'transparent',
                  }}
                >
                  Sign in
                </ButtonPrimary>
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
export default PrivateHeader
