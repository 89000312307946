import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.7721 0C2.59393 0 0.136072 7.08964 0.113572 7.16143L0 7.5L0.1125 7.83857C0.136072 7.91036 2.59393 15 10.7721 15C18.9504 15 21.4082 7.91036 21.4307 7.83857L21.5443 7.5L21.4318 7.16143C21.4082 7.08964 18.9504 0 10.7721 0ZM10.7721 11.7857C8.40857 11.7857 6.48643 9.86357 6.48643 7.5C6.48643 5.13643 8.40857 3.21429 10.7721 3.21429C13.1357 3.21429 15.0579 5.13643 15.0579 7.5C15.0579 9.86357 13.1357 11.7857 10.7721 11.7857Z"
      fill="#F3F8FF"
    />
    <path d="M11 6C9.916 6 9 6.687 9 7.5C9 8.313 9.916 9 11 9C12.084 9 13 8.313 13 7.5C13 6.687 12.084 6 11 6Z" fill="#F3F8FF" />
    <path d="M6.60254 4.75L14.9996 10" stroke="#F3F8FF" strokeLinecap="round" />
  </Svg>
)

export default Icon
