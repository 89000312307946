import produce from 'immer'
import * as types from './constants'

const initialState = {
  subscription: {},
}

export default function Subscription(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SUBSCRIPTION_SUCCESS:
        draft.subscription = payload
        break
      case types.APPROVE_SUBSCRIPTION:
        draft.subscription = payload
        break
      case types.UPGRADE_SUBSCRIPTION:
        draft.subscription = payload
        break

      default:
        return { ...state }
    }
  })
}
