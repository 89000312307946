import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
// import { Form } from 'antd'
import './styles.scss'

import { setAuthToken, executeRecaptchaV2, executeRecaptcha } from 'helpers'
import AuthCard from 'modules/auth/components/AuthCard'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import ButtonPrimary from 'widgets/ButtonPrimary'
// import Input from 'widgets/Input'
import { Checkbox, Col, Form, Input, Row } from 'antd'
import FormItem from 'widgets/FormItem'
import VerifyAuthenCode from 'modules/auth/components/VerifyAuthenCode'
import VerifyEmailCode from 'modules/auth/components/VerifyEmailCode'
import { getProfile } from 'state/user/actions'
import { login } from 'state/auth/actions'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const SignIn = ({ history, location }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [formValue, setFormValue] = useState({})
  const [view, setView] = useState({ view: 0, dataView: null })
  const [loading, setLoading] = useState(false)

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true)
      dispatch(
        login(
          { ...values },
          (receipt) => {
            if (receipt.is_email) {
              setView({ view: 1, dataView: receipt })
            }
            if (receipt.is_2fa) {
              setView({ view: 2, dataView: receipt })
            }
            setFormValue(values)
            setLoading(false)
          },
          (error) => {
            if (error?.code === 'err_email_active_required') {
              history.push('/register', { view: 1, email: values.email })
            }
            setLoading(false)
          },
        ),
      )
    })
  }

  const onFinishVerifyCode = useCallback(
    (receipt) => {
      const authToken = setAuthToken(receipt?.token)
      if (authToken) {
        dispatch(
          getProfile({}, () => {
            if (get(location, 'state.from')) {
              const pathName = get(location, 'state.from.pathname')
              const search = get(location, 'state.from.search')
              history.push(`${pathName}${search}`)
            } else {
              history.push('/wallet')
            }
          }),
        )
      }
    },
    [dispatch, history, location],
  )

  const onBack = useCallback(() => {
    setView({ view: 0, dataView: null })
  }, [])

  return (
    <AuthWrapper>
      <div className="wrapper-form-login">
        {view.view === 0 && (
          <AuthCard className="form-login fadeIn">
            <Form layout="vertical" form={form}>
              <FormItem
                label={<span className="form-title">Email</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter email field',
                  },
                ]}
              >
                <Input placeholder="evmtracker1@gmail.com" />
              </FormItem>
              <FormItem
                label={
                  <div className="wrapper-label">
                    <span className="form-title">Password</span>
                    <Link to="/forgot-password" className="des-title">
                      Forgot Password ?
                    </Link>
                  </div>
                }
                name="password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter password field',
                  },
                  {
                    min: 10,
                    message: `The 'password' field length must be greater than or equal to 10 characters long.`,
                  },
                ]}
              >
                <Input.Password
                  placeholder="**********"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
                {/* <Input
                  placeholder="**********"
                  type="password"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                /> */}
              </FormItem>
              <FormItem>
                <ButtonPrimary type="submit" className="form-action" loading={loading} onClick={onFormSubmit}>
                  SIGN IN
                </ButtonPrimary>
              </FormItem>
            </Form>
            <div className="form-links">
              <span style={{ color: '#003e9d' }}>Don’t have an account ?</span>
              <Link to="/register">Sign up now</Link>
            </div>
          </AuthCard>
        )}
        {view.view === 1 && (
          <VerifyEmailCode
            type="login"
            formValue={formValue}
            token={view.dataView?.token}
            onFinish={onFinishVerifyCode}
            onBack={onBack}
          />
        )}
        {view.view === 2 && <VerifyAuthenCode type="login" token={view.dataView?.token} onFinish={onFinishVerifyCode} />}
      </div>
    </AuthWrapper>
  )
}

export default withGoogleReCaptcha(SignIn)
