import produce from 'immer'
import * as types from './constants'

const initialState = {
  blockchainList: {
    data: undefined,
    page: 0,
    total: 0,
  },
}

export default function BlockchainList(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_BLOCKCHAIN_LIST_SUCCESS:
        draft.blockchainList = payload
        break

      default:
        return { ...state }
    }
  })
}
