export const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const getBase64 = async (img) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.readAsDataURL(img)
  })

export const onLoadImage = (url) =>
  new Promise((resolve, reject) => {
    if (!url) reject(new Error('Uri is null'))
    if (typeof url !== 'string' || !url?.startsWith('http')) reject(new Error('Is not uri'))
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
  })
