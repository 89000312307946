import Page from 'components/App/Page'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { getAddressList } from 'state/address/actions'
import { getBlockchainList } from 'state/blockChainList/actions'
import { getDetailProject } from 'state/project/actions'
import Advance from './components/advance'
import ManageTop from './components/file'
import ManageTracker from './components/manageChainTracker'
import Notification from './components/notification'
import ManagePackage from './components/package'
import SecretKey from './components/secretKey'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const ProjectManage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addressList } = useSelector((state) => state.address)
  const { blockchainList } = useSelector((state) => state.blockchainList)
  const [projectDetail, setProjectDetail] = useState(null)
  const { userInfo } = useSelector((state) => state.user)
  const pathName = history.location.pathname.split('/')[3]
  useEffect(() => {
    dispatch(getBlockchainList())
    dispatch(
      getDetailProject({ project_id: pathName }, (receipt) => {
        setProjectDetail(receipt)
      }),
    )
    // if (!addressList[pathName]) {
    //   dispatch(getAddressList({ project_id: pathName }))
    // }
  }, [addressList, dispatch, pathName])

  useEffect(() => {
    if (projectDetail?.status === 'NEW' || projectDetail?.status === 'EXPIRED') {
      history.push(`/project/detail?project_id=${projectDetail?._id}`, { hasPackageFree: history.location.state?.hasPackageFree })
    }
  }, [history, projectDetail?._id, projectDetail?.status])

  const currentPackage = projectDetail?.package

  return (
    <Page>
      <ManageTop projectInfo={projectDetail} />
      <ManagePackage
        hasPackageFree={history.location.state?.hasPackageFree}
        projectInfo={projectDetail}
        packageInfo={currentPackage}
      />
      <ManageTracker addressList={addressList} chainList={blockchainList} projectInfo={projectDetail} />
      <Notification projectInfo={projectDetail} />
      <SecretKey userInfo={userInfo} projectId={projectDetail?._id} />
      <Advance userInfo={userInfo} projectId={projectDetail?._id} />
    </Page>
  )
}

export default withGoogleReCaptcha(ProjectManage)
