import { all, takeLatest, call, put, take, takeEvery } from 'redux-saga/effects'
import qs from 'query-string'
import fetchHelper from 'helpers/FetchHelper'
import * as types from './constants'
import * as actions from './actions'
import { ROOT_API_URL } from 'constants/index'

async function getProjectListFromApi(payload) {
  const qStr = qs.stringify(payload)
  // return fetchHelper
  //   .fetch(`${ROOT_API_URL}/evmProject/list?${qStr}`, {
  //     method: 'GET',
  //   })

  const result = await fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/list?${qStr}`, {
      method: 'GET',
    })
    .then(([res, status]) => ({
      data: res,
      status,
    }))
    .catch((err) => {})
  return result
}
function* getProjectList({ payload }) {
  try {
    const { data } = yield call(getProjectListFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getProjectListSuccess(data.data))
    }
  } catch (error) {
    console.error('getTransactionList', error)
  }
}

function getProjectStatusFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/staticData/data?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getProjectStatus({ payload }) {
  try {
    const { data } = yield call(getProjectStatusFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getProjectStatusSuccess(data.data))
    }
  } catch (error) {
    console.error('getListStatus', error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(types.GET_PROJECTS_LIST, getProjectList), takeLatest(types.GET_PROJECT_STATUS, getProjectStatus)])
}
