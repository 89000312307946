import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.931 6.936L7.206 11.185M12.813 16.794L17.064 18.069M11.683 12.317L17.442 6.558M4 5.5C4 5.69698 4.0388 5.89204 4.11418 6.07403C4.18956 6.25601 4.30005 6.42137 4.43934 6.56066C4.57863 6.69995 4.74399 6.81044 4.92597 6.88582C5.10796 6.9612 5.30302 7 5.5 7C5.69698 7 5.89204 6.9612 6.07403 6.88582C6.25601 6.81044 6.42137 6.69995 6.56066 6.56066C6.69995 6.42137 6.81044 6.25601 6.88582 6.07403C6.9612 5.89204 7 5.69698 7 5.5C7 5.30302 6.9612 5.10796 6.88582 4.92597C6.81044 4.74399 6.69995 4.57863 6.56066 4.43934C6.42137 4.30005 6.25601 4.18956 6.07403 4.11418C5.89204 4.0388 5.69698 4 5.5 4C5.30302 4 5.10796 4.0388 4.92597 4.11418C4.74399 4.18956 4.57863 4.30005 4.43934 4.43934C4.30005 4.57863 4.18956 4.74399 4.11418 4.92597C4.0388 5.10796 4 5.30302 4 5.5ZM17 5.5C17 5.89782 17.158 6.27936 17.4393 6.56066C17.7206 6.84196 18.1022 7 18.5 7C18.8978 7 19.2794 6.84196 19.5607 6.56066C19.842 6.27936 20 5.89782 20 5.5C20 5.10218 19.842 4.72064 19.5607 4.43934C19.2794 4.15804 18.8978 4 18.5 4C18.1022 4 17.7206 4.15804 17.4393 4.43934C17.158 4.72064 17 5.10218 17 5.5ZM17 18.5C17 18.8978 17.158 19.2794 17.4393 19.5607C17.7206 19.842 18.1022 20 18.5 20C18.8978 20 19.2794 19.842 19.5607 19.5607C19.842 19.2794 20 18.8978 20 18.5C20 18.1022 19.842 17.7206 19.5607 17.4393C19.2794 17.158 18.8978 17 18.5 17C18.1022 17 17.7206 17.158 17.4393 17.4393C17.158 17.7206 17 18.1022 17 18.5ZM4 15.5C4 16.6935 4.47411 17.8381 5.31802 18.682C6.16193 19.5259 7.30653 20 8.5 20C9.69347 20 10.8381 19.5259 11.682 18.682C12.5259 17.8381 13 16.6935 13 15.5C13 14.3065 12.5259 13.1619 11.682 12.318C10.8381 11.4741 9.69347 11 8.5 11C7.30653 11 6.16193 11.4741 5.31802 12.318C4.47411 13.1619 4 14.3065 4 15.5Z"
      stroke="#2E6DFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.813 16.794L17.064 18.069M17 18.5C17 18.8978 17.158 19.2794 17.4393 19.5607C17.7206 19.842 18.1022 20 18.5 20C18.8978 20 19.2794 19.842 19.5607 19.5607C19.842 19.2794 20 18.8978 20 18.5C20 18.1022 19.842 17.7206 19.5607 17.4393C19.2794 17.158 18.8978 17 18.5 17C18.1022 17 17.7206 17.158 17.4393 17.4393C17.158 17.7206 17 18.1022 17 18.5ZM4 15.5C4 16.6935 4.47411 17.8381 5.31802 18.682C6.16193 19.5259 7.30653 20 8.5 20C9.69347 20 10.8381 19.5259 11.682 18.682C12.5259 17.8381 13 16.6935 13 15.5C13 14.3065 12.5259 13.1619 11.682 12.318C10.8381 11.4741 9.69347 11 8.5 11C7.30653 11 6.16193 11.4741 5.31802 12.318C4.47411 13.1619 4 14.3065 4 15.5Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />{' '}
  </Svg>
)
export default Icon
