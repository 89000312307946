import ModalCustom from 'components/BaseModal'
import InputCode from 'components/InputCode'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resendAuthenLoginAccountCode, verifyEmail } from 'state/auth/actions'
import { verifyDelete } from 'state/project/actions'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'
import { executeRecaptchaV2 } from 'helpers'

const initialField = 6
const ModalDeleteProject = ({ deleteProject, projectId, type, token, visible, onCancel, isGoogleAuth, ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  // const [code, onChangeCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [codeValue, setCodeValue] = useState('')
  const [errorMess, setErrorMess] = useState('')
  const [timeCountDown, setTimeCountDown] = useState()

  const onSubmit = (value) => {
    const code = Object.values(value || codeValue).filter((o) => o)
    if (code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters from email of you.`)
    } else {
      setErrorMess('')

      dispatch(
        verifyDelete({ project_id: projectId, code: +code.join('') }, (receipt) => {
          history.push('/project')
        }),
      )
    }
  }

  const onChangeCode = (value) => {
    setCodeValue(value)
    // const code = Object.values(value).filter((o) => o)
    // if (code.length >= initialField) {
    //   onSubmit(value)
    // }
  }

  const resendCode = () => {
    switch (type) {
      case 'deleteProject':
        setTimeCountDown(50)
        dispatch(deleteProject({ project_id: projectId }))

        break
      default:
        break
    }
  }
  useEffect(() => {
    if (visible) {
      setTimeCountDown(50)
    }
  }, [visible])

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])
  return (
    <ModalCustom
      centered
      onCancel={() => onCancel(false)}
      visible={Boolean(visible)}
      title={
        <div
          className="modal-verify-cody-header"
          style={{ textAlign: 'left', fontSize: window.innerWidth >= 768 ? '32px' : '24px' }}
        >
          Delete Project
        </div>
      }
    >
      <h2 className="verify-title-delete">Are you sure about this?</h2>
      <p className="modal-delete-text">
        When you delete the project all associated addresses, webhook, and Telegram group ID will be deleted and will not be
        recoverable after 24 hours.
      </p>
      <p className="modal-delete-title">{!isGoogleAuth ? 'Verification code' : 'Google Authentication'}</p>
      <InputCode value={codeValue} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
      {!isGoogleAuth && (
        <div className="verify-resend">
          <div style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>Resend in {timeCountDown}s</div>
          {timeCountDown <= 0 && (
            <a onClick={resendCode} role="presentation">
              Resend
            </a>
          )}
        </div>
      )}
      {/* <div className="verify-error">{errorMess}</div> */}
      <div className="generate-confirm">
        <ButtonPrimary onClick={onSubmit}>Confirm</ButtonPrimary>
      </div>
    </ModalCustom>
  )
}
ModalDeleteProject.defaultProps = {
  //   initialField: 6,
  //   onFinish: () => null,
  //   onBack: () => null,

  type: 'deleteProject',
}

export default React.memo(ModalDeleteProject)
