import { ROOT_API_URL } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import { take } from 'lodash'
import qs from 'query-string'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { contactActions } from './slice'

const { getMainIssueList, getMainIssueListSuccess, createContact } = contactActions

function getMainIssueListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/mainIssue/list${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}

function* getIssueList({ payload }) {
  try {
    const { data } = yield call(getMainIssueListFromApi, payload)

    if (data.code === 200) {
      yield put(getMainIssueListSuccess(data.data.rows))
    }
  } catch (error) {
    console.error('getIssue', error)
  }
}

function createSupportTicketFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/supportTicket/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}

function* createSupportTicket(action) {
  try {
    const { payload, cbs, cbe } = action.payload
    const { data } = yield call(createSupportTicketFromApi, payload)

    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('createSupportTicket', error)
  }
}

export default function* rootSaga() {
  yield all([takeLatest(getMainIssueList.type, getIssueList), takeLatest(createContact.type, createSupportTicket)])
}
