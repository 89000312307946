import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import './styles.scss'
import { from } from 'rxjs'

const Social1 = [
  {
    id: 1,
    icons: '/images/Home/banner/App1.png',
  },
  {
    id: 2,
    icons: '/images/Home/banner/App2.png',
  },
  {
    id: 3,
    icons: '/images/Home/banner/App3.png',
  },
]
const Social2 = [
  {
    id: 1,
    icons: '/images/Home/banner/App4.png',
  },
  {
    id: 2,
    icons: '/images/Home/banner/App5.png',
  },
]
const Banner = () => {
  const { userInfo } = useSelector((state) => state.user)
  return (
    <div className="warp-banner">
      <div className="group_banner">
        <div className="group-left">
          <img src="/images/Home/banner/imgLeft.png" alt="" />
          <p>And more EVM coming soon</p>
        </div>

        <div className="group-right" style={{ backgroundImage: "url('/images/Home/banner/imgRight.png')", height: '517px' }}>
          <div className="app-top">
            {Social1.map((icon, index) => (
              <Link key={icon.id}>
                <div className="ellipse">
                  <img className="ellipse-border" src="/images/Home/banner/Ellipse.png" alt="" />
                  <img className="ellipse-img" src={icon.icons} alt="" />
                </div>
              </Link>
            ))}
          </div>
          <div className="app-bottom">
            {Social2.map((icon, index) => (
              <Link key={icon.id}>
                <div className="ellipse">
                  <img className="ellipse-border" src="/images/Home/banner/Ellipse.png" alt="" />
                  <img className="ellipse-img" src={icon.icons} alt="" />
                </div>
              </Link>
            ))}
          </div>
          <p>And more EVM coming soon</p>
        </div>
      </div>
    </div>
  )
}

export default Banner
