import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './style.scss'
import { resendVerifyWithdrawCode } from 'state/wallets/actions'

/**
 * @dev Required body data by resend action
 * Type === login => required body data { transaction_id }
 * Type === register => required body data { code }
 */
const ResendCode = ({ type, countDown, data, className, ...props }) => {
  const dispatch = useDispatch()

  const [timeCountDown, setTimeCountDown] = useState(countDown)

  const resendCode = () => {
    switch (type) {
      case 'login':
        dispatch(
          resendVerifyWithdrawCode(
            {
              transaction_id: data._id,
            },
            () => {
              setTimeCountDown(50)
            },
          ),
        )
        break
      default:
        break
    }
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])
  return (
    <div className={`resend-code ${className}`} {...props}>
      <p className="coundown-send-code" style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>
        Resend in {timeCountDown}s
      </p>

      {timeCountDown <= 0 && (
        <p onClick={resendCode} role="presentation">
          Resend
        </p>
      )}
    </div>
  )
}

ResendCode.defaultProps = {
  type: 'login',
  className: '',
  countDown: 50,
}

export default React.memo(ResendCode)
