import Page from 'components/App/Page'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAddressList } from 'state/address/actions'
import { getDetailProject } from 'state/project/actions'
import Addresses from './components/addresses'
import FindAddress from './components/findAddress'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const TrackerEdit = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addressList } = useSelector((state) => state.address)

  const [projectDetail, setProjectDetail] = useState(null)
  const [currentTracking, setCurrentTracking] = useState(null)
  const projectId = history.location.state?.project_id
  const chainCode = history.location.state?.chain_code
  const titleAddress = history.location.state?.title
  useEffect(() => {
    dispatch(
      getDetailProject({ project_id: projectId }, (receipt) => {
        setProjectDetail(receipt)
      }),
    )
    // if (!addressList[projectId]) {
    //   dispatch(getAddressList({ project_id: projectId, chainCode }))
    //   // setCurrencySorting(addressList[projectInfo?._id]?.rows)
    // }
  }, [addressList, dispatch, projectId])
  return (
    <Page>
      <Addresses
        titleAddress={titleAddress}
        currentTracking={addressList.total}
        projectInfo={projectDetail}
        chainCode={chainCode}
      />
      <FindAddress addressList={addressList} setCurrentTracking={setCurrentTracking} projectInfo={projectDetail} />
    </Page>
  )
}

export default withGoogleReCaptcha(TrackerEdit)
