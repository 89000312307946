import { all, takeLatest, call, put } from 'redux-saga/effects'
import * as types from './constants'
import * as actions from './actions'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'

function getProfileFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/profile`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function getKycBuyAuthToken() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/kyc/find`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getProfile({ cbs, cbe }) {
  try {
    const [userInfo, kyc] = yield all([call(getProfileFromApi), call(getKycBuyAuthToken)])
    let mappingData = {}
    if (userInfo.data.code === 200) {
      mappingData = {
        ...mappingData,
        ...userInfo.data.data,
      }
    }
    if (kyc.data.code === 200) {
      mappingData = {
        ...mappingData,
        kycInfo: kyc.data.data,
      }
    }
    if (userInfo.data.code === 200) {
      yield put(actions.getProfileSuccess(mappingData))
      if (cbs) cbs(mappingData)
    } else if (cbe) cbe(mappingData)
  } catch (error) {
    console.error('getProfile', error)
    if (cbe) cbe(error)
  }
}

function updateProfileFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/update-profile`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateProfile({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateProfileFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('updateProfile', error)
    if (cbe) cbe(error)
  }
}

function updateKycFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/kyc/request`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateKyc({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateKycFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getProfileSuccess(data.data))
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('updateKyc', error)
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.GET_PROFILE, getProfile),
    takeLatest(types.UPDATE_PROFILE, updateProfile),
    takeLatest(types.UPDATE_KYC, updateKyc),
  ])
}
