import { combineReducers } from 'redux'
import App from './app/reducer'
import Auth from './auth/reducer'
import User from './user/reducer'
import Language from './language/reducer'
import Home from './home/reducer'
import Wallets from './wallets/reducer'
import Stake from './stake/reducer'
import Transactions from './transactions/reducer'
import File from './file/reducer'
import PrivateSale from './privateSale/reducer'
import Exchange from './exchange/reducer'
import Contact from './contact/slice'
import Package from './package/reducer'
import ProjectList from './projectList/reducer'
import Project from './project/reducer'
import Subscription from './subscription/reducer'
import Address from './address/reducer'
import BlockchainList from './blockChainList/reducer'
import Report from './report/reducer'
import Invoice from './invoice/reducer'

export default combineReducers({
  app: App,
  auth: Auth,
  home: Home,
  user: User,
  language: Language,
  wallets: Wallets,
  stake: Stake,
  transactions: Transactions,
  file: File,
  privateSale: PrivateSale,
  exchange: Exchange,
  contact: Contact,
  package: Package,
  projectList: ProjectList,
  project: Project,
  subscription: Subscription,
  address: Address,
  blockchainList: BlockchainList,
  report: Report,
  invoice: Invoice,
})
