import React from 'react'
import './styles.scss'
import { Row, Col, Carousel } from 'antd'
import TitleSection from '../titleSection'

const data = [
  {
    id: 1,
    name: 'EVM Builder',
    image: '/images/Home/partners/evmbuilder.png',
  },
  {
    id: 2,
    name: 'QuickNode',
    image: '/images/Home/partners/quicknode.svg',
  },
  {
    id: 3,
    name: 'XBUILDAPP',
    image: '/images/Home/banner/xbuildapp.png',
  },
]

const OurParter = () => (
  <div className="warp-partner">
    <TitleSection title="OUR PARTNER" />
    <div className="warp-bg" style={{ backgroundImage: "url('/images/Home/banner/bg-partner.png')" }}>
      <div className="bg_data">
        <Carousel
          rows={3}
          dots={false}
          arrows
          prevArrow={<img src="/images/Home/banner/prevArrow.png" alt="" />}
          nextArrow={<img src="/images/Home/banner/nextArrow.png" alt="" />}
        >
          {data.map((item, index) => (
            <div key={index} className="bg_items">
              <h1>{item.name}</h1>
              <img src={item.image} alt="" />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
    {/* mobile */}
    <div
      className="warp-bg-mobile"
      style={{
        backgroundImage: "url('/images/Home/banner/banner-mobile.png')",
      }}
    >
      <Carousel
        rows={3}
        dots={false}
        arrows
        prevArrow={<img src="/images/Home/banner/arr-mb-left.png" alt="" />}
        nextArrow={<img src="/images/Home/banner/arr-mb-right.png" alt="" />}
      >
        {data.map((item, index) => (
          <div key={index} className="bg_items">
            <h1>{item.name}</h1>
            <img src={item.image} alt="" />
          </div>
        ))}
      </Carousel>
    </div>
  </div>
)

export default OurParter
