import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 4H18.25M1.75 10H18.25M1.75 16H18.25M4 6.25L1.75 4L4 1.75"
      stroke="#0093E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Icon
