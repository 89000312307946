import React from 'react'
import './projectItem.scss'
import { roundNumber } from 'helpers'
import { DECIMALS } from 'components/constants'

const ProjectItem = ({ item }) => {
  const {
    title,
    price_month: price,
    limit_address: limit,
    transaction_watcher: trans,
    transfer_event: transfer,
    webhook,
    telegram_bot: bot,
  } = item
  return (
    <div
      style={{
        padding: '0px 8px 16px',
      }}
    >
      <div className="itemData">
        <h2>Package</h2>
        <div>{title}</div>
      </div>
      <div className="itemData">
        <h2>Pricing</h2>
        <div>${roundNumber(price / 10 ** DECIMALS, 6)}</div>
      </div>
      <div className="itemData">
        <h2>Limit Addresses</h2>
        <div>{limit}</div>
      </div>
      <div className="itemData">
        <h2>Transaction Watcher</h2>
        <div>{trans}</div>
      </div>
      <div className="itemData">
        <h2>ERC20 Transfer</h2>
        <div>
          {transfer ? (
            <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />
          ) : (
            <img style={{ width: '22px' }} src="/images/Home/banner/false.png" alt="" />
          )}
        </div>
      </div>
      <div className="itemData">
        <h2>Webhook</h2>
        <div>
          {webhook ? (
            <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />
          ) : (
            <img style={{ width: '22px' }} src="/images/Home/banner/false.png" alt="" />
          )}
        </div>
      </div>

      <div className="itemData">
        <h2>Telegram Bot</h2>
        <div>
          {bot ? (
            <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />
          ) : (
            <img style={{ width: '22px' }} src="/images/Home/banner/false.png" alt="" />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectItem
