import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import BackLink from 'components/BackLink'
import { HistoryTable } from 'components/AccountActiveTable'
import { getHistoryLogin } from 'state/auth/actions'
import useIsMobile from 'hooks/useIsMobile'

const Form = () => {
  const isMobile = useIsMobile()

  const { rows, total } = useSelector((state) => state.transactions.transactionsList)
  const { historyLogin } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  useEffect(() => {
    document.getElementById('private-content').scrollTo(0, 0)
  }, [])
  const [paramsHistory, setParamsHistory] = useState({
    page: 1,
    pageSize: 10,
  })
  useEffect(() => {
    dispatch(getHistoryLogin(paramsHistory))
  }, [dispatch, paramsHistory])

  return (
    <>
      <div className="Account-activity-warp-history-form">
        <div className="go-back">
          <BackLink label="Account Activity" href="/security" />
          {/* <Link to="/security/account-activity">
            <ButtonDisable className="btn-disable" style={{ width: '118px' }}>
              Log Out All
            </ButtonDisable>
          </Link> */}
        </div>

        <div className="history-form">
          <HistoryTable
            paramsHistory={paramsHistory}
            historyLogin={historyLogin}
            rows={rows}
            total={total}
            setParamsHistory={setParamsHistory}
            isMobile={isMobile}
          />
        </div>
      </div>
    </>
  )
}

export default Form
