// import { BugTwoTone } from '@ant-design/icons'
import Page from 'components/App/Page'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { approveSubscription } from 'state/subscription/actions'
import ButtonPrimary from 'widgets/ButtonPrimary'
import Loading from 'widgets/Loading/Loading'
import './styles.scss'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const PendingSubs = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const urlParams = new URLSearchParams(history.location.search)
  const subscriptionId = urlParams.get('subscription_id')
  const urlAction = history.location.pathname.split('/')[2]
  const dispatch = useDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(
      approveSubscription(
        { subscription_id: subscriptionId },
        () => setLoading(false),
        () => setLoading(false),
      ),
    )
  }, [dispatch, subscriptionId])

  return (
    <Page>
      <div className="pending-sub">
        {loading ? <Loading /> : <img src={`/images/pendingSubs/${urlAction}.png`} alt="" />}
        {!loading && <p>Your payment is {urlAction === 'success' ? 'successfully' : 'canceled'}</p>}
        {!loading && (
          <Link to="/project">
            <ButtonPrimary>Continue</ButtonPrimary>
          </Link>
        )}
      </div>
    </Page>
  )
}

export default withGoogleReCaptcha(PendingSubs)
