import { Button } from 'antd'
import { DECIMALS } from 'constants/index'
import { roundNumber } from 'helpers'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { subscription, upgradeSubscription } from 'state/subscription/actions'
import './styles.scss'

const PackagePayment = ({ action, packageLevel, data, time, img, isUpgrade, hasPackageFree, projectId }) => {
  const [toggle, setToggle] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const timeNow = Math.floor(Date.now() / 1000)
  const startDate = timeNow
  let endDate
  if (time === 'MONTH') {
    const setTime = timeNow * 1000
    const date = new Date(setTime)
    date.setMonth(date.getMonth() + 1)
    endDate = date.getTime()
  } else if (time === 'YEAR') {
    const setTime = timeNow * 1000
    const date = new Date(setTime)
    date.setFullYear(date.getFullYear() + 1)
    endDate = date.getTime()
  }
  const handleSendData = () => {
    if (isUpgrade.hasData) {
      if (packageLevel === 1) {
        dispatch(
          subscription(
            { project_id: projectId, package_id: data._id, quantity: 1, type: time },
            (receipt) => {
              const { data } = receipt.data.orderPaypal
              // window.location.replace(data.links[0].href)
              window.open(data.links[0].href)
            },
            () => {
              history.push('/project')
            },
          ),
        )
      } else {
        dispatch(
          upgradeSubscription(
            { project_id: projectId, package_id: data._id },
            (receipt) => {
              const data = receipt.data.orderPaypal
              // window.location.replace(data?.links[0].href)
              window.open(data.links[0].href, '_blank', 'noopener,noreferrer')
            },
            () => {
              // history.push('/project')
            },
          ),
        )
      }
      // history.push({
      //   pathname: '/project/update',
      //   state: { packageCurrent: data, updateName, nameCurrent, projectId, type: time },
      // })
    }
    if (action === 'package') {
      history.push({
        pathname: `/project/detail?project_id=${projectId}`,
        state: { packageCurrent: data, type: time, startDate, endDate },
      })
      console.log('history', history)
    } else if (action === 'create') {
      const dataCreate = { packageCurrent: data, type: time, startDate, endDate }
      history.push('/project/create', dataCreate)
      // history.push({ pathname: '/project/create', state: { packageCurrent: data, type: time } })
    }
  }
  return (
    <div className="package-payment-wrapper">
      <div className="package-top">
        <div className="package-top_left">
          <div className="package-top_left_img">
            <img src={data.img ? data.img : img} alt="" />
          </div>
          <div className="package-top_left_detail">
            <div className="package-top_left_detail_title">
              <span>{data.title}</span>
            </div>
            <div className="package-top_left_detail_value">
              <span>
                $
                {time === 'YEAR'
                  ? roundNumber(data.price_year / 10 ** DECIMALS, 6)
                  : roundNumber(data.price_month / 10 ** DECIMALS, 6)}
                / {time === 'YEAR' ? 'YEAR' : 'MONTH'}
              </span>
            </div>
            <div className="package-top_left_detail_desc">
              <span>Max {data.limit_address} addresses</span>
            </div>
          </div>
        </div>
        {/* <div className="package-top_button">
          {hasPackageFree && data.title !== 'Free' && <Button onClick={handleSendData}>Select & Continue</Button>}
          {!hasPackageFree && <Button onClick={handleSendData}>Select & Continue</Button>}
        </div> */}
        <div className="package-top_button">
          {hasPackageFree && data.title === 'Free' && (
            <Button disabled style={{ opacity: '0.6' }} onClick={handleSendData}>
              Select & Continue
            </Button>
          )}
          {hasPackageFree && data.title !== 'Free' && <Button onClick={handleSendData}>Select & Continue</Button>}
          {!hasPackageFree && <Button onClick={handleSendData}>Select & Continue</Button>}
        </div>
      </div>
      <div className={`package-info ${toggle ? 'active' : ''}`}>
        <ul>
          <li>
            IPN (webhook){' '}
            {data.webhook ? (
              <span>
                <img src="/images/package/checked.svg" alt="" />
              </span>
            ) : (
              <span />
            )}
          </li>
          <li>
            Tele group message{' '}
            {data.telegram_bot ? (
              <span>
                <img src="/images/package/checked.svg" alt="" />
              </span>
            ) : (
              <span />
            )}
          </li>
          <li>
            Token Transfer Event{' '}
            {data.nft_transfer_event ? (
              <span>
                <img src="/images/package/checked.svg" alt="" />
              </span>
            ) : (
              <span />
            )}
          </li>
          <li>
            Native Token Send{' '}
            {data.transfer_event ? (
              <span>
                <img src="/images/package/checked.svg" alt="" />
              </span>
            ) : (
              <span />
            )}
          </li>
          <li>
            Target address: From, To, Both
            {/* <span>
              <img src="/images/package/checked.svg" alt="" />
            </span> */}
          </li>
        </ul>
      </div>
      <div className="package-expland" role="presentation" onClick={() => setToggle(!toggle)}>
        {toggle ? <img src="/images/package/up-expland.svg" alt="" /> : <img src="/images/package/down-expland.svg" alt="" />}
      </div>
    </div>
  )
}

export default PackagePayment
