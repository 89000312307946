import { Button } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import './styles.scss'

const Block = ({ hasPackageFree }) => {
  const history = useHistory()
  return (
    <div className="project-content">
      <div className="project-right">
        <div className="project-content_img">
          <img src="/images/project/star.png" alt="" />
        </div>
      </div>
      <div className="project-left">
        <div className="project-left_info">
          <div className="project-left_info_title">
            <span>Start New Project</span>
          </div>
          <div className="project-left_info_value">
            <span>$0</span>
          </div>
          <div className="project-left_info_desc">
            <span>Start tracking address transfer</span>
          </div>
        </div>
        <div className="project-button">
          <Button
            // type="primary"
            // onClick={() => history.push({ pathname: '/project/start', state: { ...history.location.state, hasPackageFree } })}
            onClick={() => history.push('/project/start', { action: 'create', hasPackageFree })}
          >
            Start
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Block
