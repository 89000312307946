import produce from 'immer'
import * as types from './constants'

const initialState = {
  invoiceList: {},
}

export default function Invoice(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_INVOICE_LIST_SUCCESS:
        draft.invoiceList = payload
        break

      default:
        return { ...state }
    }
  })
}
