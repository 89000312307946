import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { linksPrivate, linksPublic } from 'components/Menu/config'
import './style.scss'
import PrivateHeader from './components/PrivateHeader'
import useIsMobile from 'hooks/useIsMobile'
import PrivateFooter from './components/PrivateFooter'
import NavigationMobile from 'components/NavigationMobile'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { LogoutIcon } from 'widgets/Svg'
import { useDispatch } from 'react-redux'
import { logout } from 'state/auth/actions'
import useIsDesktop from 'hooks/useIsDesktop'

const PrivateLayout = ({ isLogin, children }) => {
  const location = useLocation()
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const dispatch = useDispatch()
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => {
    setShowMenu((prev) => !prev)
    document.querySelector('body').style.overflow = showMenu ? 'unset' : 'hidden'
  }
  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <div className="private-layout-container">
      {isMobile && (
        <div className="collapse-icon">
          <input type="checkbox" id="menu" checked={showMenu} onChange={toggleMenu} />
        </div>
      )}
      {/* <div className="header">
        <Link to="/">
          <div className="panel-logo">
            <img style={{ width: '100%' }} src="/images/logo-text.png" title="Evm Tracker" alt="Evm Tracker" />
          </div>
        </Link>
        <PrivateHeader links={linksPrivate} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} />
      </div> */}
      <PrivateHeader links={linksPrivate} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} />
      <div className="panel-content">
        <div className="panel-container">
          <div className="panel-body">
            <div className="panel-top">
              <ul className="panel-link">
                {linksPrivate.map((item, index) => {
                  const isHttp = item?.href?.startsWith('http')
                  const Tag = isHttp ? 'a' : Link
                  const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
                  const Icon = item.icon
                  return (
                    <li
                      key={`panel-mb-${index}`}
                      className={location.pathname.split('/').includes(item.label.toLowerCase()) ? 'active' : ''}
                    >
                      <Tag {...propsLink} rel="noreferrer">
                        {item.icon && <Icon />}
                        {item.label}
                      </Tag>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="panel-footer">
              {/* <div className="panel-footer" style={{ marginLeft: '30px' }}>
                <img src="/images/clock.png" title="Diamond wallets" alt="Diamond wallets" />
              </div>
              <a className="" href="https://beurl.app/zodi-telegram-group" target="_blank" rel="noreferrer">
                <ButtonPrimary style={{ maxWidth: '150px' }}>JOIN NOW</ButtonPrimary>
              </a> */}
              {/* <Link className="term-privacy" to="/#" target="_blank">
                Terms & Services Privacy Policy
              </Link>
              <div className="version">Version Beta 0.1</div> */}
              {/* <div className="panel-footer_img">
                <img src="/images/panel/panel-1.png" alt="" />
              </div>
              <div className="panel-footer_line" />
              <div className="panel-footer_logout">
                <LogoutIcon onClick={handleLogout} />
                <div className="panel-footer_logout-txt" role="presentation" onClick={handleLogout}>
                  <span>Logout</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="private-content" id="private-content" style={{ overflow: showMenu ? 'hidden' : 'auto' }}>
          {/* <PrivateHeader links={linksPrivate} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} /> */}
          {children}
          <PrivateFooter links={linksPublic} location={location} isLogin={isLogin} />
        </div>
      </div>

      {/* <PrivateFooter links={linksPublic} location={location} isLogin={isLogin} /> */}

      {!isDesktop && <NavigationMobile showMenu={showMenu} toggleMenu={toggleMenu} />}
    </div>
  )
}

export default PrivateLayout
