/* eslint-disable no-case-declarations */
import produce from 'immer'
import * as types from './constants'
import { CACHE_CHAIN_LIST } from 'constants/index'

const chainList = JSON.parse(localStorage.getItem(CACHE_CHAIN_LIST))

const FAKE_NATIVE_TOKEN_BY_CHAIN = {
  '6243432f1e5349c0f40dd4da': 'BNB',
  '62aea6d867c10159005c5270': 'BTC',
  '62446dd96da4123185bb9595': 'MATIC',
  '62aeedfa67c10159005c5291': 'BCH',
  '62aeee3867c10159005c5292': 'BCD',
  '62aeee8667c10159005c5293': 'ETH',
  '62aeeec767c10159005c5294': 'TRX',
  '62aeeefb67c10159005c5295': 'XRP',
}

const initialState = {
  chainList,
  currency: undefined,
  balance: undefined,
  currencyAttr: undefined,
  walletsAddress: undefined,
}

export default function WalletsReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_CHAIN_LIST_SUCCESS:
        draft.chainList = payload
        draft.chainMapCode = Object.values(FAKE_NATIVE_TOKEN_BY_CHAIN)
        break
      case types.GET_CURRENCY_SUCCESS:
        draft.currency = payload
        break
      case types.FETCH_WALLET_BALANCE_SUCCESS:
        const { currency } = state
        const currencyWithBalance = currency.map((item) => ({
          ...item,
          usdValue: (payload[item.code] * item.usd_rate) / 1e18,
          balance: payload[item.code],
        }))
        draft.currency = currencyWithBalance.sort((a, b) => b.balance * b.usd_rate - a.balance * a.usd_rate)
        draft.balance = payload
        break
      case types.GET_CURRENCY_ATTR_SUCCESS:
        draft.currencyAttr = payload.map((item) => ({ ...item, native_token: FAKE_NATIVE_TOKEN_BY_CHAIN[item.blockchain] }))
        break
      case types.GET_WALLET_ADDRESS_SUCCESS:
        draft.walletsAddress = payload
        break

      default:
        return { ...state }
    }
  })
}
