import { Col, Row } from 'antd'
import './style.scss'
import InputCopyLink from 'components/FielCopy/InputCopy'
import { APP_URL } from 'constants/index'
import { FormattedMessage } from 'react-intl'

export default function ReferrerCard({ className, userInfo }) {
  const codeSponsor = userInfo?.ref_code
  const link = `${APP_URL}register?sponsorKey=${userInfo?.ref_code}`

  return (
    <div className={`wrap-card-ref ${className}`}>
      <div className="box-info-sponserkey">
        <Row gutter={[50, 16]} style={{ marginBottom: 0 }}>
          <Col className="networks-ref" xs={24} sm={12}>
            <InputCopyLink value={link} title={<FormattedMessage id="Referral Link" />} />
          </Col>
          <Col className="networks-ref" xs={24} sm={12}>
            <InputCopyLink value={codeSponsor} title={<FormattedMessage id="Referral Code" />} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

ReferrerCard.defaultProps = {
  className: '',
}
