import produce from 'immer'
import * as types from './constants'

const initialState = {
  historyLogin: {
    rows: [],
    page: 1,
    total: 0,
  },
}

export default function HomeReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_HISTORY_LOGIN_SUCCESS:
        draft.historyLogin = payload
        break
      default:
        return { ...state }
    }
  })
}
