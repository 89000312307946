import './styles.scss'
import React from 'react'

const ControlInputText = ({ placeholder, onChange, label, ...props }) => (
  <div className="input-text-item">
    {label && <p>{label}</p>}
    <input placeholder={placeholder} onChange={(e) => onChange(e.target.value)} {...props} />
  </div>
)

ControlInputText.defaultProps = {
  placeholder: '',
  onChange: () => null,
}

export default ControlInputText
