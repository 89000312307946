import './styles.scss'
import React from 'react'
import { Link } from 'react-router-dom'
import Page from 'components/App/Page'
import Form from './component/form'
import ButtonDisable from 'widgets/ButtonDisable'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const AccountActivity = () => (
  <Page>
    <div className="warp-page-account-activity">
      <Form />
    </div>
    <Link
      style={{ display: window.innerWidth <= 991 ? 'block' : 'none' }}
      className="activity-btn-logout"
      to="/security/account-activity"
    >
      <ButtonDisable className="btn-disable" style={{ width: '130px', height: '35px' }}>
        Log Out All
      </ButtonDisable>
    </Link>
  </Page>
)

export default withGoogleReCaptcha(AccountActivity)
