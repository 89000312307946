import { DECIMALS } from 'constants/index'
import { roundNumber } from 'helpers'
import React, { useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import useIsMobile from 'hooks/useIsMobile'

const WalletCard = ({ currency, img, pending, text }) => {
  const isMobile = useIsMobile()
  const totalBalance = useMemo(() => {
    if (currency) {
      return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
    }
    return undefined
  }, [currency])
  // console.log(totalBalance)
  return (
    <div className="balance-card">
      {!isMobile ? (
        <>
          {/* <img data-status={pending} src={img} alt="" /> */}
          <div className="balance-card-header">
            <div className="balance-card-header-content">
              <p>{text}</p>
            </div>
          </div>
          <div className="balance-card-currency">
            <div className="balance-card-currency-content">
              <span>$</span>
              <CurrencyFormat value={roundNumber(totalBalance / 10 ** DECIMALS)} displayType="text" thousandSeparator />
            </div>
          </div>
        </>
      ) : (
        <>
          {/* <img data-status={pending} src={img} alt="" /> */}
          <div className="balance-card-currency">
            <div className="balance-card-currency-content">
              <span>$</span>
              <CurrencyFormat value={roundNumber(totalBalance / 10 ** DECIMALS)} displayType="text" thousandSeparator />
            </div>
          </div>
          <div className="balance-card-header">
            <div className="balance-card-header-content">
              <p>{text}</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default WalletCard
