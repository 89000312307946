import { Col, Form, Row } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import Page from 'components/App/Page'
import BackLink from 'components/BackLink'
import ControlInputSelect from 'components/ControlInputSelect'
import ControlInputText from 'components/ControlInputText'
import { dummyRequest, executeRecaptcha, getBase64 } from 'helpers'
import { get } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contactActions } from 'state/contact/slice'
import { uploadFile } from 'state/file/actions'
import ButtonPrimary from 'widgets/ButtonPrimary'
import FormItem from 'widgets/FormItem'
import './styles.scss'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Contact = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(contactActions.getMainIssueList())
  }, [dispatch])

  const { currency } = useSelector((state) => state.wallets)
  const { mainIssueList } = useSelector((state) => state.contact)
  const { userInfo } = useSelector((state) => state.user)

  const [loading, setLoading] = useState(false)
  const [showSelectToken, setShowSelectToken] = useState(false)
  const [imageData, setImageData] = useState(null)
  const [imageError, setImageError] = useState('')
  const [yourIssue, setYourIssue] = useState(null)
  const [yourSubIssue, setYourSubIssue] = useState(null)
  const [tokenId, setTokenId] = useState(null)
  const [customInput, setCustomInput] = useState({
    showInput: false,
    label: '',
    value: '',
    subIssues: [],
  })
  const optionsFromCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item._id,
        code: item.code,
      }))
    }
    return undefined
  }, [currency])

  const optionsFromIssue = useMemo(() => {
    if (mainIssueList) {
      return mainIssueList.map((item) => ({
        label: item.title,
        value: item._id,
        subIssues: item.subIssues.map((issue) => ({
          label: issue.title,
          value: issue._id,
        })),
      }))
    }
    return undefined
  }, [mainIssueList])

  const handleSelectChange = (value) => {
    const option = JSON.parse(value)

    form.setFieldsValue({
      sub_id: undefined,
      currrency: undefined,
    })

    setYourIssue(option.value)
    setTokenId(null)

    if (['638610b092a08c2a96c82fac'].includes(option.value)) {
      setShowSelectToken(true)
    } else {
      setShowSelectToken(false)
    }

    if (option.subIssues) {
      setCustomInput({
        showInput: true,
        ...option,
      })
    } else {
      setShowSelectToken(false)
      setTokenId(null)
      setCustomInput({
        showInput: false,
        subIssues: [],
        ...option,
      })
    }
  }

  const handleOnChangeImage = (e) => {
    if (e.file.status === 'uploading') {
      return
    }

    if (e.file.status === 'done') {
      getBase64(e.file.originFileObj).then(() => {
        setImageError('')

        dispatch(
          uploadFile(
            {
              key: 1,
              image: get(e, 'file.originFileObj', null),
            },
            (receipt) => {
              setImageData({
                ...receipt,
                image: receipt.full_link,
              })
            },
          ),
        )
      })
    }
  }

  const handleSubmitForm = () => {
    try {
      form.validateFields().then((values) => {
        if (!imageData) {
          setImageError('Error')
          return false
        }

        setLoading(true)

        const payload = {
          ...values,
          image: imageData?._id,
          main_id: yourIssue,
          sub_id: yourSubIssue,
          currrency: tokenId,
          full_name: `${userInfo?.profile?.fistname} ${userInfo?.profile?.lastname}`,
        }

        executeRecaptcha((recaptcha) => {
          dispatch(
            contactActions.createContact({
              payload: { ...payload, recaptcha },
              cbs: () => {
                setLoading(false)
              },
              cbe: (error) => {
                setLoading(false)
                console.log(error)
              },
            }),
          )
        })

        // Reset data
        form.resetFields()
        setShowSelectToken(false)
        setImageData(null)
        setImageError('')
        setYourIssue(null)
        setYourSubIssue(null)
        setTokenId(null)
        setCustomInput({
          showInput: false,
          label: '',
          value: '',
          subIssues: [],
        })
      })
    } catch (error) {
      setLoading(false)
      console.log('Submit failed:', error)
    }
  }

  return (
    <Page>
      <div className="contact-container">
        <div className="contact-header">
          <BackLink label="We are here to help !" icon={false} />
          <p className="sub-title">
            Please fill in the following form. Due to the high volume of request, our team replies within 1-2 hours.
          </p>
        </div>

        <div className="contact-body">
          <Form layout="vertical" form={form}>
            <Row gutter={[{ sm: 15, md: 32, lg: 57 }]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="input-width-pen">
                  <div className="box-input">
                    <FormItem
                      label={<span>Your email</span>}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your email',
                        },
                        {
                          type: 'email',
                          message: 'Email is not correct',
                        },
                      ]}
                    >
                      <ControlInputText placeholder="" />
                    </FormItem>
                  </div>
                </div>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="input-width-pen">
                  <div className="box-input">
                    <FormItem
                      label={<span>Choose your issue</span>}
                      name="main_id"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose your issue',
                        },
                      ]}
                    >
                      <ControlInputSelect
                        getPopupContainer={(trigger) => trigger.parentNode}
                        dataSelect={optionsFromIssue}
                        onChange={handleSelectChange}
                      />
                    </FormItem>
                  </div>
                </div>
              </Col>

              {customInput.showInput && (
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <div className="input-width-pen">
                    <div className="box-input">
                      <FormItem
                        label={<span>{customInput.label}</span>}
                        name="sub_id"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose your issue',
                          },
                        ]}
                      >
                        <ControlInputSelect
                          getPopupContainer={(trigger) => trigger.parentNode}
                          dataSelect={customInput.subIssues}
                          onChange={(v) => setYourSubIssue(v && JSON.parse(v).value)}
                        />
                      </FormItem>
                    </div>
                  </div>
                </Col>
              )}

              {showSelectToken && (
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <div className="input-width-pen">
                    <div className="box-input">
                      <FormItem
                        label={<span>Choose coin</span>}
                        name="currrency"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose issue token',
                          },
                        ]}
                      >
                        <ControlInputSelect
                          getPopupContainer={(trigger) => trigger.parentNode}
                          dataSelect={optionsFromCurrency}
                          onChange={(v) => setTokenId(v && JSON.parse(v).value)}
                        />
                      </FormItem>
                    </div>
                  </div>
                </Col>
              )}

              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="input-width-pen">
                  <div className="box-input">
                    <FormItem label={<span>Transaction hash or coin address</span>} name="transaction_hash">
                      <ControlInputText placeholder="" />
                    </FormItem>
                  </div>
                </div>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="input-width-pen">
                  <div className="box-input">
                    <FormItem label={<span>Subject</span>} name="subject">
                      <ControlInputText placeholder="" />
                    </FormItem>
                  </div>
                </div>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="input-width-pen">
                  <div className="box-input">
                    <FormItem label={<span>Description of the issue</span>} name="description">
                      <textarea id="description" placeholder="" rows="8" />
                    </FormItem>
                  </div>
                </div>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="input-file-item" style={{ maxWidth: '100%' }}>
                  <Dragger
                    customRequest={dummyRequest}
                    showUploadList={false}
                    accept=".jpg,.jpeg,.png"
                    style={{ border: imageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                    onChange={(e) => {
                      handleOnChangeImage(e)
                    }}
                  >
                    {imageData ? (
                      <img src={imageData.full_link} alt="" />
                    ) : (
                      <>
                        <p className="ant-upload-drag-icon">
                          <img src="/images/upload-file.png" alt="" />
                        </p>
                        <p className="ant-upload-text">Upload Your Image or File</p>
                      </>
                    )}
                  </Dragger>
                </div>
              </Col>
            </Row>
          </Form>

          <ButtonPrimary className="submit-btn" loading={loading} disabled={imageError} onClick={handleSubmitForm}>
            Submit
          </ButtonPrimary>
        </div>
      </div>
    </Page>
  )
}

export default withGoogleReCaptcha(Contact)
