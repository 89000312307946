import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { linksPublic } from 'components/Menu/config'
import FooterAuth from '../FooterAuth'
import './styles.scss'
import ButtonSecondary from 'widgets/ButtonSecondary'
import ButtonPrimary from 'widgets/ButtonPrimary'
import WalletLogin from 'components/WalletLogin'

const AuthWrapper = ({ children }) => {
  const [openForm, setOpenForm] = useState(true)
  const location = useLocation()

  const handleSetOpenForm = () => {
    setOpenForm(true)
  }

  return (
    <div className="auth-container">
      {/* <FooterAuth links={linksPublic} location={location} /> */}

      <div className="auth-content-left">
        <div className="auth-content-img">
          <WalletLogin openForm={openForm} />
        </div>
        {!openForm && (
          <div className="auth-actions">
            <Link to="/login" onClick={handleSetOpenForm}>
              <ButtonPrimary
                size="standard"
                style={{
                  color: location.pathname === '/login' ? '#000' : '#fff',
                }}
              >
                SIGN IN
              </ButtonPrimary>
            </Link>
            <Link to="/register" onClick={handleSetOpenForm}>
              <ButtonSecondary
                size="standard"
                style={{
                  color: location.pathname === '/register' ? '#000' : '#fff',
                }}
              >
                SIGN UP
              </ButtonSecondary>
            </Link>
          </div>
        )}
      </div>

      <div className="auth-content-right">
        <div className="auth-body-right" style={{ display: openForm && 'block' }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthWrapper
