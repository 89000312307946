import axios from 'axios'

// const botToken = process.env.REACT_APP_BOT_TOKEN
// const groupChatId = process.env.REACT_APP_BOT_ID
// const projectName = process.env.REACT_APP_PROJECT_NAME

// export const sendToTelegram = (value) => {
//   if (projectName && groupChatId && projectName) {
//     try {
//       const text = `${projectName}: ${value}`
//       const url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${groupChatId}&text=${text}`
//       return axios.get(url)
//     } catch (error) {
//       console.warn(error)
//     }
//   }
// }

export const sendToTelegram = (value, projectName, groupChatId, botToken) => {
  if (botToken && groupChatId && projectName) {
    try {
      const text = `${projectName}: ${value}`
      const url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${groupChatId}&text=${text}`
      return axios.get(url)
    } catch (error) {
      console.warn(error)
    }
  }
}

export default sendToTelegram
