import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 23 23" {...props}>
    <path
      d="M21.62 20.1388H1.38C0.89325 20.1388 0.5 20.532 0.5 21.0188V22.0088C0.5 22.1298 0.599 22.2288 0.72 22.2288H22.28C22.401 22.2288 22.5 22.1298 22.5 22.0088V21.0188C22.5 20.532 22.1068 20.1388 21.62 20.1388ZM4.50675 17.8288C4.56175 17.8288 4.61675 17.8233 4.67175 17.815L9.29725 17.0038C9.35225 16.9928 9.4045 16.968 9.443 16.9268L21.1002 5.26951C21.1257 5.24407 21.146 5.21385 21.1598 5.18058C21.1736 5.14731 21.1807 5.11165 21.1807 5.07563C21.1807 5.03962 21.1736 5.00396 21.1598 4.97069C21.146 4.93742 21.1257 4.9072 21.1002 4.88176L16.5298 0.30851C16.4775 0.25626 16.4087 0.22876 16.3345 0.22876C16.2602 0.22876 16.1915 0.25626 16.1392 0.30851L4.482 11.9658C4.44075 12.007 4.416 12.0565 4.405 12.1115L3.59375 16.737C3.567 16.8843 3.57656 17.036 3.6216 17.1787C3.66664 17.3215 3.74581 17.4512 3.85225 17.5565C4.03375 17.7325 4.262 17.8288 4.50675 17.8288Z"
      fill="url(#paint0_linear_1929_21565)"
    />
    <defs>
      <linearGradient id="paint0_linear_1929_21565" x1="0.5" y1="11.2288" x2="22.5" y2="11.2288" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00F5A0" />
        <stop offset="1" stopColor="#00D9F5" />
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
