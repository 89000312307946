import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './styles.scss'
import TitleSection from '../titleSection'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
// import ButtonSecondary from 'widgets/ButtonSecondary'

const EVMTracker = () => {
  const { userInfo } = useSelector((state) => state.user)
  return (
    <div className="warp-EVM">
      <div className="EVM_ui">
        <div className="img-left">
          <img src="/images/Home/evmtracker/illustration.png" alt="" />
        </div>
        <div className="group-btn">
          <TitleSection title="EVMTRACKER" description="Realtime Tracking Your Address Transactions" />
          <Link to="/register" target="_blank" rel="noreferrer">
            <ButtonPrimary>
              <FormattedMessage id="home-get-stated" />
            </ButtonPrimary>
          </Link>
        </div>
        <div className="img-right">
          <img src="/images/Home/evmtracker/imgright.png" alt="" />
        </div>
      </div>
      <div className="EVM_mb">
        <div className="top">
          <img src="/images/Home/evmtracker/banner-mb.png" alt="" />
        </div>
        <div className="bottom">
          <div style={{ padding: '24px 43px' }}>
            <TitleSection title="EVMTRACKER" description="Realtime Tracking Your Address Transactions" />
            <Link to="/register" target="_blank" rel="noreferrer">
              <ButtonPrimary>Get Started</ButtonPrimary>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EVMTracker
