import './styles.scss'
import React from 'react'
import { Table } from 'antd'

const TableActive = ({ columns, dataSource, ...props }) => (
  <Table
    pagination={false}
    scroll={{ y: window.innerWidth < 768 ? 'max-content' : 304, x: 'max-content' }}
    className="account-activity-recent-transaction-table"
    columns={columns}
    dataSource={dataSource}
    {...props}
  />
)

export default TableActive
