import * as types from './constants'

export const getTransactionList = (payload) => ({
  type: types.GET_TRANSACTIONS_LIST,
  payload,
})
export const getTransactionListSuccess = (payload) => ({
  type: types.GET_TRANSACTIONS_LIST_SUCCESS,
  payload,
})
export const getListStatus = (payload, cbs, cbe) => ({
  type: types.GET_LIST_STATUS,
  payload,
  cbs,
  cbe,
})
export const getListStatusSuccess = (payload) => ({
  type: types.GET_LIST_STATUS_SUCCESS,
  payload,
})
