import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'antd'
import './styles.scss'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import AuthCard from 'modules/auth/components/AuthCard'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import ButtonPrimary from 'widgets/ButtonPrimary'
import AuthenCode from 'modules/auth/components/VerifyAuthenCode'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const ResetPassword = () => {
  const [form] = Form.useForm()
  const [view, setView] = useState('login')

  const onFormSubmit = () => {
    form.validateFields().then(() => {
      setView('authen')
    })
  }

  return (
    <AuthWrapper>
      <div className="wrapper-form-reset-password">
        {view === 'login' ? (
          <AuthCard className="form-forgot fadeIn">
            <h2>Reset Password</h2>
            <Form layout="vertical" form={form} onFinish={onFormSubmit}>
              <FormItem
                label={<span>Enter your password</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter email field',
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" />
              </FormItem>

              <FormItem>
                <ButtonPrimary type="submit" className="form-action" style={{ maxWidth: '150px' }}>
                  Continue
                </ButtonPrimary>
              </FormItem>
            </Form>
            <div className="form-links">
              Already have Account. ?<Link to="/login">Sign up</Link>
            </div>
          </AuthCard>
        ) : (
          <AuthenCode />
        )}
      </div>
    </AuthWrapper>
  )
}

export default withGoogleReCaptcha(ResetPassword)
