import ModalCustom from 'components/BaseModal'
import React, { useState } from 'react'
import ButtonSecondary from 'widgets/ButtonSecondary'
import Input from 'widgets/Input'
import './styles.scss'
import { Form } from 'antd'
import { useDispatch } from 'react-redux'
import { withdrawPaypal } from 'state/wallets/actions'
import { executeRecaptchaV2 } from 'helpers'

const RequestWithdraw = ({ visible, setOpen, currency }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const handleWithdraw = () => {
    form.validateFields().then((values) => {
      // console.log(values)
      dispatch(withdrawPaypal({ email: values.email_withdraw }))
    })
  }
  return (
    <ModalCustom
      className="request-withdraw-modal"
      onCancel={() => setOpen(false)}
      visible={Boolean(visible)}
      centered
      title={<div className="modal-withdraw-header">Request Withdraw</div>}
    >
      <div className="request-withdraw">
        <Form form={form}>
          <div className="withdraw-desc">
            <h4>
              The available balance of affiliates will be topped up to avoid lots of small transactions and automatically take
              care of any new invoices generated.
            </h4>
          </div>
          <div className="withdraw-avaiable-balance">
            <h3>Available Balance:</h3>
            <span>${currency}</span>
          </div>
          <div className="withdraw-paypal-email">
            <h3>Paypal Email:</h3>
            <Form.Item name="email_withdraw">
              {/* <Input placeholder="Input your new project name" /> */}
              <Input />
            </Form.Item>
          </div>
          <div className="withdraw-button">
            <ButtonSecondary onClick={handleWithdraw}>Confirm</ButtonSecondary>
            <ButtonSecondary onClick={() => setOpen(false)}>Cancel</ButtonSecondary>
          </div>
        </Form>
      </div>
    </ModalCustom>
  )
}

export default RequestWithdraw
