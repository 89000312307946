import React, { useState } from 'react'
import './styles.scss'
import { formatDate } from 'helpers'
import { Link } from 'react-router-dom'

const ItemActive = ({ data, setViewDetails, setModalVerifyCode, ...props }) => {
  const [first, setFirst] = useState('')
  return (
    <>
      <div className="item-recent-transaction" {...props}>
        <div>
          <p>{formatDate(data.createdAt * 1000)}</p>

          {/* {data.status !== 'CREATED' ? (
          <Link to="/security/account-activity" className="ts-log-out">
            Log Out
          </Link>
        ) : (
          <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(data)}>
            Verify
          </a>
        )} */}
        </div>

        <div>
          <p>IP Adress</p>
          <p>{data.ip}</p>
        </div>
        <div>
          <p>Location</p>
          <p>{data.location ? data.location : '-'}</p>
        </div>
        <div>
          <p>Divice</p>
          <p data-status={data.status}>
            <span>{data.os}</span>
          </p>
        </div>
      </div>
    </>
  )
}
export default ItemActive
