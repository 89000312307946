import { Button } from 'antd'
import ModalConfirmPackage from 'components/ModalConfirmPackage'
import { formatDate } from 'helpers'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './styles.scss'

const ManagePackage = ({ hasPackageFree, packageInfo, projectInfo }) => {
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const handleUpgradePackage = () => {
    history.push('/project/start', {
      hasPackageFree,
      type: 'upgrade',
      projectId: projectInfo?._id,
      levelPackage: packageInfo?.level,
    })
  }

  let endTime
  if (projectInfo?.period === 'MONTH') {
    const setTime = packageInfo?.createdAt * 1000
    const date = new Date(setTime)
    date.setMonth(date.getMonth() + 1)
    endTime = date.getTime()
  } else if (projectInfo?.period === 'YEAR') {
    const setTime = packageInfo?.createdAt * 1000
    const date = new Date(setTime)
    date.setFullYear(date.getFullYear() + 1)
    endTime = date.getTime()
  }
  return (
    <div className="manage-package-wrapper">
      <div className="manage-package-wrapper_title">
        <span>Package</span>
      </div>
      <div className="manage-package-wrapper_name">
        <span className="row-title">Name:</span>
        <span className="row-content">{packageInfo?.title}</span>
      </div>
      <div className="manage-package-wrapper_duration">
        <div className="duration-left">
          <div className="duration-left_top">
            <span className="row-title">Duration:</span>
            <span className="row-content">
              {formatDate(projectInfo?.createdAt * 1000, 'YYYY/MM/DD')} -{' '}
              {formatDate(projectInfo?.expiredAt * 1000, 'YYYY/MM/DD')}
            </span>
          </div>
          <div className="duration-left_bottom">
            <ul>
              <li>
                IPN (webhook){' '}
                <span>
                  {packageInfo?.webhook ? (
                    <img src="/images/package/checked.svg" alt="" />
                  ) : (
                    <img src="/images/package/unCheck.svg" alt="" />
                  )}
                </span>
              </li>
              <li>
                Tele group message{' '}
                <span>
                  {packageInfo?.telegram_bot ? (
                    <img src="/images/package/checked.svg" alt="" />
                  ) : (
                    <img src="/images/package/unCheck.svg" alt="" />
                  )}{' '}
                </span>
              </li>
              <li>
                Token Transfer Event{' '}
                <span>
                  {packageInfo?.nft_transfer_event ? (
                    <img src="/images/package/checked.svg" alt="" />
                  ) : (
                    <img src="/images/package/unCheck.svg" alt="" />
                  )}{' '}
                </span>
              </li>
              <li>
                Native Token Send{' '}
                <span>
                  {packageInfo?.transfer_event ? (
                    <img src="/images/package/checked.svg" alt="" />
                  ) : (
                    <img src="/images/package/unCheck.svg" alt="" />
                  )}{' '}
                </span>
              </li>
              <li>
                Target address: {packageInfo?.transaction_watcher}{' '}
                <span>
                  <img src="/images/package/checked.svg" alt="" />
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="duration-right">
          <img src="/images/projectManage/Frame.png" alt="" />
          <Button className="duration-right_button" onClick={handleUpgradePackage}>
            Update Package
          </Button>
        </div>
      </div>
      <ModalConfirmPackage visible={open} setOpen={setOpen} />
    </div>
  )
}

export default ManagePackage
