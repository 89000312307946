import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import './styles.scss'
import { socials } from './config'
import InputWithButton from 'widgets/InputWithButton'
import { color } from '@amcharts/amcharts4/core'

const Footer = () => (
  <footer className="footer-container">
    <div className="footer-content">
      <div>
        <Link to="/">
          <div className="footer-logo">
            <img src="/images/evm-tracker.png" title="EVM Tracker" alt="EVM Tracker" />
          </div>
        </Link>

        <div className="socials-list">
          {socials.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <a
              key={item?.link_to + index}
              // style={{ pointerEvents: 'none', cursor: 'pointer' }}
              href={item?.link_to}
              target={item?.target}
              rel="noreferrer"
            >
              <img src={item?.img_url} alt={item?.label} width={item?.width || '40px'} title={item?.label} />
            </a>
          ))}
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          Copyright By <span style={{ color: '#FFFFFF' }}>EVMTRACKER.COM</span> Alright Reverse !{' '}
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
