import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import './styles.scss'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import AuthCard from 'modules/auth/components/AuthCard'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import ButtonPrimary from 'widgets/ButtonPrimary'
import AuthenCode from 'modules/auth/components/VerifyResetPasswordCode'
import { requestForgotPassword } from 'state/auth/actions'
import { Link } from 'react-router-dom'
import BackLink from 'components/BackLink'
import { executeRecaptchaV2 } from 'helpers'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [view, setView] = useState(0)
  const [email, setEmail] = useState('')

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      dispatch(
        requestForgotPassword(
          { ...values },
          () => {
            setEmail(values.email)
            setView(1)
          },
          () => {
            setView(0)
          },
        ),
      )
    })
  }

  return (
    <AuthWrapper>
      <div className="wrapper-form-forgot">
        {view === 0 ? (
          <AuthCard className="form-forgot fadeIn">
            {/* <h2 className="forgot-title">Forgot Password</h2> */}
            <BackLink icon={<img src="/images/settings/left-icon.png" alt="" />} label="Forgot Password" href="/login" />
            <Form layout="vertical" form={form} onFinish={onFormSubmit}>
              <FormItem
                label={<span className="forgot-label">Enter your email address</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter email field',
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" type="email" />
              </FormItem>

              <FormItem>
                <ButtonPrimary type="submit" className="form-action">
                  Continue
                </ButtonPrimary>
              </FormItem>
            </Form>
            {/* <div className="form-links">
              Already have Account. ?<Link to="/login">Sign in</Link>
            </div> */}
          </AuthCard>
        ) : (
          <AuthenCode email={email} setView={setView} />
        )}
      </div>
    </AuthWrapper>
  )
}

export default withGoogleReCaptcha(ForgotPassword)
