import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'

const RealTimeTracking = ({ title, ...props }) => (
  <div className="realTime-section">
    {title ? <h2>{title}</h2> : ''}
    {props.description ? <p>{props.description}</p> : ''}
    {props.desc ? <p>{props.desc}</p> : ''}
    {props.children ? <>{props.children} </> : ''}
    {props.url ? (
      <a href={props.url} target="blank" className="api" style={{ color: '#111' }}>
        {props.linkText}
      </a>
    ) : (
      ''
    )}
  </div>
)

export default RealTimeTracking
