import { Form, Input } from 'antd'
import Page from 'components/App/Page'
import BackLink from 'components/BackLink'
import ModalCustom from 'components/BaseModal'
import { sendToTelegram } from 'helpers'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateProject } from 'state/project/actions'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'
import axios from 'axios'
import sendToWebhook from 'helpers/sendToWebhook'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Notification = () => {
  const history = useHistory()
  const { state } = history.location
  const dispatch = useDispatch()
  // console.log('history in notification edit: ', history)
  const backLink = state?._id
  const pathName = history.location.pathname.split('/')[2]
  const telegramGroup = state?.telegram_group_id
  const webhook = state?.webhook
  const notificationTitle = pathName.includes('webhook') ? 'Webhook' : 'Telegram'
  const inputTitle = pathName.includes('webhook') ? 'Webhook link' : 'Group ID'
  const [groupId, setGroupId] = useState(telegramGroup)
  const [botKey, setBotKey] = useState(state?.telegram_bot_key)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSuccess, setisSuccess] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    // document.getElementById('private-content').scrollTo(0, 0)
    if (document.getElementById('private-content')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [])
  const handleUpdate = () => {
    form.validateFields().then((values) => {
      // console.log('values update webhook:', values, state._id)

      dispatch(
        updateProject(
          {
            project_id: state._id,
            webhook: values.webhook,
            telegram_group_id: values.telegram_group_id,
            telegram_bot_key: values.telegram_bot_key,
          },
          (dataR) => console.log(dataR),
        ),
      )
    })
  }
  const sendTestWebhook = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: webhook,
        data: { success: 'send test success' },
      })
      console.log('Message sent:', response)
      setisSuccess(true)
      setIsModalOpen(true)
    } catch (error) {
      console.error('Error sending message:', error)
      setisSuccess(false)
      setIsModalOpen(true)
    }
  }
  const handleSendTestTele = async () => {
    try {
      // console.log(groupId, botKey)
      if (groupId && botKey) {
        const response = await sendToTelegram('send test success', state.title, groupId, botKey)
        // console.log(response)
        setIsModalOpen(true)
        setisSuccess(true)
      } else {
        setIsModalOpen(true)
        setisSuccess(false)
      }
    } catch (error) {
      console.log(error)
      setIsModalOpen(true)
      setisSuccess(false)
    }
  }
  return (
    <Page>
      <div className="notification-manage">
        <Form form={form}>
          <div className="notification-manage_header">
            {state?.logo && <img src={state?.logo} alt="" />}
            <p>{state?.title}</p>
          </div>
          <div className="notification-manage_content">
            <div className="notification-manage_content_title">
              <BackLink
                icon={<img src="/images/settings/left-icon.png" alt="" />}
                label={`Notification > ${notificationTitle}`}
                href={`/project/manage/${backLink}`}
              />
            </div>
            <div className="notification-manage_content_input">
              <div className="notification-manage-title">{inputTitle}:</div>
              <div className="notification-manage-input">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: `${
                        pathName.includes('telegram') ? 'Please input your Telegram group ID' : ' Please input your webhook link'
                      }`,
                    },
                  ]}
                  name={pathName.includes('telegram') ? 'telegram_group_id' : 'webhook'}
                >
                  <Input
                    onChange={(e) => setGroupId(e.target.value)}
                    defaultValue={pathName.includes('telegram') ? telegramGroup : webhook}
                    placeholder={
                      pathName.includes('telegram') ? 'Input your Telegram group ID without g' : ' Input your webhook link'
                    }
                  />
                </Form.Item>
                {/* <Input placeholder="Input your webhood link" /> */}
              </div>
              {pathName.includes('webhook') && (
                <div className="notification-manage-save">
                  <ButtonSecondary onClick={handleUpdate}>Save</ButtonSecondary>
                </div>
              )}
            </div>
            {pathName.includes('telegram') && (
              <div className="notification-manage_content_input telegram">
                <div className="notification-manage-title">Bot key:</div>
                <div className="notification-manage-input">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please input Bot key',
                      },
                    ]}
                    name="telegram_bot_key"
                  >
                    <Input
                      onChange={(e) => setBotKey(e.target.value)}
                      defaultValue={state?.telegram_bot_key}
                      placeholder="Input your bot key"
                    />
                  </Form.Item>
                  {/* <Input placeholder="Input your webhood link" /> */}
                </div>
                {/* <div className="notification-manage-save">
                  <ButtonSecondary onClick={handleUpdate}>Save</ButtonSecondary>
                </div> */}
              </div>
            )}
            {pathName.includes('telegram') && (
              <div className="notification-manage-save telegram-save">
                <ButtonSecondary onClick={handleUpdate}>Save</ButtonSecondary>
              </div>
            )}
            {pathName.includes('telegram') && <div className="notification-manage_content_text" />}
          </div>
          <div className="notification-manage_submit">
            {/* <Form.Item>
              <ButtonSecondary>Send Test</ButtonSecondary>
            </Form.Item> */}

            <ButtonSecondary onClick={pathName.includes('telegram') ? handleSendTestTele : sendTestWebhook}>
              Send Test
            </ButtonSecondary>
            {/* <ButtonSecondary onClick={handleSendTestTele}>Send Test</ButtonSecondary> */}
          </div>
        </Form>
      </div>
      <ModalCustom
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        centered
        className="modal-send-test-telegram"
        title="Notification"
      >
        <p className="noti-send-test">{isSuccess ? 'Send test success!' : 'Send test failure!'}</p>
      </ModalCustom>
    </Page>
  )
}

export default withGoogleReCaptcha(Notification)
