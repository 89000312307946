/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-nested-ternary */
import { Col, DatePicker, Form, Row, Select } from 'antd'
import Page from 'components/App/Page'
import PointSecurity from 'components/PointSecurity'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import FormItem from 'widgets/FormItem'
import { regexPhone } from '../../utils/Regex'
import './styles.scss'
import { executeRecaptchaV2, getBase64 } from 'helpers'
import { getListCountry } from 'state/app/actions'
import { requestForgotPassword } from 'state/auth/actions'
import { uploadFile } from 'state/file/actions'
import { getProfile, updateProfile } from 'state/user/actions'
import { CheckedIcon, EditUnderLineIcon } from 'widgets/Svg'
import ChangePassword from './ChangePassword'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const { Option } = Select

const AccountSetting = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const [view, setView] = useState(0)
  const [name, setName] = useState(false)
  const [form] = Form.useForm()
  const { userInfo } = useSelector((state) => state.user)
  const { listCountry } = useSelector((state) => state.app)
  const [inputName, setInputName] = useState(`${userInfo.profile.fistname} ${userInfo.profile.lastname}`)
  const [inputBirthday, setInputBirthday] = useState(
    `${moment(userInfo.profile.birthday)._d}`.toUpperCase() !== 'Invalid Date'.toUpperCase()
      ? userInfo.profile.birthday
      : '2000-05-24',
  )
  const [avatar, setAvatar] = useState('')
  const [timeResend, setTimeResend] = useState(0)
  const [viewDetails, setViewDetails] = useState({ toggle: false, dataModal: null })
  useEffect(() => {
    // document.getElementById('private-content').scrollTo(0, 0)
    if (document.getElementById('private-content')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [])

  const dataSelectCountry = listCountry?.map((item, index) => (
    <Option key={index} value={item.code}>
      {/* <img
        src="https://cdn-icons-png.flaticon.com/512/939/939633.png"
        alt=""
        style={{ position: 'absolute', top: '12px', left: '0', width: '34px', height: '24px' }}
      /> */}
      {item.title}
      {/* <div className="input-country">
        <img src="https://cdn-icons-png.flaticon.com/512/939/939633.png" alt="" style={{ width: '34px', height: '24px' }} />
      </div> */}
    </Option>
  ))

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true)

      dispatch(
        updateProfile(
          {
            ...values,
            fistname: inputName.split(' ').slice(0, -1).join(' '),
            lastname: inputName.split(' ').slice(-1).join(' '),
            birthday: inputBirthday,
            avatar: avatar._id,
          },
          () => {
            dispatch(getProfile())
            setView(0)
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    })
  }

  useEffect(() => {
    if (userInfo) {
      const defaultValues = {
        country: userInfo.profile.country,
        phone_number: userInfo.profile.phone_number,
        address: userInfo.profile.address,
        birthday: userInfo.profile.birthday,
      }
      setAvatar({
        image: userInfo.profile.avatar,
      })
      form.setFieldsValue(defaultValues)
    }
  }, [form, userInfo])

  const handleChangeName = (value) => {
    setInputName(value)
  }

  const handleChangeDate = (date, dateString) => {
    setInputBirthday(dateString)
  }

  const handleChangePassword = () => {
    setViewDetails({ toggle: true, dataModal: viewDetails })
    dispatch(
      requestForgotPassword(
        {
          email: userInfo.email,
        },
        () => {},
      ),
    )
  }
  const handleChangeAvatar = (event) => {
    if (event.target.files && event.target.files[0]) {
      getBase64(event.target.files[0]).then(() => {
        dispatch(
          uploadFile(
            {
              key: 1,
              image: event.target.files[0],
            },
            (receipt) => {
              setAvatar({
                ...receipt,
                image: receipt.full_link,
              })
            },
          ),
        )
      })
    }
  }

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeResend((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  return (
    <Page>
      {view === 0 ? (
        <div className="warp-page-account">
          <div className="page-account-content">
            <div className="box-avatar">
              <div className="avatar-content-left">
                <div className="avatar-image">
                  <img src={avatar?.image ? `${avatar.image}` : '/images/user-default.png'} alt="" />

                  <label htmlFor="change_avatar">
                    <input
                      id="change_avatar"
                      accept="image/*"
                      type="file"
                      disabled
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        handleChangeAvatar(e)
                      }}
                    />
                  </label>
                </div>
                <span className="wrapper-info">
                  <div className="avatar-info">
                    <p>
                      {name && view === 1 ? (
                        <input autoFocus defaultValue={inputName} onChange={(event) => handleChangeName(event.target.value)} />
                      ) : (
                        inputName
                      )}
                    </p>
                    <p>{userInfo.email}</p>
                  </div>
                </span>
              </div>
              <div className="avatar-content-right">
                <ButtonSecondary style={{ width: '120px' }} onClick={() => setView(1)}>
                  <EditUnderLineIcon />
                  Edit
                </ButtonSecondary>
              </div>
            </div>
            <div className="box-info-input">
              {/* Ref */}
              {/* <div className="wrap-referrer">
                <ReferrerCard userInfo={userInfo} />
              </div> */}

              {/* Form edit */}
              <Form layout="vertical" form={form}>
                <Row gutter={[{ sm: 15, md: 32, lg: 57 }]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <FormItem
                          label={<span>Country</span>}
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter country field',
                            },
                          ]}
                        >
                          <Select id="country" disabled showSearch style={{ width: '100%' }} placeholder="Country">
                            {dataSelectCountry}
                          </Select>
                        </FormItem>
                      </div>
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="input-width-pen">
                      <div className="box-input phone-number">
                        <img className="mobile-icon phone" src="/images/account/phone.png" alt="" />
                        <p>+84</p>
                        <FormItem
                          label={<span>Phone Number</span>}
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Phone Number field',
                            },
                          ]}
                        >
                          <input disabled id="phone-number" placeholder="Phone Number" />
                        </FormItem>
                      </div>
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <img className="mobile-icon address" src="/images/account/address.png" alt="" />

                        <FormItem
                          label={<span>Address</span>}
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Address field',
                            },
                          ]}
                        >
                          <input disabled id="address" placeholder="address" />
                        </FormItem>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="input-width-pen">
                      <div className="box-input">
                        <img className="mobile-icon birth" src="/images/account/birthday.png" alt="" />

                        <p style={{ marginBottom: '6px' }}>Birthday</p>
                        <div style={{ position: 'relative' }}>
                          <DatePicker
                            disabled
                            id="birthday"
                            defaultValue={moment(inputBirthday)}
                            style={{ width: '100%', padding: '0', borderRadius: '10px' }}
                            onChange={(even, date) => handleChangeDate(date)}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className="box-info-password">
              <div className="password-left">
                <p>Password</p>
                <p>Increase your password strength to enhance account security</p>
              </div>
            </div>

            <div className="box-rate-sercurity">
              <Link to="/affiliate">
                <div className="rate-sercurity-item">
                  <div className="rate-sercurity-item-name">
                    <img src="/images/account/wlt.png" alt="Wallet" />
                    <p>Wallet</p>
                  </div>
                  <div className="rate-sercurity-item-description">
                    <p>Your assets will be safely kept here and the multi-chain makes it easy to use</p>
                  </div>
                </div>
              </Link>

              <Link to="/settings/security">
                <div className="rate-sercurity-item">
                  <div className="rate-sercurity-item-name">
                    <img src="/images/account/protect.png" alt="Security" />
                    <p>Security</p>
                  </div>
                  <div className="rate-sercurity-item-description">
                    <p>
                      Account risk level:
                      {!userInfo?.status_2fa ? (
                        <PointSecurity point={3} />
                      ) : !userInfo?.status_kyc ? (
                        <PointSecurity point={4} />
                      ) : (
                        <PointSecurity point={5} />
                      )}
                    </p>
                  </div>
                </div>
              </Link>
              <div className="btn-edit-mobile">
                <ButtonSecondary style={{ width: '120px' }} onClick={() => setView(1)} timeresend={timeResend}>
                  <EditUnderLineIcon />
                  Edit
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="warp-page-account editor">
            <div className="page-account-content">
              <div className="box-avatar">
                <div className="avatar-content-left">
                  <div className="avatar-image">
                    <img src={avatar?.image ? `${avatar.image}` : '/images/user-default.png'} alt="" />
                    <label htmlFor="change_avatar">
                      <input
                        id="change_avatar"
                        accept="image/*"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          handleChangeAvatar(e)
                        }}
                      />
                      <img src="/images/account/cmr.svg" alt="" role="presentation" />
                    </label>
                  </div>
                  <span className="wrapper-info">
                    <div className="avatar-info">
                      <p>
                        {name && view === 1 ? (
                          inputName
                        ) : (
                          <input autoFocus defaultValue={inputName} onChange={(event) => handleChangeName(event.target.value)} />
                        )}
                        {/* <EditUnderLineIcon
                          onClick={() => {
                            if (name) {
                              setName(false)
                            } else {
                              setName(true)
                            }

                            // document.getElementById('username')?.focus()
                          }}
                        /> */}
                        <img
                          src="/images/account/edit-underline.png"
                          alt=""
                          role="presentation"
                          onClick={() => {
                            if (name) {
                              setName(false)
                            } else {
                              setName(true)
                            }

                            document.getElementById('username')?.focus()
                          }}
                        />
                      </p>

                      <p>
                        {userInfo.email}{' '}
                        {/* <EditUnderLineIcon
                          onClick={() => {
                            if (name) {
                              setName(false)
                            } else {
                              setName(true)
                            }

                            // document.getElementById('username')?.focus()
                          }}
                        /> */}
                      </p>
                    </div>
                  </span>
                </div>
                <div className="avatar-content-right">
                  <ButtonPrimary style={{ width: '120px', lineHeight: 'unset', color: '#F3F8FF' }} onClick={onFormSubmit}>
                    <CheckedIcon /> Save
                  </ButtonPrimary>
                </div>
              </div>

              <div className="box-info-input">
                {/* Ref */}
                {/* <div className="wrap-referrer">
                  <ReferrerCard userInfo={userInfo} />
                </div> */}

                {/* Form edit */}
                <Form layout="vertical" form={form}>
                  <Row gutter={[{ sm: 15, md: 32, lg: 57 }]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                      <div className="input-width-pen">
                        <div className="box-input">
                          <FormItem
                            label={<span>Country</span>}
                            name="country"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter country field',
                              },
                            ]}
                          >
                            <Select id="country" showSearch style={{ width: '100%' }} placeholder="Country">
                              {dataSelectCountry}
                            </Select>
                          </FormItem>
                          <img
                            src="/images/account/edit-underline.png"
                            alt=""
                            role="presentation"
                            onClick={() => {
                              document.getElementById('country')?.focus()
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                      <div className="input-width-pen">
                        <div className="box-input phone-number mobile-icon">
                          <img className="mobile-icon phone" src="/images/account/phone.png" alt="" />
                          <p>+84</p>
                          <FormItem
                            label={<span>Phone Number</span>}
                            name="phone_number"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Phone Number field',
                              },
                              { pattern: regexPhone, message: 'Invalid Phone number' },
                            ]}
                          >
                            <input id="phone-number" placeholder="Phone Number" />
                          </FormItem>
                          <img
                            src="/images/account/edit-underline.png"
                            alt=""
                            role="presentation"
                            onClick={() => {
                              document.getElementById('phone-number')?.focus()
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                      <div className="input-width-pen">
                        <div className="box-input mobile-icon">
                          <img className="mobile-icon address" src="/images/account/address.png" alt="" />
                          <FormItem
                            label={<span>Address</span>}
                            name="address"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Address field',
                              },
                            ]}
                          >
                            <input id="address" placeholder="address" />
                          </FormItem>
                          <img
                            src="/images/account/edit-underline.png"
                            alt=""
                            role="presentation"
                            onClick={() => {
                              document.getElementById('address')?.focus()
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                      <div className="input-width-pen">
                        <div className="box-input mobile-icon">
                          <img className="mobile-icon birth" src="/images/account/birthday.png" alt="" />
                          <p style={{ marginBottom: '8px' }}>Birthday</p>
                          <div style={{ position: 'relative' }}>
                            <DatePicker
                              id="birthday"
                              name="birthday"
                              defaultValue={moment(inputBirthday)}
                              style={{ width: '100%', padding: '0', borderRadius: '10px' }}
                              onChange={(date, dateString) => handleChangeDate(date, dateString)}
                            />
                            <img
                              style={{ top: '18px' }}
                              src="/images/account/callender.png"
                              alt=""
                              role="presentation"
                              onClick={() => {
                                document.getElementById('birthday')?.focus()
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>

              <div className="box-info-password">
                <div className="password-left">
                  <p>Password</p>
                  <p>Increase your password strength to enhance account security</p>
                </div>
                <div className="password-right">
                  <ButtonSecondary role="presentation" onClick={handleChangePassword}>
                    Change
                  </ButtonSecondary>
                </div>
              </div>

              <div className="box-rate-sercurity">
                <Link to="/affiliate">
                  <div className="rate-sercurity-item">
                    <div className="rate-sercurity-item-name">
                      <img src="/images/account/wlt.png" alt="Wallet" />
                      <p>Wallet</p>
                    </div>
                    <div className="rate-sercurity-item-description">
                      <p>Your assets will be safely kept here and the multi-chain makes it easy to use</p>
                    </div>
                  </div>
                </Link>

                <Link to="/settings/security">
                  <div className="rate-sercurity-item">
                    <div className="rate-sercurity-item-name">
                      <img src="/images/account/protect.png" alt="Security" />
                      <p>Security</p>
                    </div>
                    <div className="rate-sercurity-item-description">
                      <p>
                        Account risk level:
                        {!userInfo?.status_2fa ? (
                          <PointSecurity point={3} />
                        ) : !userInfo?.status_kyc ? (
                          <PointSecurity point={4} />
                        ) : (
                          <PointSecurity point={5} />
                        )}
                      </p>
                    </div>
                  </div>
                </Link>
                <div className="btn-edit-mobile">
                  <ButtonPrimary style={{ width: '120px', lineHeight: 'unset' }} onClick={onFormSubmit}>
                    <CheckedIcon />
                    Save
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
          <ChangePassword
            email={userInfo.email}
            visible={viewDetails.toggle}
            dataModal={viewDetails.dataModal}
            close={setViewDetails}
          />
        </>
      )}
    </Page>
  )
}

export default withGoogleReCaptcha(AccountSetting)
