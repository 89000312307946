import { all } from 'redux-saga/effects'

import App from './app/sagas'
import User from './user/sagas'
import Home from './home/sagas'
import Auth from './auth/sagas'
import Wallet from './wallets/sagas'
import Stake from './stake/sagas'
import Transactions from './transactions/sagas'
import File from './file/sagas'
import PrivateSale from './privateSale/sagas'
import ExchangeSaga from './exchange/sagas'
import Contact from './contact/sagas'
import Package from './package/sagas'
import ProjectList from './projectList/sagas'
import Project from './project/sagas'
import Subscription from './subscription/sagas'
import Address from './address/sagas'
import BlockchainList from './blockChainList/sagas'
import Report from './report/sagas'
import Invoice from './invoice/sagas'

export default function* rootSaga() {
  yield all([
    App(),
    User(),
    Home(),
    Auth(),
    Wallet(),
    Stake(),
    Transactions(),
    File(),
    PrivateSale(),
    ExchangeSaga(),
    Contact(),
    Package(),
    ProjectList(),
    Project(),
    Subscription(),
    Address(),
    BlockchainList(),
    Report(),
    Invoice(),
  ])
}
