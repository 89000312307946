import { Badge, List, Pagination, Table } from 'antd'
import Page from 'components/App/Page'
import BackLink from 'components/BackLink'
import TablePagination from 'components/TablePagination/TablePagination'
import { formatCode, formatDate, roundNumber } from 'helpers'
import useIsMobile from 'hooks/useIsMobile'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectList } from 'state/projectList/actions'
import ItemRecentTransaction from './components/itemRecentTransaction'
import ModalViewDetails from './components/ModalViewDetails'
import { DECIMALS } from 'constants/index'
import './styles.scss'
import { getInvoiceList } from 'state/invoice/actions'
import InfiniteScroll from 'react-infinite-scroll-component'
import Nodata from 'components/Nodata'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const responsiveList = { gutter: 16, xs: 1, sm: 1, md: 1 }

const timePricing = { YEAR: 'Yearly', MONTH: 'Monthly' }
const status = {
  COMPLETED: 'Active',
  APPROVAL_PENDING: 'Processing',
}

const columns = [
  {
    title: <p className="package-history-title init">Transaction ID</p>,
    dataIndex: '_id',
    key: '_id',
    render: (text) => <span className="trans-id-txt">{formatCode(text, 5, 5)}</span>,
  },
  {
    width: 290,
    title: <p className="package-history-title">Product/Service</p>,
    key: 'product',
    render: (_, record) => (
      <div className="produect-service">
        <span>{record.project.title.title || record.project.title}</span>
        <span>{record?.package?.limit_address} addresses</span>
      </div>
    ),
  },
  {
    title: <p className="package-history-title">Pricing</p>,
    key: 'pricing',
    render: (_, record) => (
      <div className="pricing">
        <span>
          $
          {record.project.period === 'YEAR'
            ? roundNumber(record.package.price_year / 10 ** DECIMALS, 6)
            : roundNumber(record.package.price_month / 10 ** DECIMALS, 6)}
        </span>
        <span>{timePricing[record.project.period]}</span>
      </div>
    ),
  },
  {
    title: <p className="package-history-title">Payment Method</p>,
    key: 'type',
    render: (_, record) => <span className="trans-id-txt black-txt centered">{record.package.type}</span>,
  },
  {
    title: <p className="package-history-title">Date</p>,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }} className="trans-id-txt black-txt centered">
        {formatDate(text * 1000, 'YYYY-MM-DD')}
      </span>
    ),
  },
  {
    width: 150,
    title: <p className="package-history-title">Status</p>,
    dataIndex: 'status_payment',
    key: 'status_payment',
    render: (text) => (
      <span className="trans-id-txt centered">
        <Badge status={text === 'COMPLETED' ? 'success' : 'warning'} text={status[text]} />
      </span>
    ),
  },
]

const ProjectHistory = () => {
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    txhash: '',
    type: '',
    from_date: '',
    to_date: '',
    chain_code: '',
    currency: '',
    status_url: false,
  })
  const [viewDetails, setViewDetails] = useState({ toggle: false, dataModal: null })
  const { invoiceList } = useSelector((state) => state.invoice)
  const { rows } = invoiceList
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getInvoiceList(txParams))
  }, [dispatch, txParams])

  const isMobile = useIsMobile()
  const handleChangePagination = (key) => {
    switch (key) {
      case 'previous':
        setTxParams({ ...txParams, page: txParams?.page - 1 })

        break
      case 'next':
        setTxParams({ ...txParams, page: txParams?.page + 1 })
        break

      default:
        return txParams
    }
  }
  const handleChangeShow = (value) => {
    setTxParams({
      ...txParams,
      pageSize: value,
    })
  }
  // <List
  //   dataSource={rows || []}
  //   grid={responsiveList}
  //   renderItem={(item, index) => (
  //     <List.Item key={`list-${index}`}>
  //       <ItemRecentTransaction
  //         data={item}
  //         key={item.id}
  //         setViewDetails={setViewDetails}
  //         setModalVerifyCode={setModalVerifyCode}
  //       />
  //     </List.Item>
  //   )}
  //   locale={{
  //     emptyText: transactionsList?.total === 0 ? <div>Empty</div> : <></>,
  //   }}
  // />
  const loadMoreData = () => {
    setTxParams((prev) => ({
      ...prev,
      pageSize: prev.pageSize + 15,
    }))
  }
  useEffect(() => {
    if (isMobile) {
      loadMoreData()
    }

    // loadMoreData()
  }, [])

  return (
    <Page>
      <div className="package-history" id="scrollableDiv">
        <BackLink icon={<img src="/images/settings/left-icon.png" alt="" />} label="History" href="/project/start" />
        {isMobile ? (
          <div style={{ minHeight: '100px' }}>
            {rows?.length > 0 ? (
              <InfiniteScroll
                className="infinite-scroll"
                style={{ overflow: 'unset' }}
                dataLength={rows?.length || 0}
                next={loadMoreData}
                hasMore={rows?.length < invoiceList?.total}
                loader={
                  <List
                    dataSource={[{}]}
                    grid={responsiveList}
                    renderItem={(_, index) => (
                      <List.Item key={`list-${index}`}>
                        <div
                          className="loading-list-stake"
                          style={{
                            color: '#003e9d',
                            textAlign: 'center',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        >
                          <span role="presentation" onClick={loadMoreData} style={{ marginRight: '4px', cursor: 'pointer' }}>
                            Load more...
                          </span>
                          {/* <Loading /> */}
                        </div>
                      </List.Item>
                    )}
                  />
                }
                scrollableTarget="scrollableDiv"
              >
                <List
                  dataSource={rows || []}
                  grid={responsiveList}
                  renderItem={(item, index) => (
                    <List.Item key={`list-${index}`}>
                      <ItemRecentTransaction
                        data={item}
                        key={item.id}
                        setViewDetails={setViewDetails}
                        // setModalVerifyCode={setModalVerifyCode}
                      />
                    </List.Item>
                  )}
                  // locale={{
                  //   emptyText: transactionsList?.total === 0 ? <div>Empty</div> : <></>,
                  // }}
                />
              </InfiniteScroll>
            ) : (
              <Nodata />
            )}
          </div>
        ) : (
          <div className="history-package-table">
            <Table rowKey={(record) => record._id} dataSource={rows} columns={columns} pagination={false} />
            <div className="history-pagination">
              <TablePagination
                page={txParams?.page}
                pageSize={txParams?.pageSize}
                total={invoiceList?.total}
                onChange={handleChangePagination}
                onChangeShow={handleChangeShow}
              />
            </div>
          </div>
        )}
      </div>
      <ModalViewDetails visible={viewDetails.toggle} dataModal={viewDetails.dataModal} close={setViewDetails} />
    </Page>
  )
}

export default withGoogleReCaptcha(ProjectHistory)
