import Page from 'components/App/Page'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectList } from 'state/projectList/actions'
import Block from './components/block'
import ProjectDetail from './components/project'
import './styles.scss'
import Skeleton from 'components/Skeleton'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const ProjectList = () => {
  const dispatch = useDispatch()
  const { projectsList } = useSelector((state) => state.projectList)
  const { rows } = projectsList
  useEffect(() => {
    dispatch(getProjectList())
  }, [])
  let hasPackageFree = false
  for (let i = 0; i < rows?.length; i += 1) {
    if (rows[i].package && rows[i].package.title.includes('Free')) {
      hasPackageFree = true
      break
    }
  }
  useEffect(() => {
    // document.getElementById('private-content').scrollTo(0, 0)
    if (document.getElementById('private-content')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [])
  return (
    <Page>
      <div className="project-list-wrapper">
        <div className="project-list-top">
          <Block hasPackageFree={hasPackageFree} />
        </div>
        <div className="my-project">
          <span>My Projects</span>
        </div>
        <div className="project-list-content">
          {rows ? (
            rows.map((item) => <ProjectDetail hasPackageFree={hasPackageFree} data={item} key={item._id} />)
          ) : (
            <Skeleton height="240px" />
          )}
        </div>
        {/* <div className="project-list-content">
          {rows?.map((item) => (
            <ProjectDetail hasPackageFree={hasPackageFree} data={item} key={item._id} />
          ))}
        </div> */}
      </div>
    </Page>
  )
}

export default withGoogleReCaptcha(ProjectList)
