import * as types from './constants'

export const getPrivateSaleList = (payload) => ({
  type: types.GET_PRIVATE_SALE_LIST,
  payload,
})
export const getPrivateSaleListSuccess = (payload) => ({
  type: types.GET_PRIVATE_SALE_LIST_SUCCESS,
  payload,
})

export const getPrivateSaleDetail = (payload) => ({
  type: types.GET_PRIVATE_SALE_DETAIL,
  payload,
})
export const getPrivateSaleDetailSuccess = (payload) => ({
  type: types.GET_PRIVATE_SALE_DETAIL_SUCCESS,
  payload,
})

export const buyPrivateSale = (payload, cbs, cbe) => ({
  type: types.BUY_PRIVATE_SALE,
  payload,
  cbs,
  cbe,
})

export const getPrivateSaleTransaction = (payload) => ({
  type: types.GET_PRIVATE_TRANSACTION,
  payload,
})
export const getPrivateSaleTransactionSuccess = (payload) => ({
  type: types.GET_PRIVATE_TRANSACTION_SUCCESS,
  payload,
})
