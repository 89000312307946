export const socials = [
  {
    id: 1,
    label: 'Facebook',
    link_to: 'https://beurl.app/evmtracker-fb',
    target: '_blank',
    img_url: '/images/socials/fb.png',
  },
  {
    id: 1,
    label: 'Twitter',
    link_to: 'https://beurl.app/evmtracker-tw',
    target: '_blank',
    img_url: '/images/socials/twiter.png',
  },
  {
    id: 1,
    label: 'Telegram',
    link_to: 'https://beurl.app/evmtracker-tele-group',
    target: '_blank',
    img_url: '/images/socials/tele.png',
  },
]

export const link2 = null
