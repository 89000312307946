import { all, takeLatest, call, put } from 'redux-saga/effects'
import qs from 'query-string'
import * as types from './constants'
import * as actions from './actions'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'

function getChainListFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/blockchain/list`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getChainList() {
  try {
    const { data } = yield call(getChainListFromApi)
    if (data.code === 200) {
      yield put(actions.getChainListSuccess(data.data.data))
    }
  } catch (error) {
    console.error('getChainList', error)
  }
}
/**
 * Get Currency
 */
function getCurrencyFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/currency/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getCurrency({ payload }) {
  try {
    const { data } = yield call(getCurrencyFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getCurrencySuccess(data.data))
      /*
       * Fetch balance
       */
      yield put(actions.fetchWalletBalance(data.data))
    }
  } catch (error) {
    console.error('getCurrency', error)
  }
}

/**
 * Fetch wallet balance
 */
function fetchWalletBalanceFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet/balance?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* fetchWalletBalance({ payload }) {
  try {
    const fetchList = {}
    payload.forEach((item) => {
      fetchList[item.code] = call(fetchWalletBalanceFromApi, { currency_code: item.code })
    })
    const balanceList = yield all(fetchList)
    if (balanceList) {
      const dataList = {}
      Object.entries(balanceList).forEach(([key, value]) => {
        if (value?.status === 200) {
          dataList[key] = value.data.data.balance
        }
      })
      yield put(actions.fetchWalletBalanceSuccess(dataList))
    }
  } catch (error) {
    console.error('fetchWalletBalance', error)
  }
}

/**
 * Get Currency
 */
function getCurrencyAttrFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/currencyAttr/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getCurrencyAttr({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getCurrencyAttrFromApi, payload)

    if (data.code === 200) {
      if (cbs) cbs(data.data)
      yield put(actions.getCurrencyAttrSuccess(data.data))
    } else if (cbe) cbe(data)
  } catch (error) {
    if (cbe) cbe(error)
    console.error('getCurrency', error)
  }
}

/**
 * Get Wallet
 */
function createWalletAddressFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet/getaddress`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createWalletAddress({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createWalletAddressFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
      yield put(actions.getWalletAddress({ page: 1, pageSize: 100 }))
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('createWalletAddress', error)
    if (cbe) cbe(error)
  }
}

/**
 * Get Wallet
 */
function getWalletAddressFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getWalletAddress({ payload }) {
  try {
    const { data } = yield call(getWalletAddressFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getWalletAddressSuccess(data.data))
    }
  } catch (error) {
    console.error('getWalletAddress', error)
  }
}

/**
 * Withdraw
 */
function withdrawFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/transaction/withdraw`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* withdraw({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(withdrawFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('withdraw', error)
    if (cbe) cbe(error)
  }
}

/**
 * Withdraw Paypal
 */
function withdrawPaypalFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/transaction/withdrawPaypal`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* withdrawPaypal({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(withdrawPaypalFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('withdraw paypal', error)
    if (cbe) cbe(error)
  }
}

/**
 * Verify withdraw
 */
function verifyWithdrawFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/transaction/verify-withdraw`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* verifyWithdraw({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(verifyWithdrawFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('withdraw', error)
    if (cbe) cbe(error)
  }
}
/**
 * Resend verify withdraw code
 */
function resendVerifyWithdrawCodeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/transaction/resend-verify-code`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* resendVerifyWithdrawCode({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(resendVerifyWithdrawCodeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('resendVerifyWithdrawCode', error)
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.GET_CHAIN_LIST, getChainList),
    takeLatest(types.GET_CURRENCY, getCurrency),
    takeLatest(types.FETCH_WALLET_BALANCE, fetchWalletBalance),
    takeLatest(types.GET_CURRENCY_ATTR, getCurrencyAttr),
    takeLatest(types.CREATE_WALLET_ADDRESS, createWalletAddress),
    takeLatest(types.GET_WALLET_ADDRESS, getWalletAddress),
    takeLatest(types.WITHDRAW, withdraw),
    takeLatest(types.VERIFY_WITHDRAW, verifyWithdraw),
    takeLatest(types.RESEND_VERIFY_WITHDRAW_CODE, resendVerifyWithdrawCode),
    takeLatest(types.WITHDRAW_PAYPAL, withdrawPaypal),
  ])
}
