import React, { useEffect } from 'react'
import './styles.scss'
import Page from 'components/App/Page'
import Block from './component/block'
import Project from './component/project'
import ReferrerCard from 'components/ReferrerCard'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectList } from 'state/projectList/actions'
import { getReport } from 'state/report/actions'
import useIsMobile from 'hooks/useIsMobile'
import { roundNumber } from 'helpers'
import { DECIMALS } from 'components/constants'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Dashboard = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const { userInfo } = useSelector((state) => state.user)
  const { projectsList } = useSelector((state) => state.projectList)
  const { report } = useSelector((state) => state.report)
  const { reportTransaction } = report
  const { reportTotalAddress } = report
  // const total = Object.keys(reportTransaction[0]).reduce((acc, cur) => {
  //   if (typeof reportTransaction[0][cur] === 'number') {
  //     acc += reportTransaction[0][cur]
  //   }
  //   return acc
  // }, 0)
  const totalAddress = reportTransaction?.reduce((accumulator, currentValue) => {
    // let sum = 0
    // eslint-disable-next-line no-restricted-syntax
    for (const key in currentValue) {
      if (typeof currentValue[key] === 'number' && currentValue[key] !== 'EVM_DIRECT_COMMISSION') {
        // eslint-disable-next-line no-param-reassign
        accumulator += currentValue[key]
      }
    }
    return accumulator
  }, 0)
  // const total = reportTransaction?.reduce((acc, curr) => console.log(reportTransaction[0][curr]))
  // console.log('total:', roundNumber(totalAddress / 10 ** DECIMALS))
  useEffect(() => {
    dispatch(getProjectList())
    dispatch(getReport())
  }, [dispatch])
  useEffect(() => {
    if (document.getElementById('private-content')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [])
  return (
    <Page>
      <div className="dashboard-top">
        {isMobile ? (
          <div className="dashboard-top-mobile">
            <div>
              <Block img="images/dashboard/icon-1.png" text={projectsList.total} desc="Projects" />
              <Block
                img="images/dashboard/icon-2.png"
                text={reportTotalAddress}
                desc={reportTotalAddress !== 0 ? 'Addresses' : 'Address'}
              />
            </div>
            <div>
              <Block
                img="images/dashboard/icon-3.png"
                text={reportTransaction?.[0].EVM_DIRECT_COMMISSION}
                desc="Earning |"
                descript=" Withdraw"
              />
            </div>
          </div>
        ) : (
          <div className="dashboard-top_mb">
            <Block img="images/dashboard/icon-1.png" text={projectsList.total} desc="Projects" />
            <Block
              img="images/dashboard/icon-2.png"
              text={reportTotalAddress}
              desc={reportTotalAddress !== 0 ? 'Addresses' : 'Address'}
            />
            <Block
              img="images/dashboard/icon-3.png"
              text={reportTransaction?.[0].EVM_DIRECT_COMMISSION}
              desc="Earning |"
              descript=" Withdraw"
            />
          </div>
        )}

        {/* <div className="dashboard-top_mb">
        </div> */}
      </div>
      <div className="dashboard-project">
        <Project />
      </div>
      <div className="dashboard-line" />
      <div className="dashboard-card">
        <ReferrerCard userInfo={userInfo} />
      </div>
    </Page>
  )
}

export default withGoogleReCaptcha(Dashboard)
