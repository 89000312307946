import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage } from './actions'

export function useLanguage() {
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.language)
  const setLanguage = useCallback(
    (language) => {
      dispatch(changeLanguage(language))
    },
    [dispatch],
  )
  return { language, setLanguage }
}
