import { all, takeLatest, call, put } from 'redux-saga/effects'
import Cookies from 'js-cookie'
import qs from 'query-string'
import * as types from './constants'
import * as actions from './actions'
import fetchHelper from 'helpers/FetchHelper'
import { CACHE_TOKEN, ROOT_API_URL } from 'constants/index'
import { deleteAuthToken } from 'helpers'

export const setCookies = (token, redirectCallback = () => null) => {
  Cookies.set(CACHE_TOKEN, token)
  setTimeout(redirectCallback(), 100)
}

function requestSignUp(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/signup`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}

function* signUp({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(requestSignUp, payload)
    if (data.code === 200) {
      if (cbs) cbs(data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('signUp', error)
    if (cbe) cbe(error)
  }
}

function loginFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/login`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* login({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(loginFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('verifyEmail', error)
    if (cbe) cbe(error)
  }
}

function logoutFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/logout?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* logout({ cbs, cbe }) {
  const payload = { type: 'all' }
  try {
    const { data } = yield call(logoutFromApi, payload)
    if (data.code === 200) {
      const isDelete = deleteAuthToken()
      if (isDelete) {
        window.location.href = `${window.location.origin}/login`
      }
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('verifyEmail', error)
    if (cbe) cbe(error)
  }
}

function verifyEmailFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/verify-login`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* verifyEmail({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(verifyEmailFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('verifyEmail', error)
    if (cbe) cbe(error)
  }
}

function verifyAuthenCodeFromApi(payload) {
  return fetchHelper
    .fetch(
      `${ROOT_API_URL}/customer/2fa/verify`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      {
        isToastSuccess: false,
        isToastFailed: false,
      },
    )
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* verifyAuthenCode({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(verifyAuthenCodeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('verifyAuthenCode', error)
    if (cbe) cbe(error)
  }
}

function verifyAuthenActiveAccountCodeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/active-account`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* verifyAuthenActiveAccountCode({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(verifyAuthenActiveAccountCodeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('verifyAuthenActiveAccountCode', error)
    if (cbe) cbe(error)
  }
}

function resendAuthenActiveAccountCodeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/code`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* resendAuthenActiveAccountCode({ payload, cbs }) {
  try {
    const { data } = yield call(resendAuthenActiveAccountCodeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs()
    }
  } catch (error) {
    console.error('resendAuthenActiveAccountCode', error)
  }
}

/**
 * @dev resend login code
 */
function resendLoginActiveAccountCodeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/resend-login-code`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* resendAuthenLoginAccountCode({ payload, cbs }) {
  try {
    const { data } = yield call(resendLoginActiveAccountCodeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs()
    }
  } catch (error) {
    console.error('resendAuthenLoginAccountCode', error)
  }
}
function resendActiveCodeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/resend-active-code`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* resendActiveCode({ payload, cbs }) {
  try {
    const { data } = yield call(resendActiveCodeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs()
    }
  } catch (error) {
    console.error('resen active code', error)
  }
}

function requestForgotPasswordFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/request-forgot-password`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* requestForgotPassword({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(requestForgotPasswordFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs()
    } else if (cbe) cbe()
  } catch (error) {
    if (cbe) cbe(error)
  }
}

function forgotPasswordFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/forgot-password`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* forgotPassword({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(forgotPasswordFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('forgotPassword', error)
    if (cbe) cbe(error)
  }
}

function create2FAFromApi() {
  return fetchHelper
    .fetch(
      `${ROOT_API_URL}/customer/2fa/create`,
      {
        method: 'POST',
      },
      {
        isToastSuccess: false,
        isToastFailed: false,
      },
    )
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* create2FA({ cbs, cbe }) {
  try {
    const { data } = yield call(create2FAFromApi)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('create2FA', error)
    if (cbe) cbe(error)
  }
}

function verify2FAFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/2fa/verify`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* verify2FA({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(verify2FAFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('create2FA', error)
    if (cbe) cbe(error)
  }
}
/**
 * Update 2FA
 */
function update2FAFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/2fa/update`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* update2FA({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(update2FAFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('update2FA', error)
    if (cbe) cbe(error)
  }
}

/**
 * Get History
 */
function getHistoryLoginFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customerHistory/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getHistoryLogin({ payload }) {
  try {
    const { data } = yield call(getHistoryLoginFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getHistoryLoginSuccess(data.data))
    }
  } catch (error) {
    console.error('getHistoryLogin', error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.SIGN_UP, signUp),
    takeLatest(types.LOGIN, login),
    takeLatest(types.LOGOUT, logout),
    takeLatest(types.VERIFY_EMAIL, verifyEmail),
    takeLatest(types.VERIFY_AUTHEN_CODE, verifyAuthenCode),
    takeLatest(types.VERIFY_AUTHEN_ACTIVE_ACCOUNT_CODE, verifyAuthenActiveAccountCode),
    takeLatest(types.RESEND_AUTHEN_ACTIVE_ACCOUNT_CODE, resendAuthenActiveAccountCode),
    takeLatest(types.RESEND_AUTHEN_LOGIN_ACCOUNT_CODE, resendAuthenLoginAccountCode),
    takeLatest(types.REQUEST_FORGOT_PASSWORD, requestForgotPassword),
    takeLatest(types.FORGOT_PASSWORD, forgotPassword),
    takeLatest(types.CREATE_2FA, create2FA),
    takeLatest(types.VERIFY_2FA, verify2FA),
    takeLatest(types.UPDATE_2FA, update2FA),
    takeLatest(types.GET_HISTORY_LOGIN, getHistoryLogin),
    takeLatest(types.RESEND_ACTIVE_CODE, resendActiveCode),
  ])
}
