import React, { useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import { formatCode, formatDate, roundNumber } from 'helpers'
import { DECIMALS } from 'constants/index'
import { getBscScanLink } from 'utils'
import { Badge } from 'antd'

const ItemTransaction = ({ data, setViewDetails, setModalVerifyCode, ...props }) => {
  const status = {
    COMPLETED: 'Active',
    APPROVAL_PENDING: 'Processing',
  }
  return (
    <div className="item-history-transaction" {...props} style={{ borderBottom: '0.5px solid #A7B7DD' }}>
      <div>
        <p>Transaction ID</p>
        <p>
          {/* {data.status === 'COMPLETED' ? (
            <span href={getBscScanLink(data.txhash, 'transaction', data.chain)} target="_blank" rel="noreferrer">
              {formatCode(data.txhash, 5, 5)}
            </span>
          ) : (
            data.status
          )} */}
          {formatCode(data._id, 5, 5)}
        </p>
      </div>

      <div>
        <p>Product/Services</p>
        <p>
          <span style={{ fontWeight: '700', color: '#003e9d' }}>{data.project.title.title || data.project.title}</span>
          &nbsp;
          <span>{data.package.limit_address} addresses</span>
        </p>
      </div>
      <div>
        <p>Pricing</p>
        <p>
          <span style={{ fontWeight: '700', color: '#003e9d' }}>
            $
            {data.project.period === 'YEAR'
              ? roundNumber(data.package.price_year / 10 ** DECIMALS, 6)
              : roundNumber(data.package.price_month / 10 ** DECIMALS, 6)}
          </span>
          {data.project.period && <span>&nbsp;/&nbsp;</span>}
          <span>{data.project.period}</span>
        </p>
      </div>

      <div>
        <p>Payment Method</p>
        <p>{data.package.type}</p>
      </div>

      {/* <div>
        <p>Fee</p>
        <p>{data.fee / 10 ** DECIMALS}</p>
      </div> */}
      <div>
        <p>Date</p>
        <p>{formatDate(data.createdAt * 1000, 'YYYY-MM-DD')}</p>
        {/* <p style={{ color: '#A7B7DD', fontWeight: '500' }}>{formatDate(data.createdAt * 1000)}</p> */}
      </div>
      <div>
        <p>Status</p>
        <p>
          <Badge status={data.status_payment === 'COMPLETED' ? 'success' : 'warning'} text={status[data.status_payment]} />
        </p>
      </div>
      {/* <div style={{ justifyContent: 'flex-end' }}>
        <a
          className="item-recent-transaction-view"
          role="presentation"
          onClick={() => setViewDetails({ toggle: true, dataModal: data })}
        >
          View
        </a>
        {data.status !== 'CREATED' ? (
          <a
            className="item-recent-transaction-view"
            role="presentation"
            onClick={() => setViewDetails({ toggle: true, dataModal: data })}
          >
            View
          </a>
        ) : (
          <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(data)}>
            Verify
          </a>
        )}
      </div> */}
    </div>
  )
}

export default ItemTransaction
