import ModalCustom from 'components/BaseModal'
import InputCode from 'components/InputCode'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { verifySecretKey } from 'state/project/actions'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'

const initialField = 6
const ModalGenerateKey = ({ generateKey, setSecretKey, projectId, type, token, visible, setOpen, isGoogleAuth }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [timeCountDown, setTimeCountDown] = useState()
  const [codeValue, setCodeValue] = useState('')

  const resendCode = () => {
    switch (type) {
      case 'generateKey':
        setTimeCountDown(50)
        dispatch(generateKey({ project_id: projectId }))
        break
      default:
        break
    }
  }
  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])
  useEffect(() => {
    if (visible) {
      setTimeCountDown(50)
    }
  }, [visible])
  const onSubmit = (value) => {
    const code = Object.values(value || codeValue).filter((o) => o)
    if (code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters from email of you.`)
    } else {
      setErrorMess('')
      dispatch(
        verifySecretKey({ project_id: projectId, code: +code.join('') }, (receipt) => {
          setOpen(false)
          setCodeValue('')
          setSecretKey(receipt.api_key)
        }),
      )
    }
  }
  const onChangeCode = (value) => {
    setCodeValue(value)
    const code = Object.values(value).filter((o) => o)
    if (code.length >= initialField) {
      onSubmit(value)
    }
  }
  return (
    <div>
      <ModalCustom
        centered
        className="modal-generate-key"
        onCancel={() => setOpen(false)}
        visible={Boolean(visible)}
        title={
          <div
            className="modal-verify-cody-header"
            style={{ textAlign: 'left', fontSize: window.innerWidth >= 768 ? '32px' : '24px' }}
          >
            Generate Key
          </div>
        }
      >
        <h2 className="verify-title">Do you generate a new key for your project?</h2>
        <p>{!isGoogleAuth ? 'Verification code' : 'Google Authentication'}</p>
        <InputCode value={codeValue} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
        {!isGoogleAuth && (
          <div className="verify-resend">
            <div style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>Resend in {timeCountDown}s</div>
            {timeCountDown <= 0 && (
              <a onClick={resendCode} role="presentation">
                Resend
              </a>
            )}
          </div>
        )}
        <div className="generate-confirm">
          <ButtonPrimary onClick={onSubmit}>Confirm</ButtonPrimary>
        </div>
      </ModalCustom>
    </div>
  )
}
ModalGenerateKey.defaultProps = {
  initialField: 6,
  //   onFinish: () => null,
  //   onBack: () => null,

  // type: 'generateKey',
}

export default React.memo(ModalGenerateKey)
