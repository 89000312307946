import { Form } from 'antd'
import BackLink from 'components/BackLink'
import InputCode from 'components/InputCode'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { forgotPassword, requestForgotPassword } from 'state/auth/actions'
import ButtonPrimary from 'widgets/ButtonPrimary'
import FormItem from 'widgets/FormItem'
import Input from 'widgets/Input'
import AuthCard from '../AuthCard'
import './styles.scss'

const defaultTimeCountDown = 50

const VerifyResetPasswordCode = ({
  setView,
  email,
  initialField,
  onFinish,
  children,
  title,
  timeresend,
  settimeresend,
  ...props
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const history = useHistory()
  const [viewPassword, setViewPassword] = useState(0)
  const [nextView, setNextView] = useState(0)
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(defaultTimeCountDown)

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (codeValue.length < initialField && typeof codeValue !== 'number') {
        setErrorMess(`Please enter ${initialField} characters from email of you.`)
      } else {
        setErrorMess('')
        setLoading(true)
        dispatch(
          forgotPassword(
            {
              ...values,
              email: `${email}`,
              code: codeValue,
            },
            () => {
              history.push('/login')
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          ),
        )
      }
    })
  }

  // const onChangeCode = (value) => {
  //   setCodeValue(value)
  //   console.log(typeof codeValue)
  //   if (codeValue.length >= initialField) {
  //     setViewPassword(1)
  //   }
  // }
  const onChangeCode = (value) => {
    setCodeValue(value)
    const code = Object.values(value).filter((o) => o)
    if (code.length >= initialField) {
      setNextView(1)
    } else {
      setNextView(0)
    }
  }

  const resendCode = () => {
    dispatch(
      requestForgotPassword(
        {
          email: `${email}`,
        },
        () => {
          if (settimeresend) {
            settimeresend(defaultTimeCountDown)
            setTimeCountDown(defaultTimeCountDown)
          } else {
            setTimeCountDown(defaultTimeCountDown)
          }
        },
      ),
    )
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  useEffect(() => {
    if (timeresend && timeresend > 0) {
      setTimeCountDown(timeresend)
    }
  }, [timeresend])
  const handleSetView = () => {
    setViewPassword(1)
  }
  return (
    <div className="form-verify-forgot fadeIn">
      <AuthCard className="verify-code fadeIn" {...props}>
        {/* <h2 className="verify-title">{title ? `${title}` : 'Reset Password'}</h2> */}
        <div className="form-verify-forgot-title">
          {viewPassword === 1 ? (
            <BackLink
              icon={<img src="/images/settings/left-icon.png" alt="" />}
              label="New Password"
              onClick={() => setViewPassword(0)}
            />
          ) : (
            <BackLink
              icon={<img src="/images/settings/left-icon.png" alt="" />}
              label="Verification Code"
              href="/login"
              // onClick={setView(0)}
            />
          )}
        </div>

        <Form layout="vertical" form={form}>
          {viewPassword === 1 ? (
            <>
              <FormItem
                label={<span>Enter your new password</span>}
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter password field',
                  },
                  {
                    min: 10,
                    message: `The 'password' field length must be greater than or equal to 10 characters long.`,
                  },
                ]}
              >
                <Input placeholder="************" type="password" />
              </FormItem>
              <FormItem
                label={<span>Confirm Password</span>}
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'))
                    },
                  }),
                ]}
              >
                <Input placeholder="************" type="password" />
              </FormItem>
            </>
          ) : (
            <div className="verify-input">
              {/* <p>Verification code</p> */}
              <InputCode value={codeValue} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
            </div>
          )}

          {/* <div className="verify-error">{errorMess}</div> */}
          {viewPassword !== 1 && (
            <div className="verify-resend">
              <div className="time-count" style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>
                Resend in {timeCountDown}s
              </div>
              {timeCountDown <= 0 && (
                <a onClick={() => resendCode()} role="presentation">
                  Resend
                </a>
              )}
            </div>
          )}
          {/* <div className="verify-resend">
            <div className="time-count" style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>
              Resend in {timeCountDown}s
            </div>
            {timeCountDown <= 0 && (
              <a onClick={() => resendCode()} role="presentation">
                Resend
              </a>
            )}
          </div> */}

          {/* <div className="verify-error">{errorMess}</div> */}
          <div className="verify-action-forgot-password">
            {viewPassword === 0 ? (
              <ButtonPrimary disabled={!nextView} loading={loading} onClick={handleSetView}>
                Next
              </ButtonPrimary>
            ) : (
              <ButtonPrimary loading={loading} onClick={onSubmit}>
                Change
              </ButtonPrimary>
            )}
          </div>
        </Form>
      </AuthCard>
    </div>
  )
}

VerifyResetPasswordCode.defaultProps = {
  onFinish: () => null,
  initialField: 6,
}

export default React.memo(VerifyResetPasswordCode)
