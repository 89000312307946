import { Select } from 'antd'
import React, { useState } from 'react'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'

const PaymentMethod = ({ setOpen }) => {
  const [first, setFirst] = useState('')
  return (
    <div className="payment-method">
      <div className="payment-method_header">
        <p>! Withdrawal of your whole balance</p>
      </div>
      <div className="payment-method_select">
        <p>Payment Method</p>
        <Select
          defaultValue="Paypal"
          options={[
            {
              value: 'paypal',
              label: 'Paypal',
            },
          ]}
        />
      </div>
      <div className="payment-method_btn">
        <ButtonPrimary onClick={() => setOpen(true)}>Withdraw</ButtonPrimary>
      </div>
    </div>
  )
}

export default PaymentMethod
