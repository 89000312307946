import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import InputCode from 'components/InputCode'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { verifyEmail } from 'state/auth/actions'
import AuthCard from '../AuthCard'

const VerifyAuthenCode = ({ type, initialField, token, onFinish, children, ...props }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [, setTimeCountDown] = useState(50)

  const onSubmit = (value) => {
    const code = Object.values(value || codeValue).filter((o) => o)
    if (code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters Google Authenticator on the Mobile App`)
    } else {
      setErrorMess('')
      setLoading(true)
      dispatch(
        verifyEmail(
          {
            token,
            code: code.join(''),
            os: window.navigator.platform,
            device_id: window.navigator.appVersion,
          },
          (receipt) => {
            onFinish({ code: codeValue, type: 'is_email', ...receipt })
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    }
  }

  const handleChangeCode = (value) => {
    setCodeValue(value)
    const code = Object.values(value).filter((o) => o)
    if (code.length >= initialField) {
      onSubmit(value)
    }
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  return (
    <AuthCard className="authen-code fadeIn" {...props}>
      <h2 className="authen-title">Google Authenticator</h2>
      {/* <p className="authen-des">Enter {initialField} characters or past copied text</p> */}
      <div className="authen-input">
        <InputCode initialField={initialField} value={codeValue} onChange={handleChangeCode} />
      </div>
      {/* <div className="authen-error">{errorMess}</div> */}
      <div className="authen-action">
        <ButtonPrimary loading={loading} onClick={onSubmit}>
          Done
        </ButtonPrimary>
      </div>
    </AuthCard>
  )
}

VerifyAuthenCode.defaultProps = {
  initialField: 6,
  onFinish: () => null,
}

export default VerifyAuthenCode
