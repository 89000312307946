import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'
import { Form } from 'antd'
import FormItem from 'widgets/FormItem'
import { forgotPassword, requestForgotPassword } from 'state/auth/actions'
import InputCode from 'components/InputCode'
import { useHistory } from 'react-router-dom'
import Input from 'widgets/Input'
import ModalCustom from 'components/BaseModal'

const defaultTimeCountDown = 50

const ChangePassword = ({
  visible,
  close,
  email,
  initialField,
  token,
  onFinish,
  onBack,
  children,
  title,
  timeResend,
  setTimeResend,
  ...props
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(defaultTimeCountDown)

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (codeValue.length < initialField && typeof codeValue !== 'number') {
        setErrorMess(`Please enter ${initialField} characters from email of you.`)
      } else {
        setErrorMess('')
        setLoading(true)
        dispatch(
          forgotPassword(
            {
              ...values,
              email: `${email}`,
              code: codeValue,
            },
            () => {
              history.push('/login')
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          ),
        )
      }
    })
  }
  const onChangeCode = (value) => {
    setCodeValue(value)
  }

  const resendCode = () => {
    dispatch(
      requestForgotPassword(
        {
          email: `${email}`,
        },
        () => {
          if (setTimeResend) {
            setTimeResend(defaultTimeCountDown)
            setTimeCountDown(defaultTimeCountDown)
          } else {
            setTimeCountDown(defaultTimeCountDown)
          }
        },
      ),
    )
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  useEffect(() => {
    if (timeResend && timeResend > 0) {
      setTimeCountDown(timeResend)
    }
  }, [timeResend])
  const CloseModal = () => {
    close(false)
  }
  return (
    <>
      <ModalCustom
        className="form-change-password"
        // title={<div className="form-change-password-heading">Change Password</div>}
        centered
        visible={Boolean(visible)}
        width={window.innerWidth <= 768 ? '100vw' : 580}
        height={window.innerWidth <= 768 ? '100%' : 550}
        onCancel={CloseModal}
      >
        <div className="modal-scan-action-bg" />
        {/* <h2 className="form-change-password-title">{title ? `${title}` : 'New Password'}</h2> */}
        <Form layout="vertical" form={form}>
          <FormItem
            style={{ padding: ' 0 24px' }}
            label={<span>New Password</span>}
            name="new_password"
            rules={[
              {
                required: true,
                message: 'Please enter new password field',
              },
              {
                min: 10,
                message: `The 'password' field length must be greater than or equal to 10 characters long.`,
              },
            ]}
          >
            <Input placeholder="************" type="password" />
          </FormItem>

          <FormItem
            style={{ padding: ' 0 24px' }}
            label={<span>Confirm Password</span>}
            name="confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'))
                },
              }),
            ]}
          >
            <Input placeholder="************" type="password" />
          </FormItem>
          <FormItem
            style={{ padding: ' 0 24px', marginTop: '20px' }}
            label={<span>Email OTP</span>}
            rules={[
              {
                required: true,
                message: `Please enter ${initialField} characters from email of you.`,
              },
            ]}
          >
            <div className="verify-input">
              {/* <p>Verification code</p> */}
              <InputCode
                value={codeValue}
                initialField={initialField}
                hasErrored={Boolean(errorMess)}
                onChange={onChangeCode}
                type="number"
              />
              {/* <div className="verify-resend" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '14px' }}>
                <div
                  className="time-count"
                  style={{ opacity: timeCountDown > 0 ? '1' : '0', color: '#fff', fontWeight: '500', textAlign: 'left' }}
                >
                  Resend in {timeCountDown}s
                </div>
                {timeCountDown <= 0 && (
                  <a
                    onClick={() => resendCode()}
                    role="presentation"
                    style={{ fontWeight: '700', textAlign: 'right', color: '#00F4A3' }}
                  >
                    Resend
                  </a>
                )}
              </div> */}
              {/* <div className="verify-error">{errorMess}</div> */}
            </div>
          </FormItem>
          <div className="verify-action" style={{ textAlign: 'center', padding: '24px 0' }}>
            <ButtonPrimary loading={loading} onClick={onSubmit}>
              Change
            </ButtonPrimary>
          </div>
        </Form>
      </ModalCustom>
    </>
  )
}

ChangePassword.defaultProps = {
  onFinish: () => null,
  onBack: () => null,
  initialField: 6,
}

export default React.memo(ChangePassword)
