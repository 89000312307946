import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 5.00004C2 3.34316 3.34316 2 5.00004 2H12C13.6569 2 15 3.34316 15 5.00004V7.50002C15 8.05233 14.5524 8.49998 14.0001 8.49998C13.4478 8.49998 13 8.05233 13 7.50002V5.00004C13 4.44773 12.5523 3.99991 12 3.99991H5.00004C4.44773 3.99991 3.99991 4.44773 3.99991 5.00004V19C3.99991 19.5523 4.44773 20.0001 5.00004 20.0001H12C12.5523 20.0001 13 19.5523 13 19V16.5C13 15.9477 13.4478 15.5 14.0001 15.5C14.5524 15.5 15 15.9477 15 16.5V19C15 20.657 13.6569 22 12 22H5.00004C3.34316 22 2 20.657 2 19V5.00004Z"
      fill="#003e9d"
    />
    <path
      d="M15.998 15C15.998 15.3789 16.2121 15.7251 16.5507 15.8945C16.8896 16.0638 17.295 16.0273 17.5981 15.8001L21.5981 12.8C21.8499 12.611 21.998 12.3147 21.998 12C21.998 11.6852 21.8499 11.3889 21.5981 11.1999L17.5981 8.19987C17.2951 7.97269 16.8896 7.93603 16.5507 8.10545C16.2121 8.27487 15.998 8.62107 15.998 8.99993V11H10.9982C10.4459 11 9.99805 11.4477 9.99805 12C9.99805 12.5523 10.4459 12.9999 10.9982 12.9999H15.998V15Z"
      fill="#FF2E2E"
    />
  </Svg>
)

export default Icon
