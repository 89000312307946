/* eslint-disable no-nested-ternary */
import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import { BackIcon } from 'widgets/Svg'

const BackLink = ({ icon, label, href, target, onClick, ...props }) => {
  const isHttp = href?.startsWith('http')

  const itemLinkProps = isHttp
    ? {
        ...(href && { href }),
        ...(target && { target }),
      }
    : {
        ...(href && { to: href }),
      }
  const TagName = isHttp || onClick ? 'a' : href ? Link : 'div'
  return (
    <TagName className="back-link" role="presentation" onClick={onClick && onClick} {...itemLinkProps} {...props}>
      {icon && <div className="back-icon">{icon}</div>}
      {label && <div className="back-title">{label}</div>}
    </TagName>
  )
}

BackLink.defaultProps = {
  icon: <BackIcon />,
}

export default BackLink
