/**
 * @dev
 * Root config
 */
export const ROOT_API_URL = process.env.REACT_APP_USER_API
export const G_SITE_KEY = process.env.REACT_APP_GOOGLE_SITE_KEY

/**
 * Network
 */
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const ChainId = {
  MAINNET: 56,
  TESTNET: 97,
  BEP20: 'BEP20',
  POLYGON: 'POLYGON',
}

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com',
  [ChainId.BEP20]: 'https://bscscan.com',
  [ChainId.POLYGON]: 'https://polygonscan.com',
}

export const DECIMALS = 18

/**
 * @dev Router
 * Path default config for redirect page
 */
export const HOME_PATH = '/'
export const LOGIN_PATH = '/login'

/**
 * @dev
 * Cache at locale global
 */
export const CACHE_TOKEN = 'zodipay'
export const CACHE_USER_INFO = 'live_us'
export const CACHE_CHAIN_LIST = 'chain_list'

/**
 * STATUS PROCESSING
 */

export const STATUS_TRANSACTION = {
  FAIL: 'FAIL',
  COMPLETED: 'COMPLETED',
}
