import React from 'react'
import './styles.scss'

const Block = ({ text, desc, descript, img }) => (
  <div className="block-content">
    <div className="block-content_img">
      <img src={img} alt="" />
    </div>
    <div className="block-content_text">
      <span>{text}</span>
    </div>
    <div className="block-content_desc">
      <span className="clr-blue">{desc}</span> <span className="clr-gray">{descript}</span>
    </div>
  </div>
)

export default Block
