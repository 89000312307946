import React from 'react'
import './styles.scss'

const TitleSection = ({ title, description, ...props }) => (
  <div className="warp-title-section" {...props}>
    {title ? <h2>{title}</h2> : ''}
    {description ? <p>{description}</p> : ''}
  </div>
)

export default TitleSection
