import { Button, Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import './styles.scss'

const ManageTracker = ({ addressList, projectInfo, chainList }) => {
  const history = useHistory()
  const handleNavigate = (data) => {
    history.push('/project/address', { project_id: projectInfo._id, chain_code: data.code, title: data.title })
  }
  const count = {}

  for (let i = 0; i < chainList.data?.length; i += 1) {
    const filteredArr = addressList[projectInfo?._id]?.rows?.filter((obj) => obj.chain_code === chainList.data[i].code)
    count[chainList.data[i].code] = filteredArr?.length
  }
  return (
    <div className="manage-tracker-wrapper">
      <div className="manage-tracker-wrapper_title">
        <span>Manage Chain Tracker</span>
        <p>Limit at: {projectInfo?.limit_address}</p>
      </div>
      <div className="manage-tracker-wrapper_content">
        {projectInfo?.count_address.map((item) => (
          <Row key={item.code} gutter={[16, 32]}>
            <Col className="gutter-row" xs={10} sm={10} md={10} lg={10} xl={7}>
              <div className="tracker-title">{item.title}:</div>
            </Col>
            <Col className="gutter-row" xs={8} sm={8} md={8} lg={8} xl={12}>
              <div className="tracker-desc">{item.count}</div>
            </Col>
            <Col className="gutter-row" xs={6} sm={6} md={6} lg={6} xl={5}>
              <div>
                <Button className="tracker-btn" onClick={() => handleNavigate(item)}>
                  Manage
                </Button>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  )
}

export default ManageTracker
