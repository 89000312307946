import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import './styles.scss'
import Page from 'components/App/Page'
import ButtonSecondary from 'widgets/ButtonSecondary'
import ModalTurnOn2FA, { MODAlTYPE } from 'components/ModalTurnOn2FA'
import { create2FA, getHistoryLogin } from 'state/auth/actions'
import ButtonDisable from 'widgets/ButtonDisable'
import ModalEnDis2FA from 'components/ModalEnDis2FA'
import BackLink from 'components/BackLink'
import { formatDate } from 'helpers'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const SECURITY_TYPE = {
  EMAIL: 'email',
  AUTHY: 'authy',
}

const Settings = () => {
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()
  const [paramsHistory, setParamsHistory] = useState({
    page: 1,
    pageSize: 10,
  })
  useEffect(() => {
    dispatch(getHistoryLogin(paramsHistory))
  }, [dispatch, paramsHistory])
  const { userInfo } = useSelector((state) => state.user)
  const { historyLogin } = useSelector((state) => state.auth)
  const lastLogin = historyLogin.rows[0]?.last_login
  const [modal2FA, setModal2FA] = useState({ toggle: false, dataModal: null, type: '' })
  useEffect(() => {
    // document.getElementById('private-content').scrollTo(0, 0)
    if (document.getElementById('private-content')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [])
  const handleTurnOnTypeVerify = (type) => {
    switch (type) {
      case SECURITY_TYPE.EMAIL:
        break
      case SECURITY_TYPE.AUTHY:
        if (userInfo.status_2fa === 0) {
          dispatch(
            create2FA(
              (receipt) => {
                setModal2FA({ toggle: true, dataModal: receipt, type: MODAlTYPE.VERIFY })
              },
              (e) => {
                toast.error(e?.message || 'Has an error, Please try again late.')
              },
            ),
          )
        } else {
          setModal2FA({ toggle: true, dataModal: {}, type: MODAlTYPE.UPDATE })
        }
        break
      default:
        break
    }
  }

  const handleDisableAccount = () => {
    // Soon
    setOpen(true)
  }
  // useEffect(() => {
  //   const element = document.getElementById('scroll-to-top')
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' })
  //   }
  // }, [])
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  return (
    <Page>
      <div className="warp-page-settings">
        <div className="page-settings-heading">
          <div className="page-settings-heading_info">
            {/* <img src="/images/settings/left-icon.png" alt="" />
            <h2>Security</h2> */}
            <BackLink icon={<img src="/images/settings/left-icon.png" alt="" />} label={<h2>Security</h2>} href="/settings" />
          </div>
        </div>
        <div className="page-settings-content">
          <div className="page-settings-content-body">
            <div className="settings-item">
              <div className="settings-item_span">
                <p>EVMTRACKER/Google Authenticator</p>
                <p>Set up your EVMTRACKER /Google Authenticator to provide an extra security</p>
              </div>
              <div className="settings-item_button">
                {/* 0: not active 1: active 2: disable */}
                {userInfo.status_2fa === 1 ? (
                  <ButtonDisable onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}>Disable</ButtonDisable>
                ) : (
                  <>
                    <ButtonSecondary className="btn-verify" onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}>
                      Verify
                    </ButtonSecondary>
                    {/* <ButtonDisable className="btn-disable" onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}>
                      Disable
                    </ButtonDisable> */}
                  </>
                )}
              </div>
            </div>
            <div className="settings-item">
              <div className="settings-item_span">
                <p>Email Verification</p>
                <p>Increase your password strength to enhance account settings</p>
              </div>
              <div className="settings-item_button">
                <ButtonSecondary disabled={!userInfo.status_2fa} onClick={() => setOpen(true)} className="btn-verify">
                  Default
                </ButtonSecondary>
              </div>
            </div>

            <div className="settings-item">
              <div className="settings-item_span">
                <p>Account Activity</p>
                <p>Last login: {formatDate(lastLogin * 1000)} </p>
              </div>
              <div className="settings-item_button">
                <Link to="/settings/account-activities">
                  <ButtonSecondary className="btn-verify">View</ButtonSecondary>
                </Link>
              </div>
            </div>

            <div className="settings-item">
              <div className="settings-item_span">
                <p>Disable Account</p>
                <p>Disable your account immediately</p>
              </div>
              <div className="settings-item_button">
                <ButtonDisable disabled={!userInfo.status_2fa} onClick={handleDisableAccount}>
                  Disable
                </ButtonDisable>
              </div>
            </div>
          </div>
        </div>
        <ModalTurnOn2FA
          visible={modal2FA.toggle}
          dataModal={modal2FA.dataModal}
          modalType={modal2FA.type}
          onCancel={() => setModal2FA({ toggle: false, dataModal: null })}
        />
        {/* <ModalEnDis2FA visible={open} setOpen={setOpen} /> */}
      </div>
      <ModalEnDis2FA visible={open} setOpen={setOpen} />
    </Page>
  )
}

export default withGoogleReCaptcha(Settings)
