import produce from 'immer'
import * as Types from './constants'

const initialState = {
  projectsList: {
    data: undefined,
    page: 0,
    total: 0,
  },
  projectStatus: null,
}

export default function ProjectList(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_PROJECTS_LIST_SUCCESS:
        draft.projectsList = payload
        break
      case Types.GET_PROJECT_STATUS_SUCCESS:
        draft.projectStatus = payload
        break
      default:
        return { ...state }
    }
  })
}
