import * as types from './constants'

export const getReport = (payload) => ({
  type: types.GET_REPORT,
  payload,
})
export const getReportSuccess = (payload) => ({
  type: types.GET_REPORT_SUCCESS,
  payload,
})
