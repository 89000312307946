const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  mainIssueList: undefined,
}

const contactSlice = createSlice({
  name: 'CONTACT',
  initialState,
  reducers: {
    getMainIssueList(state) {
      return state
    },
    getMainIssueListSuccess(state, action) {
      state.mainIssueList = action.payload
    },
    createContact(state) {
      return state
    },
  },
})

// Actions
export const contactActions = contactSlice.actions

// Reducer
const contactReducer = contactSlice.reducer
export default contactReducer
