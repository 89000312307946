import produce from 'immer'
import * as Types from './constants'

const initialState = {
  privateSaleList: undefined,
}

export default function PrivateSaleReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_PRIVATE_SALE_LIST_SUCCESS:
        draft.privateSaleList = payload
        break
      default:
        return { ...state }
    }
  })
}
