import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import Page from 'components/App/Page'
import { dummyRequest, executeRecaptcha, executeRecaptchaV2, formatDate, getBase64 } from 'helpers'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { uploadFile } from 'state/file/actions'
import { createProject, getDetailProject, renewProject } from 'state/project/actions'
// import { createPackage } from 'state/project/actions'
import { subscription } from 'state/subscription/actions'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'
import { current } from 'immer'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const ProjectAction = () => {
  const location = useLocation()
  const history = useHistory()
  const projUrl = new URLSearchParams(location.search)

  const [updateName, setupdateName] = useState(null)
  const [packageCurrent, setPackageCurrent] = useState(null)
  const proj = location.pathname.slice(location.pathname.indexOf('=') + 1)
  const [isExpired, setIsExpired] = useState(false)
  const projectId = projUrl.get('project_id') !== null ? projUrl.get('project_id') : proj
  const [imageData, setImageData] = useState(null)
  const [imageError, setImageError] = useState('')
  // const [idPackage, setIdPackage] = useState('')

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [projectDetail, setProjectDetail] = useState(undefined)
  const currentName = location.state?.nameCurrent || projectDetail?.title

  const [openEdit, setOpenEdit] = useState(false)
  const pathName = history.location.pathname.split('/')[2]

  // useEffect(() => {
  //   const timeNow = Math.floor(Date.now() / 1000)
  //   if (projectDetail?.expiredAt < timeNow) {
  //     console.log('expired', projectDetail?.expiredAt, '-', timeNow)
  //     setIsExpired(true)
  //   } else {
  //     console.log('actived', projectDetail?.expiredAt, '-', timeNow)
  //   }
  // }, [projectDetail?.expiredAt])

  const pathNameTitle = () => {
    if (pathName.includes('detail')) {
      if (projectDetail?.status === 'NEW') {
        return 'Confirm Repay Package'
      }
      if (projectDetail?.status === 'EXPIRED') {
        return 'Confirm Renew Package'
      }
      // if (isExpired) {
      //   return 'Confirm Renew Package'
      // }
    }
    if (pathName.includes('expire')) {
      return 'Confirm Renew Package'
    }
    if (pathName.includes('create')) {
      return 'Confirm Create Package'
    }
    if (pathName.includes('update')) {
      return 'Confirm Update Package'
    }
  }
  const pathNameCurrent = () => {
    if (pathName.includes('detail')) {
      return '/project/update/123'
    }
    if (pathName.includes('expire')) {
      return '/project'
    }
  }

  const nameUpdated = history.location.state?.updateName ? history.location.state?.updateName : updateName

  useEffect(() => {
    if (location.state?.packageCurrent) {
      setPackageCurrent(location.state?.packageCurrent)
      setupdateName(location.state?.updateName || nameUpdated)
    } else {
    }
  }, [location.state?.packageCurrent])

  useEffect(() => {
    dispatch(
      getDetailProject({ project_id: projectId }, (receipt) => {
        setProjectDetail(receipt)
      }),
    )
  }, [dispatch, projectId])
  const handleBack = () => {
    setOpenEdit(false)
    setupdateName(null)
  }

  const handleOnChangeImage = (e) => {
    if (e.file.status === 'uploading') {
      return
    }

    if (e.file.status === 'done') {
      getBase64(e.file.originFileObj).then(() => {
        setImageError('')

        dispatch(
          uploadFile(
            {
              key: 1,
              image: get(e, 'file.originFileObj', null),
            },
            (receipt) => {
              setImageData({
                ...receipt,
                image: receipt.full_link,
              })
            },
          ),
        )
      })
    }
  }

  const handleUpdateName = () => {
    form.validateFields().then((values) => {
      setupdateName(values)
      setOpenEdit(false)

      history.replace('/project/update', { updateName, projectId: projectDetail?._id, packageCurrent: projectDetail?.package })
    })
  }

  const handleCheck = () => {
    form.validateFields().then((values) => {
      if (pathName.includes('create')) {
        executeRecaptcha((tokenCaptcha) => {
          if (tokenCaptcha) {
            dispatch(
              createProject({ title: values.title, logo: imageData?._id, recaptcha: tokenCaptcha }, (receipt) => {
                // setIdPackage(receipt.data.project._id)
                dispatch(
                  subscription(
                    {
                      project_id: receipt.data.project._id,
                      package_id: packageCurrent?._id,
                      type: location.state?.type,
                      quantity: 1,
                      recaptcha: tokenCaptcha,
                    },
                    (receipt) => {
                      const { data } = receipt?.data?.orderPaypal
                      // window.location.replace(data.links[0].href)
                      window.open(data.links[0].href)
                      history.push('/project')
                    },
                    () => {
                      history.push('/project')
                    },
                  ),
                )
              }),
            )
          }
        })

        // dispatch(
        //   createProject({ title: values.title, logo: imageData?._id }, (receipt) => {
        //     // setIdPackage(receipt.data.project._id)
        //     dispatch(
        //       subscription(
        //         {
        //           project_id: receipt.data.project._id,
        //           package_id: packageCurrent?._id,
        //           type: location.state?.type,
        //           quantity: 1,
        //         },
        //         (receipt) => {
        //           const { data } = receipt?.data?.orderPaypal
        //           // window.location.replace(data.links[0].href)
        //           window.open(data.links[0].href)
        //           history.push('/project')
        //         },
        //         () => {
        //           history.push('/project')
        //         },
        //       ),
        //     )
        //   }),
        // )
      }
      if (projectDetail?.status === 'NEW') {
        if (packageCurrent) {
          executeRecaptcha((tokenCaptcha) => {
            if (tokenCaptcha) {
              dispatch(
                subscription(
                  {
                    project_id: projectDetail._id,
                    package_id: packageCurrent?._id,
                    // package_id: packageCurrent?._id || projectDetail.package?._id,
                    type: projectDetail?.period || location.state?.type,
                    quantity: 1,
                    recaptcha: tokenCaptcha,
                  },
                  (receipt) => {
                    const { data } = receipt.data.orderPaypal
                    // window.location.replace(data.links[0].href)
                    window.open(data.links[0].href, '_blank', 'noopener,noreferrer')
                    // history.push(`/project/manage/${receipt.data.project._id}`, { projectCurrent: receipt.data, packageCurrent })
                  },
                ),
              )
            }
          })
        } else {
          history.push('/project/start', {
            hasPackageFree: history.location.state?.hasPackageFree,
            projectId: projectDetail?._id,
            packageCurrent: projectDetail,
            updateName,
            nameCurrent: projectDetail?.title,
            action: 'package',
          })
        }

        // dispatch(
        //   updateProject(
        //     {
        //       project_id: location.state?.projectId ? location.state?.projectId : projectDetail._id,
        //       title: updateName?.title ? updateName?.title : projectDetail.title,
        //       package: packageCurrent || projectDetail.package,
        //       logo: imageData?._id,
        //     },
        //     (data) => {
        //       console.log(data)
        //     },
        //   ),
        // )
      }
      if (projectDetail?.status === 'EXPIRED') {
        dispatch(
          renewProject(
            { project_id: projectDetail._id },
            () => {
              history.push('/project')
            },
            () => {
              history.push('/project')
            },
          ),
        )
      }
      // if (pathName.includes('update')) {
      //   dispatch(
      //     updateProject(
      //       {
      //         project_id: location.state?.projectId ? location.state?.projectId : projectDetail._id,
      //         title: updateName?.title ? updateName?.title : projectDetail.title,
      //         logo: imageData?._id,
      //       },
      //       (data) => {
      //         console.log(data)
      //       },
      //     ),
      //   )
      // }
    })
  }
  // console.log('projectDetail: ', projectDetail, 'current package: ', packageCurrent)
  const handlePackageAction = () => {
    history.push('/project/start', {
      hasPackageFree: history.location.state?.hasPackageFree,
      projectId: projectDetail?._id,
      packageCurrent: projectDetail,
      updateName,
      nameCurrent: projectDetail?.title,
      action: 'package',
    })
  }
  return (
    <Page>
      <div className="project-action">
        <Form form={form}>
          <div className="project-action-edit-package">
            {pathName === 'create' ? (
              <div className="manage-top-wrapper">
                <div className="manage-top_logo">
                  <div className="manage-top_logo_title">
                    <span>Logo</span>
                  </div>
                  <div className="manage-top_logo_file">
                    <Dragger
                      customRequest={dummyRequest}
                      showUploadList={false}
                      accept=".jpg,.jpeg,.png"
                      style={{ border: imageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                      onChange={(e) => {
                        handleOnChangeImage(e)
                      }}
                    >
                      {imageData ? (
                        <img src={imageData.full_link} alt="" />
                      ) : (
                        <>
                          <p className="ant-upload-drag-icon">
                            <img src="/images/projectManage/open-box.svg" alt="" />
                          </p>
                          <p className="ant-upload-text">-</p>
                          <p className="ant-upload-text">Click to update your new project image</p>
                        </>
                      )}
                    </Dragger>
                  </div>
                </div>
                <div className="manage-top_name">
                  {pathName.includes('create') ? (
                    <>
                      <p className="project-name">Name</p>
                      <Row>
                        <Col span={24} md={18}>
                          <Form.Item
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: 'Please input project name',
                              },
                            ]}
                          >
                            <Input placeholder="Input your new project name" />
                          </Form.Item>{' '}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Col xs={24} sm={24} md={15}>
                        <Form.Item name="title">
                          <Input placeholder="Input your new project name" />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={9}>
                        <Form.Item>
                          <div className="manage-button">
                            <Button onClick={() => setOpenEdit(false)} className="manage-btn back">
                              Back
                            </Button>
                            <Button onClick={() => setOpenEdit(false)} className="manage-btn save" type="primary">
                              Save
                            </Button>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {openEdit ? (
                  <div className="manage-top-wrapper">
                    <div className="manage-top_logo">
                      <div className="manage-top_logo_title">
                        <span>Logo</span>
                      </div>
                      <div className="manage-top_logo_file">
                        <Dragger
                          customRequest={dummyRequest}
                          showUploadList={false}
                          accept=".jpg,.jpeg,.png"
                          style={{ border: imageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                          onChange={(e) => {
                            handleOnChangeImage(e)
                          }}
                        >
                          {imageData ? (
                            <img src={imageData.full_link} alt="" />
                          ) : (
                            <>
                              <p className="ant-upload-drag-icon">
                                <img src="/images/projectManage/open-box.svg" alt="" />
                              </p>
                              <p className="ant-upload-text">-</p>
                              <p className="ant-upload-text">Click to update your new project image</p>
                            </>
                          )}
                        </Dragger>
                      </div>
                    </div>
                    <div className="manage-top_name">
                      {pathNameCurrent() === 1 ? (
                        <Row>
                          <Col span={24} md={18}>
                            <Form.Item name="title">
                              <Input placeholder="Input your new project name" />
                            </Form.Item>
                            {/* <Input placeholder="Input your new project name" /> */}
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col xs={24} sm={24} md={15}>
                            <Form.Item name="title">
                              <Input placeholder={projectDetail?.title || nameUpdated} />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={9}>
                            <Form.Item>
                              <div className="manage-button">
                                <Button onClick={handleBack} className="manage-btn back">
                                  Back
                                </Button>
                                <Button onClick={handleUpdateName} className="manage-btn save" type="primary">
                                  Save
                                </Button>
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="edit-wrapper">
                    <div className="edit-package-name">
                      <p>Name:</p>
                      <p>{updateName?.title ? updateName?.title : currentName}</p>
                    </div>
                    <div className="edit-package-logo">
                      {projectDetail?.logo && <p>Logo:</p>}
                      {projectDetail?.logo && <img src={projectDetail?.logo} alt="" />}
                    </div>
                    <div className="edit-package-btn">
                      {/* <ButtonSecondary onClick={() => setOpenEdit(true)}>Edit</ButtonSecondary> */}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="manage-package-wrapper">
            <div className="manage-package-wrapper_title">
              <span>Package</span>
            </div>
            <div className="manage-package-wrapper_name">
              <span className="row-title">Name:</span>
              {/* <span className="row-content">{packageCurrent ? packageCurrent.title : projectDetail?.package.title}</span> */}
              <span className="row-content">{packageCurrent?.title || projectDetail?.package.title}</span>
            </div>
            <div className="manage-package-wrapper_duration">
              <div className="duration-left">
                <div className="duration-left_top">
                  <span className="row-title">Duration:</span>
                  {/* <span className="row-content">
                    {packageCurrent
                      ? formatDate(packageCurrent.createdAt * 1000, 'YYYY/MM/DD')
                      : formatDate(projectDetail?.package.createdAt * 1000, 'YYYY/MM/DD')}
                    {' - '}
                    {packageCurrent
                      ? formatDate(endTime, 'YYYY/MM/DD')
                      : formatDate(projectDetail?.package.createdAt * 1000, 'YYYY/MM/DD')}
                  </span> */}
                  <span className="row-content">
                    {formatDate(history.location.state?.startDate * 1000, 'YYYY/MM/DD') ||
                      formatDate(projectDetail?.createdAt * 1000, 'YYYY/MM/DD')}
                    {' - '}
                    {formatDate(history.location.state?.endDate, 'YYYY/MM/DD') ||
                      formatDate(projectDetail?.expiredAt * 1000, 'YYYY/MM/DD')}
                  </span>
                </div>
                <div className="duration-left_bottom">
                  <ul>
                    <li>
                      IPN (webhook) {/* <img src="/images/package/checked.svg" alt="" /> */}
                      <Checkbox checked={packageCurrent ? packageCurrent.webhook : projectDetail?.package.webhook} />
                    </li>
                    <li>
                      Tele group message {/* <img src="/images/package/checked.svg" alt="" /> */}
                      <Checkbox checked={packageCurrent ? packageCurrent.telegram_bot : projectDetail?.package.telegram_bot} />
                    </li>
                    <li>
                      Token Transfer Event {/* <img src="/images/package/checked.svg" alt="" /> */}
                      <Checkbox
                        checked={packageCurrent ? packageCurrent.nft_transfer_event : projectDetail?.package.nft_transfer_event}
                      />
                    </li>
                    <li>
                      Native Token Send {/* <img src="/images/package/checked.svg" alt="" /> */}
                      <Checkbox
                        checked={packageCurrent ? packageCurrent.transfer_event : projectDetail?.package.transfer_event}
                      />
                    </li>
                    <li>
                      Target address:{' '}
                      {packageCurrent ? packageCurrent.transaction_watcher : projectDetail?.package.transaction_watcher}{' '}
                      {/* <img src="/images/package/checked.svg" alt="" /> */}
                      <Checkbox
                        checked={packageCurrent ? packageCurrent.transaction_watcher : projectDetail?.package.transaction_watcher}
                      />{' '}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="duration-right">
                <img src="/images/projectManage/Frame.png" alt="" />
                {!pathName.includes('create') && (
                  <Button className="duration-right_button" onClick={handlePackageAction}>
                    Package
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="btn-confirm">
            <ButtonSecondary onClick={handleCheck}>{pathNameTitle()}</ButtonSecondary>
            {/* <Link to={pathNameCurrent()}>
            </Link> */}
          </div>
        </Form>
      </div>
    </Page>
  )
}

export default withGoogleReCaptcha(ProjectAction)
