import * as types from './constants'

export const getListCountry = (payload) => ({
  type: types.GET_LIST_COUNTRY,
  payload,
})

export const getListCountrySuccess = (payload) => ({
  type: types.GET_LIST_COUNTRY_SUCCESS,
  payload,
})
