import React from 'react'
import { useLocation } from 'react-router-dom'
import HeaderMobile from './HeaderMobile'
import HeaderDesktop from './HeaderDesktop'

const Header = ({ links, isLogin, showMenu, toggleMenu }) => {
  const location = useLocation()
  return (
    <>
      {/* Show on Desktop */}
      <HeaderDesktop links={links} isLogin={isLogin} location={location} toggleMenu={toggleMenu} />
      {/* Show on Mobile */}
      <HeaderMobile links={links} visible={showMenu} isLogin={isLogin} location={location} toggleMenu={toggleMenu} />
    </>
  )
}

export default Header
