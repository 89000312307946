import { all, takeLatest, call, put, takeEvery } from 'redux-saga/effects'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'
import qs from 'query-string'
import * as types from './constants'
import * as actions from './actions'

function createAddressFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmAddress/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([res, status]) => ({ data: res, status }))
}

function* createAddress({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createAddressFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('create project', error)
    if (cbe) cbe(error)
  }
}
function getAddressListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmAddress/list?${qStr}`, {
      method: 'GET',
    })
    .then(([res, status]) => ({
      data: res,
      status,
    }))
}
function* getAddressList({ payload }) {
  try {
    const { data } = yield call(getAddressListFromApi, payload)
    if (data.code === 200) {
      yield put(
        actions.getAddressListSuccess({
          ...data.data,
          projectId: payload.project_id,
          chainCode: payload.chainCode,
          address: payload.address,
        }),
      )
      // yield put(actions.getAddressListSuccess(data.data))
    }
  } catch (error) {
    console.error('getAddressList', error)
  }
}
function deleteAddressFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmAddress/delete`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([res, status]) => ({ data: res, status }))
}

function* deleteAddress({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteAddressFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('delete address', error)
    if (cbe) cbe(error)
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(types.CREATE_ADDRESS, createAddress),
    takeEvery(types.GET_ADDRESS_LIST, getAddressList),
    takeLatest(types.DELETE_ADDRESS, deleteAddress),
  ])
}
