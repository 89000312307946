import ModalCustom from 'components/BaseModal'
import InputCode from 'components/InputCode'
import React, { useState } from 'react'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'

const initialField = 6

const ModalEnDis2FA = ({ type, token, visible, setOpen }) => {
  const [code, onChangeCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  //   const [timeCountDown, setTimeCountDown] = useState(50)
  return (
    <div>
      <ModalCustom
        className="modal-en-disable2FA"
        onCancel={() => setOpen(false)}
        visible={Boolean(visible)}
        title={
          <div
            className="modal-verify-cody-header"
            style={{ textAlign: 'center', fontSize: window.innerWidth >= 768 ? '32px' : '24px' }}
          >
            Google Authenticator
          </div>
        }
      >
        <p className="modal-desc-text">
          Please enter 6 characters in the Authenticator app to <span className="modal-desc-bold">enable</span>
        </p>
        <InputCode value={code} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />

        <div className="en-disable-confirm">
          <ButtonPrimary>Back</ButtonPrimary>
          <ButtonPrimary>Done</ButtonPrimary>
        </div>
      </ModalCustom>
    </div>
  )
}

ModalEnDis2FA.defaultProps = {
  //   initialField: 6,
  //   onFinish: () => null,
  //   onBack: () => null,

  type: 'login',
}

export default React.memo(ModalEnDis2FA)
