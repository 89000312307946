import Page from 'components/App/Page'
import RequestWithdraw from 'components/modalRequestWithdraw'
import ReferrerCard from 'components/ReferrerCard'
import { removeEmpty, roundNumber } from 'helpers'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getListStatus, getTransactionList } from 'state/transactions/actions'
import { createWallet, getCurrency, getCurrencyAttr, resendVerifyWithdrawCode, fetchWalletBalance } from 'state/wallets/actions'
import WalletCard from './components/balanceCard'
import PaymentMethod from './components/paymentMethod'
import RecentTransaction from './components/recentTransaction'
import './styles.scss'
import { DatePicker, Table } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import TablePagination from 'components/TablePagination/TablePagination'
import useIsMobile from 'hooks/useIsMobile'
import { DECIMALS } from 'constants/index'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const WalletEVM = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)
  const isMobile = useIsMobile()
  const { userInfo } = useSelector((state) => state.user)

  const { rows, total } = useSelector((state) => state.transactions.transactionsList)
  const { transactionsList } = useSelector((state) => state.transactions)

  const [setModalVerifyCode] = useState({ toggle: false, dataModal: null })
  const { RangePicker } = DatePicker

  const { chainList, currency, currencyAttr, walletsAddress, balance } = useSelector((state) => state.wallets)
  // useEffect(() => {
  //   dispatch(fetchWalletBalance({ currency_code: 'USD' }))
  // }, [dispatch])
  const totalBalance = useMemo(() => {
    if (currency) {
      // return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
      const Usd = currency.find((i) => i.code === 'USD')
      return Usd.balance
    }
    return undefined
  }, [currency])
  useEffect(() => {
    // document.getElementById('private-content').scrollTo(0, 0)
    if (document.getElementById('private-content')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [])
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    txhash: '',
    type: '',
    from_date: '',
    to_date: '',
    chain_code: '',
    currency: '',
    status_url: false,
  })

  useEffect(() => {
    const parseSearch = Object.fromEntries(new URLSearchParams(location.search).entries())
    setTxParams((prev) => ({
      ...prev,
      ...parseSearch,
      status_url: true,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (txParams.status_url) {
      dispatch(getTransactionList(removeEmpty(txParams, { removeFields: ['status_url'] })))
    }
  }, [dispatch, txParams])
  // Search Transaction
  const handleSearchTransaction = debounce((value) => {
    setTxParams((prev) => ({
      ...prev,
      page: 1,
      pageSize: 10,
      txhash: value,
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    queryParams.set('txhash', value || '')
    history.replace(`?${queryParams.toString()}`)
  }, 500)

  useEffect(() => {
    dispatch(getCurrency())
  }, [dispatch])

  useEffect(() => {
    dispatch(getListStatus())
  }, [dispatch])

  const handleReVerifyWithdraw = useCallback(
    (item) => {
      dispatch(
        resendVerifyWithdrawCode({
          transaction_id: item._id,
        }),
      )
      setModalVerifyCode({ toggle: true, dataModal: item })
    },
    [dispatch, setModalVerifyCode],
  )

  const handleChangePagination = (key) => {
    switch (key) {
      case 'previous':
        setTxParams({ ...txParams, page: txParams.page - 1 })

        break
      case 'next':
        setTxParams({ ...txParams, page: txParams.page + 1 })
        break

      default:
        return txParams
    }
  }
  const handleChangeShow = (value) => {
    setTxParams({
      ...txParams,
      pageSize: value,
    })
  }
  // Filter date
  const onChange = (dates, dateStrings) => {
    if (dates) {
      setTxParams((prev) => ({
        ...prev,
        page: 1,
        pageSize: 10,
        from_date: new Date(dateStrings[0]).getTime() / 1000,
        to_date: new Date(dateStrings[1]).getTime() / 1000,
        status_url: true,
      }))
    } else {
      setTxParams((prev) => ({
        ...prev,
        from_date: '',
        to_date: '',
        status_url: true,
      }))
    }
  }
  return (
    <Page>
      <div className="wallet-wrapper">
        <div className="wallet-top-content">
          <WalletCard
            text="Banlance (USD)"
            currency={roundNumber(totalBalance / 10 ** DECIMALS)}
            img="/images/dashboard/icon.png"
          />
          <WalletCard text="Pending Cleared" currency={0} img="/images/pending.png" pending />
          <PaymentMethod setOpen={setOpenModal} />
        </div>
        <div className="wallet-top-mobile">
          <div className="balance-mobile">
            <WalletCard
              text="Banlance (USD)"
              currency={roundNumber(totalBalance / 10 ** DECIMALS)}
              img="/images/dashboard/icon.png"
            />
            <WalletCard text="Pending Cleared" currency={0} img="/images/pending.png" pending />
          </div>
          <PaymentMethod setOpen={setOpenModal} />
        </div>
        {isMobile && <div style={{ height: '2px', background: '#0D6EFF', borderRadius: '1px', marginTop: '32px' }} />}
        <div className="referral-link">
          <ReferrerCard userInfo={userInfo} />
        </div>
        <div className="time-picker-div">
          <h2>Recent Transaction</h2>
          <div className="recent-trans-time-picker">
            <img className="calender" src="/images/dashboard/calender.png" alt="calender" />
            <RangePicker
              onChange={onChange}
              placeholder={['..../../..', '..../../..']}
              className="datetime-picker"
              style={{
                width: '250px',
                background: '#FFFFFF',
                height: '32px',
                borderColor: '#003e9d',
                borderRadius: '8px',
                borderWidth: '0.5px',
              }}
              suffixIcon={<img src="/images/dashboard/down-icon.png" alt="down-icon" />}
              separator={<span>-</span>}
              format="YYYY/MM/DD"
            />
          </div>
        </div>
        <div className="wallet-table-content">
          <RecentTransaction
            transactionsList={transactionsList}
            txParams={txParams}
            setTxParams={setTxParams}
            setModalVerifyCode={handleReVerifyWithdraw}
            rows={rows}
            total={total}
            pagination={false}
          />
          {/* <Table rowKey={(record) => record._id} dataSource={rows} columns={columns} pagination={false} /> */}
          {!isMobile && (
            <div className="wallet-history-pagination">
              <div>
                <TablePagination
                  page={txParams.page}
                  pageSize={txParams.pageSize}
                  total={rows?.total}
                  onChange={handleChangePagination}
                  onChangeShow={handleChangeShow}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <RequestWithdraw currency={roundNumber(totalBalance / 10 ** DECIMALS)} visible={openModal} setOpen={setOpenModal} />
    </Page>
  )
}

export default withGoogleReCaptcha(WalletEVM)
