import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import ModalCustom from 'components/BaseModal'
import { DECIMALS } from 'constants/index'
import { formatCode, formatDate, roundNumber } from 'helpers'
import { useHistory } from 'react-router-dom'

const ModalViewDetails = ({ visible, dataModal, close }) => {
  const history = useHistory()
  if (!dataModal) return <></>
  const pathName = history.location.pathname.split('/')
  const pathNameTitle = () => {
    if (pathName.includes('wallet')) {
      return <>Transaction Detail</>
    }
    if (pathName.includes('deposit')) {
      return <>Deposit Details</>
    }
    if (pathName.includes('withdraw')) {
      return <>Withdraw Details</>
    }
    if (pathName.includes('history')) {
      return <>Transaction Details</>
    }
  }
  return (
    <ModalCustom
      className="modal-history-details"
      title={<div className="history-detail-heading">{pathNameTitle() ? pathNameTitle() : 'Transaction Detail'}</div>}
      centered
      visible={Boolean(visible)}
      width={window.innerWidth <= 768 ? '100vw' : 580}
      height={window.innerWidth <= 768 ? '100%' : 550}
      onCancel={() => close(false)}
    >
      <div className="modal-scan-action-bg" />

      {/* <ul className="modal-history-details-steps">
        <li>
          <p>Withdraw order submitted</p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </li>
        <li>
          <p>System processing</p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </li>
        <li>
          <p>{dataModal.status}</p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </li>
      </ul> */}

      {/* <div className="modal-history-details-note">
        <p>Please note that you will receive a email once it is complete.</p>
        <p>Why hasn’t my history arrived?</p>
      </div> */}

      <div className="modal-history-details-info">
        <div className="modal-history-details-info-item">
          <p>TransactionID</p>
          <p className="modal-history-details-info-item-address">
            {dataModal._id ? formatCode(dataModal._id, 4, 4) : '0xc4c16...2b21a'}
          </p>
        </div>

        <div className="modal-history-details-info-item">
          <p>Status</p>
          <p data-status={dataModal.status}>{dataModal.status}</p>
        </div>

        <div className="modal-history-details-info-item">
          <p>Amount</p>
          <p>
            $
            <CurrencyFormat
              value={roundNumber(dataModal.amount / 10 ** DECIMALS, 6)}
              displayType="text"
              thousandSeparator
              renderText={(v) => v}
            />
          </p>
        </div>

        <div className="modal-history-details-info-item">
          <p>Fee</p>
          <p>{dataModal.fee / 10 ** DECIMALS}</p>
        </div>

        <div className="modal-history-details-info-item">
          <p>Description</p>
          <p>{dataModal.description || '-'}</p>
        </div>
        <div className="modal-history-details-info-item">
          <p>Time</p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </div>
      </div>
    </ModalCustom>
  )
}

export default ModalViewDetails
