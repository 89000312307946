import ModalCustom from 'components/BaseModal'
import React, { useState } from 'react'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'

const ModalConfirmPackage = ({ visible, setOpen }) => {
  const [first, setFirst] = useState('')
  return (
    <div>
      <ModalCustom className="modal-confirm-package" onCancel={() => setOpen(false)} visible={Boolean(visible)}>
        <div className="modal-confirm-img">
          <img src="/images/projectManage/Frame.png" alt="" />
        </div>
        <div className="modal-confirm-btn">
          <ButtonPrimary>Confirm Update Package</ButtonPrimary>
        </div>
      </ModalCustom>
    </div>
  )
}

export default ModalConfirmPackage
