import React from 'react'
import './styles.scss'

const PointSecurity = ({ point }) => (
  <>
    {[...Array(point)].map((x, i) => (
      <img className={i === 0 ? 'point-item first-point' : 'point-item'} src="/images/account/line.png" key={i} alt="point" />
    ))}
  </>
)

export default PointSecurity
