import React, { useState } from 'react'
import { Modal } from 'antd'
import './styles.scss'
import ButtonPrimary from 'widgets/ButtonPrimary'

const ModalCustom = ({ className, onOke, loading, children, buttonLabel, ...props }) => {
  const [first, setFirst] = useState('second')

  return (
    <Modal
      className={`custom-modal ${className}`}
      footer={
        onOke ? (
          <>
            <ButtonPrimary type="button" roles="presentation" loading={loading} onClick={props.onCancel}>
              Back
            </ButtonPrimary>
            <ButtonPrimary type="button" roles="presentation" loading={loading} onClick={onOke}>
              {buttonLabel}
            </ButtonPrimary>
          </>
        ) : (
          ''
        )
      }
      {...props}
    >
      {children}
    </Modal>
  )
}

ModalCustom.defaultProps = {
  loading: false,
  buttonLabel: 'Confirm',
}

export default ModalCustom
