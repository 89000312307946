import * as types from './constants'

export const getProjectList = (payload) => ({
  type: types.GET_PROJECTS_LIST,
  payload,
})
export const getProjectListSuccess = (payload) => ({
  type: types.GET_PROJECTS_LIST_SUCCESS,
  payload,
})
export const getProjectStatus = (payload, cbs, cbe) => ({
  type: types.GET_PROJECT_STATUS,
  payload,
  cbs,
  cbe,
})
export const getProjectStatusSuccess = (payload) => ({
  type: types.GET_PROJECT_STATUS_SUCCESS,
  payload,
})
