import './styles.scss'
import React from 'react'
import { Link } from 'react-router-dom'
import Page from 'components/App/Page'
import Form from './component/form'
import ButtonDisable from 'widgets/ButtonDisable'
import ModalVerificationCode from 'components/ModalVerificationCode'
import ModalTurnOn2FA, { MODAlTYPE } from 'components/ModalTurnOn2FA'
import ModalScan from 'components/ModalScan'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const AccountActivities = () => (
  <Page>
    <div className="warp-page-account-activity">
      <Form />
    </div>
    <Link
      style={{ display: window.innerWidth <= 991 ? 'block' : 'none' }}
      className="activity-btn-logout"
      to="/settings/account-activities"
    >
      {/* <ButtonDisable className="btn-disable" style={{ width: '130px', height: '35px' }}>
        Log Out All
      </ButtonDisable> */}
    </Link>

    {/* <ModalVerificationCode visible="true" /> */}
    {/* <ModalTurnOn2FA visible="true" /> */}
    {/* <ModalScan visible="true" /> */}
  </Page>
)

export default withGoogleReCaptcha(AccountActivities)
