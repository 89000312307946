import { formatDate } from 'helpers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAddressList } from 'state/address/actions'
import { ProfileSetting } from 'widgets/Svg'
import './styles.scss'

const ProjectDetail = ({ hasPackageFree, data }) => {
  const [disable, setDisable] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { addressList } = useSelector((state) => state.address)
  // useEffect(() => {
  //   dispatch(getAddressList({ project_id: data._id, page: 1, pageSize: 10 }))
  // }, [dispatch, data?._id])
  const timeNow = Math.floor(Date.now() / 1000)
  useEffect(() => {
    if (data.status === 'EXPIRED') {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [data.status, timeNow])

  const handleNavigate = () => {
    if (data.status === 'EXPIRED' || data.status === 'NEW') {
      history.push(`/project/detail?project_id=${data?._id}`, { hasPackageFree: history.location.state?.hasPackageFree })
    } else {
      history.push(`/project/manage/${data._id}`, { hasPackageFree })
    }
  }

  return (
    <div className="project-content">
      <div className={`projects-right ${disable ? 'disabled' : ''}`}>
        <div className="projects-content_img">
          {data?.logo ? <img src={data.logo} alt="" /> : <img src="/images/project/Vector.png" alt="" />}
        </div>
      </div>
      <div className="project-left">
        <div className={`project-left_info ${disable ? 'disabled' : ''}`}>
          <div className="project-left_info_title">
            <span>
              {data.title} - {data.package ? data.package.title : 'Pending Payment'}
            </span>
          </div>
          <div className="project-left_info_value">
            <span>
              {data?.count_address || 0} {data?.count_address ? 'Addresses' : 'Address'}
            </span>
          </div>
          <div className="project-left_info_desc">
            <span>Limit {data.limit_address} tracked addresses</span>
          </div>
        </div>

        <div className="project-detail">
          <div className="project-detail_setting">
            {/* <Link to={`/project/${disable ? 'expire' : `detail?project_id=${data._id}`}`}>
              <ProfileSetting />
            </Link> */}
            {/* <Link
              to={
                data.status === 'RUNNING'
                  ? `/project/manage/${data._id}`
                  : `/project/${disable ? 'expire' : `detail?project_id=${data._id}`}`
              }
            >
              <ProfileSetting />
            </Link> */}
            {/* <Link to={`/project/manage/${data._id}`}>
              <ProfileSetting />
            </Link> */}
            {/* <ProfileSetting onClick={() => history.push(`/project/manage/${data._id}`, { hasPackageFree })} /> */}
            <ProfileSetting onClick={handleNavigate} />
          </div>
          <div className="project-detail_info">
            <span>{disable ? 'Expired' : `Expired at ${formatDate(data.expiredAt * 1000, 'YYYY/MM/DD')}`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetail
