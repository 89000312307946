import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './styles.scss'
import TitleSection from '../titleSection'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import RealTimeTracking from '../RealTimeTracking'
// import { Row, Col } from 'antd'

const RealTime = () => {
  const { userInfo } = useSelector((state) => state.user)
  return (
    <div className="warp-realtime">
      <TitleSection title="REALTIME TRACKING YOUR ADDRESSES" />
      {/* SYNC ADDRESS */}
      <div className="warp-realtime_first">
        <div className="group-realTime">
          <div className="group-realTime_col">
            <RealTimeTracking
              title="SYNC ADDRESS"
              description="Submit addresses to EVM TRACKER"
              desc="And received notifications automatically"
              linkText="Start for free now >>"
              url="https://docs.evmtracker.com/evm-tracker/manage-project/sync-address"
            >
              <div className="icon-bot" style={{ display: 'flex' }}>
                <ul>
                  <li>Webhook</li>
                  <img src="/images/Home/banner/webhook.png" alt="" />
                </ul>
                <ul>
                  <li>Telegram Bot</li>
                  <img src="/images/Home/banner/telegram.png" alt="" />
                </ul>
              </div>
            </RealTimeTracking>
          </div>
          <div className="process">
            <div className="process_bg">
              <div className="bg_icons">
                <img src="/images/Home/banner/Project.png" alt="" />
              </div>
              <h3>Your Project</h3>
            </div>
            <div className="arrow">
              <div className="arrow_right">
                <p>sync</p>
                <img src="/images/Home/banner/rightArrow.png" alt="" />
              </div>
              <div className="arrow_left">
                <p>instant notification</p>
                <img src="/images/Home/banner/leftArrow.png" alt="" />
              </div>
            </div>
            <div className="process_bg">
              <div className="bg_icons">
                <img src="/images/Home/banner/logo.png" alt="" />
              </div>
              <h3>EVMTRACKER</h3>
            </div>
          </div>
        </div>
      </div>
      {/* EXPLORE TXHASH DATA */}
      {/* <div className="warp-realtime_second">
        <div className="group-realTime reverse">
          <div className="process_right">
            <div className="bg_data">
              <div className="textData">
                <p>&#123; txhash &#125;</p>
              </div>
              <div className="textData">
                <p>&#123; decoded data &#125;</p>
              </div>
            </div>
            <div className="arrow">
              <div className="arrow_right">
                <p>Send request</p>
                <img src="/images/Home/banner/rightArrow.png" alt="" />
              </div>
              <div className="arrow_left">
                <p>Get response</p>
                <img src="/images/Home/banner/leftArrow.png" alt="" />
              </div>
            </div>
            <div className="bg_img">
              <div className="bg_icons">
                <img src="/images/Home/banner/logo.png" alt="" />
              </div>
              <h3>EVMTRACKER</h3>
            </div>
          </div>
          <div className="group-realTime_col">
            <RealTimeTracking
              title="EXPLORE TXHASH DATA"
              description="Get txHash decoded data directly"
              desc="Easy to check user’s tx transfer to your address"
              linkText="Use Case | Explore APIs documents >>"
              url="https://docs.evmtracker.com/evm-tracker/apis/webhook"
            />
          </div>
        </div>
      </div> */}
      {/* ALERT ADDRESS */}
      <div className="warp-realtime_first">
        <div className="group-realTime">
          <div className="group-realTime_col">
            <RealTimeTracking
              title="ADDRESS ALERT"
              description="Submit addresses to EVM TRACKER"
              desc="And received Alert Realtime for Transactions, ERC20, ERC1155 transfer"
              linkText="Learn how to sync your address >>"
              url="https://docs.evmtracker.com/evm-tracker/apis/sync-address"
            >
              <div className="icon-bot" style={{ display: 'flex' }}>
                <ul>
                  <li>Webhook</li>
                  <img src="/images/Home/banner/webhook.png" alt="" />
                </ul>
                <ul>
                  <li>Telegram Bot</li>
                  <img src="/images/Home/banner/telegram.png" alt="" />
                </ul>
              </div>
            </RealTimeTracking>
          </div>
          <div className="process">
            <div className="process_bg">
              <div className="bg_icons">
                <img src="/images/Home/banner/Project.png" alt="" />
              </div>
              <h3>Your Project</h3>
            </div>
            <div className="arrow">
              <div className="arrow_right">
                <p>sync</p>
                <img src="/images/Home/banner/rightArrow.png" alt="" />
              </div>
              <div className="arrow_left">
                <p>instant notification</p>
                <img src="/images/Home/banner/leftArrow.png" alt="" />
              </div>
            </div>
            <div className="process_bg">
              <div className="bg_icons">
                <img src="/images/Home/banner/logo.png" alt="" />
              </div>
              <h3>EVMTRACKER</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="warp-realtime_btn">
        <Link to="/register" target="_blank" rel="noreferrer">
          <ButtonPrimary>Get Started</ButtonPrimary>
        </Link>
      </div>
    </div>
  )
}

export default RealTime
