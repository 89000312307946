import {
  HomeIcon,
  SettingIcon,
  WalletEvmIcon,
  ChecklistIcon,
  LogoutIcon,
  AccountIcon,
  PrivateSaleIcon,
  SecurityIcon,
  WalletIcon,
  StakeIcon,
  AffiliateIcon,
} from 'widgets/Svg'

export const linksPublic = [
  {
    label: 'Dashboard',
    href: '/Dashboard',
    target: '',
  },
  {
    label: 'Documentation',
    href: 'https://docs.evmtracker.com/evm-tracker/',
    target: '_blank',
  },
  {
    label: 'API Document',
    href: 'https://docs.evmtracker.com/evm-tracker/apis/api-endpoint',
    target: '_blank',
  },
  {
    label: 'Contact Us',
    href: 'https://evmtracker.com/contact/',
    target: '_blank',
  },
]

export const linksPrivate = [
  {
    label: 'Dashboard',
    href: '/dashboard',
    target: '',
    icon: HomeIcon,
  },
  {
    label: 'Project',
    href: '/project',
    target: '',
    icon: ChecklistIcon,
  },
  {
    label: 'Affiliate',
    href: '/affiliate',
    target: '',
    icon: AffiliateIcon,
  },
  {
    label: 'Settings',
    href: '/settings',
    target: '',
    icon: SettingIcon,
  },
  // {
  //   label: 'Stake',
  //   href: '/stake',
  //   target: '',
  //   icon: StakeIcon,
  // },
]

export const linksNavigatorMobile = linksPrivate
