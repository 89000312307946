import produce from 'immer'
import * as Types from './constants'

const initialState = {
  packagesList: {
    data: undefined,
    page: 0,
    total: 0,
  },
  packageStatus: null,
}

export default function Package(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_PACKAGES_LIST_SUCCESS:
        payload.rows.sort((a, b) => a.price_year - b.price_year)
        draft.packagesList = payload
        break
      case Types.GET_PACKAGE_STATUS_SUCCESS:
        draft.packageStatus = payload
        break
      default:
        return { ...state }
    }
  })
}
