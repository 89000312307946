import './styles.scss'
import React from 'react'
import { Input, Select } from 'antd'

const { Option } = Select

const SelectWithImage = ({ showSearch, label, dataSelect, placeholder, title, defaultValue, style, ...props }) => (
  <div className="box-form-control" style={style}>
    <p>{title}</p>
    <Input.Group compact>
      <Select defaultValue={defaultValue} placeholder={placeholder} showSearch {...props}>
        {defaultValue === 'All' && <Option value="">All</Option>}
        {dataSelect?.map((item, index) => (
          <Option value={JSON.stringify(item)} key={index}>
            {item.image && (
              <span>
                <img src={item.image} alt={item.label} />
              </span>
            )}
            {item.label}
          </Option>
        ))}
      </Select>
    </Input.Group>
  </div>
)

export default SelectWithImage
