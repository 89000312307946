/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dropdown, Menu, Select } from 'antd'
import ProfileAccount from 'components/ProfileAccount'
import { DEFAULT_LANGUAGE, OPTIONS_LANG } from 'language/config'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useLanguage } from 'state/language/hooks'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'

const HeaderDesktop = ({ links, location, isLogin }) => {
  const optionSelect = OPTIONS_LANG.map((item) => item)
  const { language, setLanguage } = useLanguage()
  const handleChange = (value) => {
    setLanguage(value)
  }
  const menu = (
    <Menu className="menu-download-dropdown">
      <Menu.Item>
        <Link to="#" target="_blank" rel="noreferrer">
          <ButtonSecondary
            style={{
              height: '30px',
              fontSize: '14px',
            }}
          >
            CH Play
          </ButtonSecondary>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="#" target="_blank" rel="noreferrer">
          <ButtonSecondary
            style={{
              height: '30px',
              fontSize: '14px',
            }}
          >
            App Store
          </ButtonSecondary>
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <header className="header-container-desktop">
      <div className="header-desktop">
        <div className="header-left">
          <Link to="/">
            <div className="header-logo">
              <img src="/images/logo-text.png" title="Evm Tracker" alt="Evm Tracker" />
            </div>
          </Link>
        </div>
        {/* <ul className="header-link">
          {links.map((item, index) => {
            const isHttp = item?.href?.startsWith('http')
            const Tag = isHttp ? 'a' : Link
            const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
            return (
              <li key={`menu-${index}`} className={location.pathname === item.href ? 'active' : ''}>
                <Tag {...propsLink} rel="noreferrer">
                  {item.label}
                </Tag>
              </li>
            )
          })}
        </ul> */}

        <div className="header-right">
          <ul className="header-link">
            {links.map((item, index) => {
              const isHttp = item?.href?.startsWith('http')
              const Tag = isHttp ? 'a' : Link
              const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
              return (
                <li key={`menu-${index}`} className={location.pathname === item.href ? 'active' : ''}>
                  <Tag {...propsLink} rel="noreferrer">
                    {item.label}
                  </Tag>
                </li>
              )
            })}
          </ul>
          {isLogin ? (
            <ProfileAccount />
          ) : (
            <>
              {location.pathname === '/login' || location.pathname === '/' || location.pathname === '/forgot-password' ? (
                <Link to="/login" className="actions">
                  <ButtonPrimary
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    Sign in
                  </ButtonPrimary>
                </Link>
              ) : (
                <Link to="/login" className="actions">
                  <ButtonSecondary
                    className="btn-unActions"
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    Sign in
                  </ButtonSecondary>
                </Link>
              )}
              {location.pathname === '/register' ? (
                <Link to="/register" className="actions" style={{ marginLeft: '15px' }}>
                  <ButtonPrimary
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    Sign up
                  </ButtonPrimary>
                </Link>
              ) : (
                <Link to="/register" className="actions" style={{ marginLeft: '15px' }}>
                  <ButtonSecondary
                    className="btn-unActions"
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    Sign up
                  </ButtonSecondary>
                </Link>
              )}
            </>
          )}
          <Link className="actions">
            <div className="header-logo">
              <img style={{ width: '28px' }} src="/images/languages.png" title="Eng UK" alt="Eng UK" />
              {/* <span style={{ fontWeight: 700, color: '#0F1B36', fontSize: '16px' }}>Eng UK</span> */}

              <Select
                onChange={handleChange}
                dropdownClassName="language-setting"
                defaultValue={language}
                style={{
                  width: 60,
                }}
              >
                {optionSelect.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.id}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Link>
        </div>
      </div>
    </header>
  )
}

export default HeaderDesktop
