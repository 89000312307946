import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import BackLink from 'components/BackLink'
import { HistoryTable } from 'components/AccountActiveTable'
import { getHistoryLogin, logout } from 'state/auth/actions'
import useIsMobile from 'hooks/useIsMobile'
import ButtonDisable from 'widgets/ButtonDisable'
import { Link } from 'react-router-dom'

const Form = () => {
  const isMobile = useIsMobile()

  const { rows, total } = useSelector((state) => state.transactions.transactionsList)
  const { historyLogin } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  useEffect(() => {
    // document.getElementById('private-content').scrollTo(0, 0)
    if (document.getElementById('private-content')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [])
  const [paramsHistory, setParamsHistory] = useState({
    page: 1,
    pageSize: 10,
  })
  useEffect(() => {
    dispatch(getHistoryLogin(paramsHistory))
  }, [dispatch, paramsHistory])

  return (
    <>
      <div className="Account-activity-warp-history-form">
        <div className="go-back">
          <BackLink
            icon={<img src="/images/settings/left-icon.png" alt="" />}
            label="Account Activity"
            href="/settings/security"
          />
          <Link to="/settings/account-activities">
            <ButtonDisable className="btn-disable" onClick={() => dispatch(logout())}>
              Log Out All
            </ButtonDisable>
          </Link>
        </div>

        <div className="history-form">
          <HistoryTable
            paramsHistory={paramsHistory}
            historyLogin={historyLogin}
            rows={rows}
            total={total}
            setParamsHistory={setParamsHistory}
            isMobile={isMobile}
          />
        </div>
      </div>
    </>
  )
}

export default Form
