import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import QRcode from 'qrcode.react'
import './styles.scss'
import { executeRecaptcha, executeRecaptchaV2 } from 'helpers'
import InputCode from 'components/InputCode'
import ModalCustom from 'components/BaseModal'
import { update2FA, verify2FA } from 'state/auth/actions'
import { getProfile } from 'state/user/actions'
import InputCopy from 'components/FielCopy/InputCopy'
import useIsMobile from 'hooks/useIsMobile'

export const MODAlTYPE = {
  CREATE: 'create',
  VERIFY: 'verify',
  UPDATE: 'update',
}
const initialField = 6

/**
 * @param {*} dataModal.code if is required for show QR Code
 * @param {*} modalType type of dispatch
 */

const ModalTurnOn2FA = ({ visible, dataModal, modalType, onCancel, ...props }) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const { userInfo } = useSelector((state) => state.user)

  const [code, onChangeCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const handleCancel = () => {
    setErrorMess('')
    setLoading(false)
    onChangeCode('')
    onCancel()
  }

  const handleOk = () => {
    if (code.length < initialField) return setErrorMess('An occurred error, Please try again late.')
    setLoading(true)
    setErrorMess('')
    switch (modalType) {
      case MODAlTYPE.VERIFY:
        dispatch(
          verify2FA(
            {
              token: code,
            },
            () => {
              setLoading(false)
              handleCancel()
              dispatch(getProfile())
            },
            () => {
              setLoading(false)
            },
          ),
        )
        break
      case MODAlTYPE.UPDATE:
        dispatch(
          update2FA(
            {
              token: code,
              status: userInfo.status_2fa === 2,
            },
            () => {
              setLoading(false)
              handleCancel()
              dispatch(getProfile())
            },
            () => {
              setLoading(false)
              dispatch(getProfile())
            },
          ),
        )
        break
      default:
        setLoading(false)
        dispatch(getProfile())
        handleCancel()
        break
    }
  }

  const renderModalDescription = (type) => {
    switch (type) {
      case MODAlTYPE.VERIFY:
        return (
          <p className="google-verify">
            Please enter 6 characters in the Authenticator app to <span>enable</span>
          </p>
        )
      case MODAlTYPE.UPDATE:
        return (
          <p className="google-verify">
            Please enter 6 characters in the Authenticator app to <span>enable</span>
          </p>
        )
      default:
        return 'Get code from your email'
    }
  }

  return (
    <ModalCustom
      title={
        <div
          className="modal-verify-cody-header"
          style={{ textAlign: 'center', fontSize: window.innerWidth >= 768 ? '32px' : '24px' }}
        >
          Google Authenticator
        </div>
      }
      className="modal-2fa"
      visible={visible}
      loading={loading}
      onOke={handleOk}
      onCancel={handleCancel}
      {...props}
    >
      {dataModal?.data && (
        <div className="canvas-qrcode">
          <QRcode
            renderAs="svg"
            size={isMobile ? 146 : 200}
            value={dataModal.data?.toString()}
            includeMargin
            fgColor="#fff"
            bgColor="#003e9d"
          />
        </div>
      )}
      <div className="powered-by">
        Powered by <span>EVM TRACKER</span>
      </div>
      {/* {dataModal?.code && <InputCopy value={dataModal.code} />} */}
      <div className="text-note">{renderModalDescription(modalType)}</div>
      <InputCode value={code} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
    </ModalCustom>
  )
}

export default ModalTurnOn2FA
