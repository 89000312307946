import { Button, Col, Form, Input, Row, Upload } from 'antd'
import { dummyRequest, getBase64 } from 'helpers'
import { get } from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { uploadFile } from 'state/file/actions'
import { updateProject } from 'state/project/actions'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'

const { Dragger } = Upload

const ManageTop = ({ projectInfo }) => {
  const [form] = Form.useForm()
  const [openEdit, setOpenEdit] = useState(false)
  const [imageData, setImageData] = useState(null)
  const [imageError, setImageError] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const [updateName, setUpdateName] = useState('')
  const handleOnChangeImage = (e) => {
    if (e.file.status === 'uploading') {
      return
    }

    if (e.file.status === 'done') {
      getBase64(e.file.originFileObj).then(() => {
        setImageError('')

        dispatch(
          uploadFile(
            {
              key: 1,
              image: get(e, 'file.originFileObj', null),
            },
            (receipt) => {
              setImageData({
                ...receipt,
                image: receipt.full_link,
              })
            },
          ),
        )
      })
    }
  }
  const handleUpdate = () => {
    form.validateFields().then((values) => {
      setUpdateName(values.title)
      dispatch(
        updateProject(
          {
            project_id: projectInfo._id,
            title: values.title,
            logo: imageData?._id,
          },
          (data) => {
            // history.push(`/project/manage/${projectInfo._id}`)

            setOpenEdit(false)
          },
        ),
      )
    })
  }

  return (
    <>
      {openEdit ? (
        <div className="manage-top-wrapper">
          <Form form={form}>
            <div className="manage-top_logo">
              <div className="manage-top_logo_title">
                <span>Logo:</span>
              </div>
              <div className="manage-top_logo_file">
                <Dragger
                  customRequest={dummyRequest}
                  showUploadList={false}
                  accept=".jpg,.jpeg,.png"
                  style={{ border: imageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                  onChange={(e) => {
                    handleOnChangeImage(e)
                  }}
                >
                  {imageData ? (
                    <img src={imageData.full_link} alt="" />
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon">
                        <img src={projectInfo?.logo ? projectInfo?.logo : '/images/projectManage/open-box.svg'} alt="" />
                        {/* <img src={imageData ? imageData.full_link : '/images/trackerEdit/avatar.png'} alt="" /> */}
                      </p>
                      <p className="ant-upload-text">-</p>
                      <p className="ant-upload-text">Click to update your new project image</p>
                    </>
                  )}
                </Dragger>
              </div>
            </div>
            <div className="manage-top_name">
              <Row>
                <Col xs={24} sm={24} md={15}>
                  <Form.Item name="title">
                    <Input defaultValue={projectInfo?.title} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={9}>
                  <Form.Item>
                    <div className="manage-button">
                      <Button className="manage-btn back" onClick={() => setOpenEdit(false)}>
                        Back
                      </Button>
                      <Button className="manage-btn save" type="primary" onClick={handleUpdate}>
                        Save
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <div className="edit-wrapper">
          <div className="edit-package-name">
            <p>Name:</p>
            <p>{updateName || projectInfo?.title}</p>
          </div>
          <div className="edit-package-logo">
            {imageData?.full_link && <p>Logo:</p>}
            {projectInfo?.logo && <p>Logo:</p>}
            {/* {imageData?.full_link ? <img src={imageData?.full_link} alt="" /> : <img src={projectInfo?.logo || ''} alt="" />} */}
            {/* {projectInfo?.logo ? <img src={projectInfo?.logo} alt="" /> : <img src="/images/trackerEdit/avatar.png" alt="" />} */}
            {imageData?.full_link && <img src={imageData?.full_link} alt="" />}
            {projectInfo?.logo && <img src={projectInfo?.logo} alt="" />}
          </div>
          <div className="edit-package-btn">
            <ButtonSecondary onClick={() => setOpenEdit(true)}>Edit</ButtonSecondary>
          </div>
        </div>
      )}
    </>
  )
}

export default ManageTop
