import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import HeaderMobile from 'components/Menu/HeaderMobile'
import { linksPublic } from 'components/Menu/config'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'

import './style.scss'
import ProfileAccount from 'components/ProfileAccount'
import { Select } from 'antd'
import { OPTIONS_LANG } from 'language/config'
import { useLanguage } from 'state/language/hooks'
import HeaderDesktop from 'components/Menu/HeaderDesktop'

const PublicLayout = ({ isLogin, children }) => {
  const optionSelect = OPTIONS_LANG.map((item) => item)
  const { language, setLanguage } = useLanguage()
  const handleChange = (value) => {
    setLanguage(value)
  }
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => {
    document.querySelector('body').style.overflow = showMenu ? 'unset' : 'hidden'
    setShowMenu((prev) => !prev)
  }

  return (
    <div className="public-layout-container">
      {/* <header className="desktop-header">
        <div className="header-left">
          <Link to="/">
            <div className="header-logo">
              <img src="/images/logo-text.png" title="Diamond wallets" alt="Diamond wallets" />
            </div>
          </Link>
        </div>
        <div className="header-right">
          {isLogin ? (
            <ProfileAccount />
          ) : (
            <>
              {location.pathname === '/login' || location.pathname === '/forgot-password' ? (
                <Link to="/login" className="actions">
                  <ButtonPrimary
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    Sign in
                  </ButtonPrimary>
                </Link>
              ) : (
                <Link to="/login" className="actions">
                  <ButtonSecondary
                    className="btn-unActions"
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    Sign in
                  </ButtonSecondary>
                </Link>
              )}
              {location.pathname === '/register' ? (
                <Link to="/register" className="actions" style={{ marginLeft: '15px' }}>
                  <ButtonPrimary
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    Sign up
                  </ButtonPrimary>
                </Link>
              ) : (
                <Link to="/register" className="actions" style={{ marginLeft: '15px' }}>
                  <ButtonSecondary
                    className="btn-unActions"
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    Sign up
                  </ButtonSecondary>
                </Link>
              )}
            </>
          )}
        </div>
      </header> */}
      <HeaderDesktop links={linksPublic} isLogin={isLogin} location={location} toggleMenu={toggleMenu} />

      <HeaderMobile links={linksPublic} visible={showMenu} isLogin={isLogin} location={location} toggleMenu={toggleMenu} />
      {children}
    </div>
  )
}

export default PublicLayout
