import * as types from './constants'

export const postSignup = (payload, cbs, cbe) => ({
  type: types.SIGN_UP,
  payload,
  cbs,
  cbe,
})

export const login = (payload, cbs, cbe) => ({
  type: types.LOGIN,
  payload,
  cbs,
  cbe,
})

export const logout = (payload, cbs, cbe) => ({
  type: types.LOGOUT,
  payload,
  cbs,
  cbe,
})

export const verifyEmail = (payload, cbs, cbe) => ({
  type: types.VERIFY_EMAIL,
  payload,
  cbs,
  cbe,
})

export const verifyAuthenCode = (payload, cbs, cbe) => ({
  type: types.VERIFY_AUTHEN_CODE,
  payload,
  cbs,
  cbe,
})

export const verifyAuthenActiveAccountCode = (payload, cbs, cbe) => ({
  type: types.VERIFY_AUTHEN_ACTIVE_ACCOUNT_CODE,
  payload,
  cbs,
  cbe,
})

export const resendAuthenActiveAccountCode = (payload, cbs) => ({
  type: types.RESEND_AUTHEN_ACTIVE_ACCOUNT_CODE,
  payload,
  cbs,
})

export const resendAuthenLoginAccountCode = (payload, cbs) => ({
  type: types.RESEND_AUTHEN_LOGIN_ACCOUNT_CODE,
  payload,
  cbs,
})
export const resendActiveCode = (payload, cbs) => ({
  type: types.RESEND_ACTIVE_CODE,
  payload,
  cbs,
})

export const requestForgotPassword = (payload, cbs, cbe) => ({
  type: types.REQUEST_FORGOT_PASSWORD,
  payload,
  cbs,
  cbe,
})

export const forgotPassword = (payload, cbs, cbe) => ({
  type: types.FORGOT_PASSWORD,
  payload,
  cbs,
  cbe,
})

export const create2FA = (cbs, cbe) => ({
  type: types.CREATE_2FA,
  cbs,
  cbe,
})
export const verify2FA = (payload, cbs, cbe) => ({
  type: types.VERIFY_2FA,
  payload,
  cbs,
  cbe,
})
export const update2FA = (payload, cbs, cbe) => ({
  type: types.UPDATE_2FA,
  payload,
  cbs,
  cbe,
})

export const getHistoryLogin = (payload) => ({
  type: types.GET_HISTORY_LOGIN,
  payload,
})
export const getHistoryLoginSuccess = (payload) => ({
  type: types.GET_HISTORY_LOGIN_SUCCESS,
  payload,
})
