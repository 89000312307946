import './styles.scss'
import React from 'react'

const WalletLogin = () => (
  <div className="box-wallet">
    <img src="/images/login/PNG-BANNER.png" alt="" />
  </div>
)

export default React.memo(WalletLogin)
