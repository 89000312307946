import produce from 'immer'
import * as types from './constants'

const initialState = {
  addressList: {},
  address: {},
}

export default function Address(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREATE_ADDRESS_SUCCESS:
        draft.address = payload
        break
      case types.GET_ADDRESS_LIST_SUCCESS:
        draft.addressList = payload
        break
      case types.DELETE_ADDRESS:
        draft.addressList = payload
        break
      default:
        return { ...state }
    }
  })
}
