import DefaultLayout from 'layouts/DefaultLayout'
import { useEffect } from 'react'
import Banner from './component/banner'
import Star from './component/Star'
import OurParter from './component/OurParter'
import EVMTracker from './component/EVMTracker'
import RealTime from './component/RealTime'
import Example from './component/Example'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getPackgeList } from 'state/package/actions'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Home = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    document.querySelector('body').style.overflow = 'unset'
  }, [])
  const { rows } = useSelector((state) => state.package.packagesList)
  useEffect(() => {
    dispatch(getPackgeList())
  }, [dispatch])
  return (
    <div className="home-container fadeIn">
      <EVMTracker />
      <Banner />
      <RealTime />
      <Star rows={rows} />
      <OurParter />
      <Example />
    </div>
  )
}

Home.Layout = DefaultLayout

export default withGoogleReCaptcha(Home)
