import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.56299 7.5L6.43701 13.5L15.437 1.5" stroke="#F3F8FF" strokeWidth="3" strokeLinecap="round" />
  </Svg>
)

export default Icon
