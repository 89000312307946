export const SIGN_UP = 'SIGN_UP'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const LOGOUT = 'LOGOUT'

export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const VERIFY_AUTHEN_CODE = 'VERIFY_AUTHEN_CODE'

export const VERIFY_AUTHEN_ACTIVE_ACCOUNT_CODE = 'VERIFY_AUTHEN_ACTIVE_ACCOUNT_CODE'
export const RESEND_AUTHEN_ACTIVE_ACCOUNT_CODE = 'RESEND_AUTHEN_ACTIVE_ACCOUNT_CODE'
export const RESEND_AUTHEN_LOGIN_ACCOUNT_CODE = 'RESEND_AUTHEN_LOGIN_ACCOUNT_CODE'
export const RESEND_ACTIVE_CODE = 'RESEND_ACTIVE_CODE'

export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

export const CREATE_2FA = 'CREATE_2FA'
export const VERIFY_2FA = 'VERIFY_2FA'
export const UPDATE_2FA = 'UPDATE_2FA'

export const GET_HISTORY_LOGIN = 'GET_HISTORY_LOGIN'
export const GET_HISTORY_LOGIN_SUCCESS = 'GET_HISTORY_LOGIN_SUCCESS'
