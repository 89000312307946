import produce from 'immer'
import * as types from './constants'

const initialState = {
  listFile: {},
}

export default function HomeReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.UPLOAD_FILE_SUCCESS:
        draft.listFile = { ...draft.listFile, ...payload }
        break
      default:
        return { ...state }
    }
  })
}
