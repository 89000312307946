import produce from 'immer'
import * as types from './constants'

const initialState = {
  report: {},
}

export default function Report(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_REPORT_SUCCESS:
        draft.report = payload
        break

      default:
        return { ...state }
    }
  })
}
