import { Button, Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import './styles.scss'

const Notification = ({ projectInfo }) => {
  const history = useHistory()
  const handleEditNoti = (type) => {
    history.push(`/project/${type}/${projectInfo?._id}`, projectInfo)
  }
  return (
    <div className="notification-wrapper">
      <div className="notification-wrapper_title">
        <span>Notification</span>
      </div>
      <div className="notification-wrapper_content">
        <Row gutter={[16, 32]}>
          <Col className="gutter-row" xs={6} sm={5} md={5} lg={6} xl={6}>
            <div className="noti-title">Webhook:</div>
          </Col>
          <Col className="gutter-row" xs={12} sm={13} md={13} lg={12} xl={14}>
            <div className="noti-desc">{projectInfo?.webhook ? projectInfo?.webhook : 'no data yet'}</div>
          </Col>
          <Col className="gutter-row" xs={6} sm={6} md={6} lg={6} xl={4}>
            <div>
              <Button className="noti-btn" onClick={() => handleEditNoti('webhook')}>
                {/* <Link to="/project/edit/123">
                </Link> */}
                <span>
                  Edit <img src="/images/projectManage/edit.svg" alt="" />
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 32]}>
          <Col className="gutter-row" xs={6} sm={5} md={5} lg={6} xl={6}>
            <div className="noti-title">Telegram:</div>
          </Col>
          <Col className="gutter-row" xs={12} sm={13} md={13} lg={12} xl={14}>
            <div className="noti-desc">{projectInfo?.telegram_group_id ? projectInfo?.telegram_group_id : 'no data yet'}</div>
          </Col>
          <Col className="gutter-row" xs={6} sm={6} md={6} lg={6} xl={4}>
            <div>
              <Button className="noti-btn" onClick={() => handleEditNoti('telegram')}>
                <span>
                  Edit <img src="/images/projectManage/edit.svg" alt="" />
                </span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Notification
