import React, { useState } from 'react'
import Header from './Header'
import Footer from 'components/Footer'
import { linksPublic } from './config'

const Menu = ({ isLogin, children }) => {
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    document.querySelector('body').style.overflow = showMenu ? 'unset' : 'hidden'
    setShowMenu((prev) => !prev)
  }

  return (
    <>
      <Header links={linksPublic} showMenu={showMenu} isLogin={isLogin} toggleMenu={toggleMenu} />
      {children}
      <Footer links={linksPublic} />
    </>
  )
}

export default Menu
