import * as types from './constants'

export const getBlockchainList = (payload) => ({
  type: types.GET_BLOCKCHAIN_LIST,
  payload,
})
export const getBlockchainListSuccess = (payload) => ({
  type: types.GET_BLOCKCHAIN_LIST_SUCCESS,
  payload,
})
