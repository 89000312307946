import { all, takeLatest, call, put, takeEvery } from 'redux-saga/effects'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'
import qs from 'query-string'
import * as types from './constants'
import * as actions from './actions'

function getEnvoiceListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmInvoice/list?${qStr}`, {
      method: 'GET',
    })
    .then(([res, status]) => ({
      data: res,
      status,
    }))
}
function* getInvoiceList({ payload }) {
  try {
    const { data } = yield call(getEnvoiceListFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getInvoiceListSuccess(data.data))
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* rootSaga() {
  yield all([takeLatest(types.GET_INVOICE_LIST, getInvoiceList)])
}
