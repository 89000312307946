export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const GET_PROJECT_DETAIL = 'GET_PROJECT_DETAIL'
export const GET_PROJECT_DETAIL_SUCCESS = 'GET_PROJECT_DETAIL_SUCCESS'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const RENEW_PROJECT = 'RENEW_PROJECT'
export const RENEW_PROJECT_SUCCESS = 'RENEW_PROJECT_SUCCESS'
export const VERIFY_DELETE = 'VERIFY_DELETE'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const VERIFY_SECRETKEY = 'VERIFY_SECRETKEY'
export const GENERATE_KEY = 'GENERATE_KEY'
