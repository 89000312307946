import * as types from './constants'

export const getProfile = (payload, cbs, cbe) => ({
  type: types.GET_PROFILE,
  payload,
  cbs,
  cbe,
})
export const getProfileSuccess = (payload) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload,
})

export const updateProfile = (payload, cbs, cbe) => ({
  type: types.UPDATE_PROFILE,
  payload,
  cbs,
  cbe,
})

export const updateKyc = (payload, cbs, cbe) => ({
  type: types.UPDATE_KYC,
  payload,
  cbs,
  cbe,
})
