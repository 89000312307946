import { Checkbox, Input, List, Table } from 'antd'
import ModalRemoveAddress from 'components/ModalRemoveAddress'
import { formatCode } from 'helpers'
import useIsMobile from 'hooks/useIsMobile'
import { useEffect, useState } from 'react'
import './styles.scss'
import { useSelector, useDispatch } from 'react-redux'
import { getAddressList } from 'state/address/actions'
// import { isMobile } from 'helpers'
import { useHistory } from 'react-router-dom'

const onChange = (checkedValues) => {
  // console.log('checked = ', checkedValues)
}
const options = [
  {
    label: '- From',
    value: 'FROM',
  },
  {
    label: '- To',
    value: 'TO',
  },
]

const FindAddress = ({ projectInfo }) => {
  const [open, setOpen] = useState({
    isOpen: false,
    data: [],
  })
  const history = useHistory()
  // const { addressList } = useSelector((state) => state.address)
  const [currencySorting, setCurrencySorting] = useState()
  const [searchCode, setSearchCode] = useState()
  const isMobile = useIsMobile()
  const addressList = useSelector((state) => state.address?.addressList)
  const dispatch = useDispatch()
  const projectId = history.location.state?.project_id
  const chainCode = history.location.state?.chain_code
  useEffect(() => {
    dispatch(getAddressList({ project_id: projectId, chain_code: chainCode, address: searchCode, page: 1, pageSize: 10 }))
    // setCurrencySorting(addressList[projectInfo?._id]?.rows)
  }, [dispatch, projectId, searchCode])
  useEffect(() => {
    // if (!currencySorting) {
    setCurrencySorting(addressList[projectInfo?._id]?.rows)
    // }
  }, [addressList, currencySorting, projectInfo?._id])
  // console.log('addressList', addressList)
  const handleValueCheck = (type) => {
    if (type === 'BOTH') {
      return ['FROM', 'TO']
    }
    return [type]
  }
  const columns = [
    {
      title: <div className="find-tbl-header">No</div>,

      render: (_, record, index) => <p className="find-tbl-number">{index + 1}</p>,
    },
    {
      title: <div className="find-tbl-header">Addresses</div>,
      dataIndex: 'address',
      render: (text) => <p className="find-tbl-address">{formatCode(text, 5, 5)}</p>,
    },
    {
      title: <div className="find-tbl-header">Transaction Watcher</div>,
      dataIndex: 'transaction_watcher',
      render: (data) => (
        <div className="find-tbl-check">
          <Checkbox.Group options={options} value={handleValueCheck(data)} onChange={onChange} />
        </div>
      ),
    },

    {
      title: <div className="find-tbl-header">Action</div>,
      key: 'action',
      render: (_, record) => (
        <a className="find-tbl-remove" role="presentation" onClick={() => setOpen({ isOpen: true, data: record })}>
          Remove
        </a>
      ),
    },
  ]
  const handleSearchCode = (value) => {
    setSearchCode(value)
  }
  let searchDataResult = currencySorting || []
  if (searchCode) {
    searchDataResult = currencySorting?.filter((entry) =>
      Object.values(entry).some((val) => typeof val === 'string' && val.includes(searchCode)),
    )
  }

  return (
    <div className="find-wrapper">
      <div className="find-top">
        <div className="find-title">
          <span>Find address:</span>
        </div>
        <div className="find-input">
          <Input
            placeholder="input address to find"
            value={searchCode}
            onChange={(value) => handleSearchCode(value.target.value)}
          />
        </div>
      </div>
      {isMobile ? (
        <div className="find-list">
          <List
            pagination={{
              pageSize: 10,
              total: addressList.total,
              onChange: (page, pageSize) => {
                dispatch(getAddressList({ project_id: projectId, chain_code: chainCode, address: searchCode, page, pageSize }))
              },
              current: addressList.page,
              hideOnSinglePage: true,
            }}
            dataSource={addressList?.rows}
            renderItem={(item, i) => (
              <List.Item>
                <div className="list-addresses">
                  <div className="list-addresses-row">
                    <div className="list-addresses-title">
                      <p>No</p>
                    </div>
                    <div className="list-addresses-value">
                      <p style={{ fontWeight: '700' }}>{i + 1}</p>
                    </div>
                  </div>
                  <div className="list-addresses-row">
                    <div className="list-addresses-title">
                      <p>Addresses</p>
                    </div>
                    <div className="list-addresses-value">
                      <p>{formatCode(item.address, 5, 5)}</p>
                    </div>
                  </div>
                  <div className="list-addresses-row">
                    <div className="list-addresses-title">
                      <p>Transaction Watcher</p>
                    </div>
                    <div className="list-addresses-value">
                      <div className="find-tbl-check">
                        <Checkbox.Group options={options} value={handleValueCheck(item.transaction_watcher)} />
                      </div>
                    </div>
                  </div>
                  <div className="list-addresses-row">
                    <div className="list-addresses-title">
                      <p>Action</p>
                    </div>
                    <div className="list-addresses-value">
                      <p className="btn-remove" role="presentation" onClick={() => setOpen({ isOpen: true, data: item })}>
                        Remove
                      </p>
                    </div>
                  </div>
                </div>
                {/* <ModalRemoveAddress data={item} visible={open.isOpen} setOpen={setOpen} /> */}
              </List.Item>
            )}
          />
          <ModalRemoveAddress data={open.data} visible={open.isOpen} setOpen={setOpen} />
        </div>
      ) : (
        <div className="find-tbl">
          <Table
            pagination={{
              pageSize: 10,
              total: addressList.total,
              onChange: (page, pageSize) => {
                dispatch(getAddressList({ project_id: projectId, chain_code: chainCode, address: searchCode, page, pageSize }))
              },
              current: addressList.page,
              hideOnSinglePage: true,
            }}
            columns={columns}
            dataSource={addressList.rows}
            rowKey={(record) => record._id}
          />

          <ModalRemoveAddress data={open.data} visible={open.isOpen} setOpen={setOpen} />
        </div>
      )}
    </div>
  )
}

export default FindAddress
