import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21 15.8385L21 18C18.0513 16.5839 14.2051 14.9068 9.46154 12.9689L-3.94217e-07 9.01863L-3.82814e-07 8.75776L21 0L21 3.80124L5.11539 10.3975L9.69231 12.3354C14.6154 14.3975 18.3846 15.5652 21 15.8385Z" />
  </Svg>
)

export default Icon
