import React, { useEffect, useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Table, List } from 'antd'
import { getBscScanLink } from 'utils'
import { DECIMALS } from 'constants/index'
import { formatDate, formatCode, roundNumber } from 'helpers'
import ItemRecentTransaction from '../itemRecentTransaction'
import ModalViewDetails from '../ModalViewDetails'
import Nodata from 'components/Nodata'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from 'widgets/Loading/Loading'
import './styles.scss'
import useIsMobile from 'hooks/useIsMobile'
// wallets history table
const responsiveList = { gutter: 16, xs: 1, sm: 1, md: 1 }

const RecentTransaction = ({ rows, setTxParams, txParams, transactionsList, setModalVerifyCode, ...props }) => {
  const [viewDetails, setViewDetails] = useState({ toggle: false, dataModal: null })
  const [loading] = useState(false)
  const isMobile = useIsMobile()
  const [rowData, setRowData] = useState(rows)
  const columns = useMemo(
    () => [
      {
        title: <div className="recent-transaction-table-title">Transaction ID</div>,
        dataIndex: '_id',
        width: 150,
        render: (text) => <div className="recent-transaction-table-action">{formatCode(text, 5, 5)}</div>,
      },
      {
        title: <div className="recent-transaction-table-title">Status</div>,
        dataIndex: 'status',
        width: 120,
        render: (text, record) => (
          <div className="recent-transaction-table-status" data-status={record.status}>
            {text}
          </div>
        ),
      },
      {
        title: <div className="recent-transaction-table-title">Action</div>,
        dataIndex: 'action',
        width: 200,
        render: (text) => <div className="recent-transaction-table-action">{text}</div>,
      },
      {
        title: <div className="recent-transaction-table-title">Amount</div>,
        dataIndex: 'amount',
        width: 100,
        render: (text, record) => (
          <div className="recent-transaction-table-amount">
            $<CurrencyFormat value={roundNumber(text / 10 ** DECIMALS, 6)} displayType="text" thousandSeparator />
            {/* <div className="coin-data">
              <div>{record.icon && <img src={record.icon} alt="" />}</div>
              <div className="coin-data-label">
                <div className="data-label-text">{record.currency}</div>
              </div>
            </div> */}
          </div>
        ),
      },
      {
        title: <div className="recent-transaction-table-title">Method</div>,
        dataIndex: 'payment_method',
        width: 100,
        render: (text) => <div className="recent-transaction-table-action">{text || '-'}</div>,
      },

      {
        title: <div className="recent-transaction-table-title">Date</div>,
        dataIndex: 'createdAt',
        width: 157,
        render: (text) => <div className="recent-transaction-table-time">{formatDate(text * 1000)}</div>,
      },
      {
        // title: 'Actions',
        key: 'actions',
        width: 100,
        render: (_, record) => {
          if (record.status !== 'CREATED') {
            return (
              <a
                className="recent-transaction-table-view"
                role="presentation"
                onClick={() => setViewDetails({ toggle: true, dataModal: record })}
              >
                View
              </a>
            )
          }
          return (
            <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(record)}>
              Verify
            </a>
          )
        },
      },
    ],
    [setModalVerifyCode],
  )

  const loadMoreData = () => {
    if (loading) {
      return
    }

    setTxParams((prev) => ({
      ...prev,
      pageSize: prev.pageSize + 15,
    }))
  }
  useEffect(() => {
    if (isMobile) {
      loadMoreData()
    }

    // loadMoreData()
  }, [])
  const loadMoreItems = () => {
    // setTxParams({ ...txParams, pageSize: txParams.pageSize + 15 })
    setRowData({ ...rows, length: rows?.length + 10 })
    // setTimeout(() => {
    //   setTxParams({ ...txParams, pageSize: txParams.pageSize + 15 })
    // }, 500)
  }

  // useEffect(() => {
  //   if (isMobile) {
  //     loadMoreItems()
  //   }

  //   // loadMoreData()
  // }, [])

  return (
    <div className="wallet-box-recent-transaction" id="scrollableDiv">
      {isMobile ? (
        <div style={{ minHeight: '100px' }}>
          {rows?.length > 0 ? (
            <InfiniteScroll
              className="infinite-scroll"
              style={{ overflow: 'unset' }}
              dataLength={rows?.length || 0}
              next={loadMoreData}
              hasMore={rows?.length < transactionsList?.total}
              loader={
                <List
                  dataSource={[{}]}
                  grid={responsiveList}
                  renderItem={(_, index) => (
                    <List.Item key={`list-${index}`}>
                      <div
                        className="loading-list-stake"
                        style={{
                          color: '#003e9d',
                          textAlign: 'center',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <span role="presentation" onClick={loadMoreData} style={{ marginRight: '4px', cursor: 'pointer' }}>
                          Load more...
                        </span>
                        {/* <Loading /> */}
                      </div>
                    </List.Item>
                  )}
                />
              }
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={rows || []}
                grid={responsiveList}
                renderItem={(item, index) => (
                  <List.Item key={`list-${index}`}>
                    <ItemRecentTransaction
                      data={item}
                      key={item.id}
                      setViewDetails={setViewDetails}
                      setModalVerifyCode={setModalVerifyCode}
                    />
                  </List.Item>
                )}
                locale={{
                  emptyText: transactionsList?.total === 0 ? <div>Empty</div> : <></>,
                }}
              />
            </InfiniteScroll>
          ) : (
            <Nodata />
          )}
        </div>
      ) : (
        <Table
          className="table-wallet-history"
          columns={columns}
          dataSource={rows || []}
          rowKey={(record) => record._id}
          scroll={{ x: 700 }}
          pagination={false}
          onChange={(page, pageSize) => {
            setTxParams((prev) => ({
              ...prev,
              page,
              pageSize,
            }))
          }}
        />
      )}
      <ModalViewDetails visible={viewDetails.toggle} dataModal={viewDetails.dataModal} close={setViewDetails} />
    </div>
  )
}

export default RecentTransaction
