import React from 'react'
// import Skeleton from "react-loading-skeleton";
import './styles.scss'

/**
 * A commont function for renderring item compoment
 * @since Sep 30 2020
 */

const Skeleton = ({ width, height, maxWidth, maxHeight, style, children, ...props }) => (
  <div className="ssc-square" style={{ height, width, maxWidth, maxHeight, ...style }} {...props}>
    {children}
  </div>
)

Skeleton.defaultProps = {
  height: 200,
}

export default Skeleton
