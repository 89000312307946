import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'
import AuthCard from '../AuthCard'
import { verifyAuthenActiveAccountCode, resendAuthenActiveAccountCode, resendActiveCode } from 'state/auth/actions'
import InputCode from 'components/InputCode'

const defaultTimeCountDown = 50

const VerifyActiveAccountCode = ({ email, initialField, token, onFinish, children, ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(defaultTimeCountDown)

  const onSubmit = (value) => {
    const code = Object.values(value || codeValue).filter((o) => o)

    if (code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters from email of you.`)
    } else {
      setErrorMess('')
      setLoading(true)
      dispatch(
        verifyAuthenActiveAccountCode(
          {
            email: `${email}`,
            code: code.join(''),
          },
          () => {
            history.push('/login')
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    }
  }

  const onChangeCode = (value) => {
    const code = Object.values(value).filter((o) => o)
    if (code.length >= initialField) {
      onSubmit(value)
    }
    setCodeValue(value)
  }

  const resendCode = () => {
    // console.log(history)
    if (history.location.pathname.includes('register')) {
      dispatch(
        resendActiveCode({ email: `${email}` }, () => {
          setTimeCountDown(defaultTimeCountDown)
        }),
      )
    } else {
      dispatch(
        resendAuthenActiveAccountCode(
          {
            email: `${email}`,
          },
          () => {
            setTimeCountDown(defaultTimeCountDown)
          },
        ),
      )
    }
    // dispatch(
    //   resendAuthenActiveAccountCode(
    //     {
    //       email: `${email}`,
    //     },
    //     () => {
    //       setTimeCountDown(defaultTimeCountDown)
    //     },
    //   ),
    // )
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  return (
    <AuthCard className="verify-code fadeIn login" {...props} style={{ height: 'fit-content' }}>
      <h2 className="verify-title">Verification code</h2>
      <div className="verify-input">
        <InputCode value={codeValue} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
      </div>
      <div className="verify-resend">
        <div style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>Resend in {timeCountDown}s</div>
        {timeCountDown <= 0 && (
          <a onClick={resendCode} role="presentation">
            Resend
          </a>
        )}
      </div>
      {/* <div className="verify-error">{errorMess}</div> */}
      <div className="verify-action">
        <ButtonPrimary loading={loading} onClick={onSubmit}>
          Done
        </ButtonPrimary>
        {/* <ButtonSecondary onClick={onBack}>Back</ButtonSecondary> */}
        {/* <p>
          Or login via <a href="/verify-code">Google Authenticator</a>
        </p> */}
      </div>
    </AuthCard>
  )
}

VerifyActiveAccountCode.defaultProps = {
  initialField: 6,
  onFinish: () => null,
  // onBack: () => null,
}

export default React.memo(VerifyActiveAccountCode)
