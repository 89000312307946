import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './styles.scss'
import TitleSection from '../titleSection'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { Carousel, Col, Row, Table } from 'antd'
import ButtonSecondary from 'widgets/ButtonSecondary'
import useIsMobile from 'hooks/useIsMobile'
import { CHPlayIcon } from 'widgets/Svg'
import { render } from '@testing-library/react'
import ProjectItem from './ProjectItem'
import { DECIMALS } from 'components/constants'
import { roundNumber } from 'helpers'

const columns = [
  {
    title: <p className="start-project-title">Package</p>,
    dataIndex: 'title',
    key: 'title',
    width: 170,
    render: (text) => <div className="start-project-desc">{text}</div>,
  },
  {
    title: <p className="start-project-title centered">Pricing</p>,
    dataIndex: 'price_month',
    width: 100,
    key: 'pricing',
    render: (text) => <div className="start-project-desc centered">{roundNumber(text / 10 ** DECIMALS, 6)}</div>,
  },
  {
    title: <p className="start-project-title centered">Limit Addresses</p>,
    width: 170,
    dataIndex: 'limit_address',
    key: 'limit_address',
    render: (text) => <div className="start-project-desc centered">{text}</div>,
  },
  {
    title: <p className="start-project-title centered">Transaction Watcher</p>,
    dataIndex: 'transaction_watcher',
    width: 220,
    key: 'transaction_watcher',
    render: (text) => <div className="start-project-desc centered">{text}</div>,
  },
  {
    title: <p className="start-project-title centered">ERC20 Transfer</p>,
    dataIndex: 'transfer_event',
    width: 250,
    key: 'transfer_event',
    render: (text) => (
      <div className="start-project-desc centered">
        {text ? (
          <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />
        ) : (
          <img style={{ width: '22px' }} src="/images/Home/banner/false.png" alt="" />
        )}
      </div>
    ),
  },
  {
    title: <p className="start-project-title centered">Webhook</p>,
    dataIndex: 'webhook',
    width: 120,
    key: 'webhook',
    render: (text) => (
      <div className="start-project-desc centered">
        {text ? (
          <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />
        ) : (
          <img style={{ width: '22px' }} src="/images/Home/banner/false.png" alt="" />
        )}
      </div>
    ),
  },
  {
    title: <p className="start-project-title centered">Telegram Bot</p>,
    dataIndex: 'telegram_bot',
    width: 130,
    key: 'telegram_bot',
    render: (text) => (
      <div className="start-project-desc centered">
        {text ? (
          <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />
        ) : (
          <img style={{ width: '22px' }} src="/images/Home/banner/false.png" alt="" />
        )}
      </div>
    ),
  },
]

const data = [
  {
    key: '1',
    package: 'Free Trial',
    pricing: '$0',
    limit: 10,
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/false.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
  {
    key: '2',
    package: 'Starter',
    pricing: '$49',
    limit: '500',
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
  {
    key: '3',
    package: 'Lite',
    pricing: '$99',
    limit: '1000',
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
  {
    key: '4',
    package: 'Base',
    pricing: '$399',
    limit: '5000',
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
  {
    key: '5',
    package: 'Base',
    pricing: '$399',
    limit: '5000',
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
  {
    key: '6',
    package: 'Base',
    pricing: '$399',
    limit: '5000',
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
  {
    key: '7',
    package: 'Base',
    pricing: '$399',
    limit: '5000',
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
  {
    key: '8',
    package: 'Base',
    pricing: '$399',
    limit: '5000',
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
  {
    key: '9',
    package: 'Base',
    pricing: '$399',
    limit: '5000',
    transaction: 'From, To, Both',
    transfer: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    webhook: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
    bot: <img style={{ width: '22px' }} src="/images/Home/banner/true.png" alt="" />,
  },
]
const Star = ({ rows }) => {
  // const { userInfo } = useSelector((state) => state.user)
  // const isMobile = useIsMobile()
  console.log()

  return (
    <div className="warp-star">
      <TitleSection title="START A PROJECT FROM 0$" />
      <Table
        className="table"
        columns={columns}
        dataSource={rows}
        pagination={false}
        rowKey={(record) => record._id}
        scroll={{
          x: 0,
        }}
      />
      <div className="carousel-wrapper">
        <Carousel
          arrows
          // prevArrow={<img style={{ width: '22px' }} src="/images/Home/banner/nextArrow.png" alt="" />}
          // nextArrow={<img style={{ width: '22px' }} src="/images/Home/banner/nextArrow.png" alt="" />}
        >
          {rows?.map((item, index) => (
            <ProjectItem item={item} key={index} />
          ))}
        </Carousel>
      </div>
      <div className="warp-project_btn">
        <Link to="/register" target="_blank" rel="noreferrer">
          <ButtonPrimary>Get Started</ButtonPrimary>
        </Link>
        <Link to="https://evmtracker.com/contact/" target="_blank" rel="noreferrer">
          <ButtonPrimary>Contact Us For Custom Limit</ButtonPrimary>
        </Link>
      </div>
    </div>
  )
}

export default Star
