export const GET_ORDER_LIST = 'GET_ORDER_LIST'
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS'

export const GET_PACKAGE_LIST = 'GET_PACKAGE_LIST'
export const GET_PACKAGE_LIST_SUCCESS = 'GET_PACKAGE_LIST_SUCCESS'

export const ORDER_STAKE = 'ORDER_STAKE'
export const ORDER_STAKE_SUCCESS = 'ORDER_STAKE_SUCCESS'

export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL'
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS'

export const GET_ORDER_STAKE_REPORT = 'GET_ORDER_STAKE_REPORT'
export const GET_ORDER_STAKE_REPORT_SUCCESS = 'GET_ORDER_STAKE_REPORT_SUCCESS'
