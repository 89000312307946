import produce from 'immer'
import * as types from './constants'

const initialState = {
  project: {},
}

export default function Project(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREATE_PROJECT_SUCCESS:
        draft.project = payload
        break
      case types.GET_PROJECT_DETAIL_SUCCESS:
        draft.project = payload
        break
      case types.UPDATE_PROJECT_SUCCESS:
        draft.project = payload
        break
      case types.DELETE_PROJECT:
        draft.project = payload
        break
      case types.GENERATE_KEY:
        draft.project = payload
        break
      case types.RENEW_PROJECT_SUCCESS:
        draft.project = payload
        break

      default:
        return { ...state }
    }
  })
}
