import produce from 'immer'
import * as types from './constants'

const initialState = {
  listCountry: undefined,
}

export default function AppReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_LIST_COUNTRY_SUCCESS:
        draft.listCountry = payload
        break

      default:
        return { ...state }
    }
  })
}
