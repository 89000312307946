import { Button } from 'antd'
import ModalDeleteProject from 'components/ModalDeleteProject'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteProject } from 'state/project/actions'
import './styles.scss'
import { executeRecaptchaV2 } from 'helpers'

const Advance = ({ projectId, userInfo }) => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const handleDeleteProject = () => {
    dispatch(
      deleteProject({ project_id: projectId }, () => {
        setOpenModal(true)
      }),
    )
  }
  return (
    <div className="advance-wrapper">
      <div className="advance-title">
        <img src="/images/projectManage/forward.svg" alt="" />
        <span>Advance</span>
      </div>
      <div className="advance-delete">
        <div className="advance-delete_title">
          <span>Delete project</span>
          <img src="/images/projectManage/warning.svg" alt="" />
        </div>
        <div className="advance-delete_desc">
          <span>
            When you delete the project all associated address, webhook, and Telegram group ID will be deleted and will not be
            recoverable after 24 hours.
          </span>
        </div>
        <div className="advance-delete_btn">
          <Button className="advance-button" onClick={handleDeleteProject}>
            Delete
          </Button>
        </div>
      </div>
      <ModalDeleteProject
        type="deleteProject"
        projectId={projectId}
        visible={openModal}
        onCancel={setOpenModal}
        isGoogleAuth={userInfo?.status_2fa}
        deleteProject={deleteProject}
      />
    </div>
  )
}

export default Advance
