import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'
import InputCopyLink from 'components/FielCopy/InputCopy'
import './styles.scss'
import ModalGenerateKey from 'components/ModalGenerateKey'
import ModalEnDis2FA from 'components/ModalEnDis2FA'
import { useDispatch } from 'react-redux'
import { generateKey } from 'state/project/actions'
import { executeRecaptchaV2 } from 'helpers'

const SecretKey = ({ projectId, userInfo }) => {
  const [open, setOpen] = useState(false)
  const [secretKey, setSecretKey] = useState('')
  const dispatch = useDispatch()
  const handleGenerateKey = () => {
    dispatch(
      generateKey({ project_id: projectId }, () => {
        setOpen(true)
      }),
    )
  }
  return (
    <div className="secret-key-wrapper">
      <div className="secret-key-wrapper_title">
        <span>Secret Key</span>
      </div>
      <div className="secret-key-wrapper_btn">
        <Button className="secret-button" onClick={handleGenerateKey}>
          Generate New Secret Key
        </Button>
      </div>
      <div className="secret-key-wrapper_key">
        <div className="secret-key-title">
          <span>Key: </span>
        </div>
        <div className="secret-key-input">
          <Row style={{ marginBottom: 0 }}>
            <Col className="networks-ref">
              <InputCopyLink value={secretKey || 'Xxxxxxx'} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="secret-key-wrapper_desc">
        <div className="secret-desc">
          <img src="/images/projectManage/shield.svg" alt="" />
          <div className="secret-desc_txt">
            <span>This key is provided only one time.</span>
            <span>If you lost this key, you have to generate a new secret key.</span>
          </div>
        </div>
      </div>
      <ModalGenerateKey
        setSecretKey={setSecretKey}
        type="generateKey"
        projectId={projectId}
        visible={open}
        setOpen={setOpen}
        generateKey={generateKey}
        isGoogleAuth={userInfo?.status_2fa}
      />
    </div>
  )
}

export default SecretKey
