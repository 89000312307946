import { all, takeLatest, call, put } from 'redux-saga/effects'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'
import qs from 'query-string'
import * as types from './constants'
import * as actions from './actions'

function createProjectInitialState(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([res, status]) => ({ data: res, status }))
}

function* createProject({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createProjectInitialState, payload)
    if (data.code === 200) {
      if (cbs) cbs(data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('create package', error)
    if (cbe) cbe(error)
  }
}
function getProjectDetailApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([res, status]) => ({
      data: res,
      status,
    }))
}
function* getProjectDetail({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getProjectDetailApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) {
      cbe(data)
    }
  } catch (error) {
    console.error('getProjectDetail', error)
  }
}
function updateProjectFromApi(payload) {
  fetchHelper.removeDefaultHeader('Content-Type')
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(([res, status]) => ({
      data: res,
      status,
    }))
}
function* updateProject({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateProjectFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) {
      cbe(data)
    }
  } catch (error) {
    console.error('getProjectDetail', error)
  }
}
function renewProjectFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/renew`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([res, status]) => ({
      data: res,
      status,
    }))
}
function* renewProject({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(renewProjectFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) {
      cbe(data)
    }
  } catch (error) {
    console.error('getProjectDetail', error)
  }
}

function verifyDeleteFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/verifyDelete`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* verifyDelete({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(verifyDeleteFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('verifyDelete', error)
    if (cbe) cbe(error)
  }
}
function deleteProjectFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/delete`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteProject({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteProjectFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('verifyEmail', error)
    if (cbe) cbe(error)
  }
}

function generateKeyFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/generateApiKey`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}

function* generateKey({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(generateKeyFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('verifyEmail', error)
    if (cbe) cbe(error)
  }
}
function verifySecretKeyFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/evmProject/verifyGenerateApiKey`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* verifySecretKey({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(verifySecretKeyFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data.data)
  } catch (error) {
    console.error('verifyDelete', error)
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.CREATE_PROJECT, createProject),
    takeLatest(types.GET_PROJECT_DETAIL, getProjectDetail),
    takeLatest(types.UPDATE_PROJECT, updateProject),
    takeLatest(types.VERIFY_DELETE, verifyDelete),
    takeLatest(types.DELETE_PROJECT, deleteProject),
    takeLatest(types.GENERATE_KEY, generateKey),
    takeLatest(types.VERIFY_SECRETKEY, verifySecretKey),
    takeLatest(types.RENEW_PROJECT, renewProject),
  ])
}
