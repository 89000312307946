import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.71841 18.3203L8.98129 18.1819L8.98129 18.1819L9.71841 18.3203ZM9.73775 18.2173L10.4749 18.3556L10.4749 18.3556L9.73775 18.2173ZM14.2622 18.2173L13.5251 18.3556L13.5251 18.3556L14.2622 18.2173ZM14.2816 18.3203L15.0187 18.1819L15.0187 18.1819L14.2816 18.3203ZM13.9918 20.5519L14.669 20.8742L14.669 20.8742L13.9918 20.5519ZM13.1978 20.475C13.0198 20.849 13.1787 21.2965 13.5527 21.4745C13.9267 21.6525 14.3742 21.4936 14.5522 21.1196L13.1978 20.475ZM10.0082 20.5519L9.33099 20.8742L9.33099 20.8742L10.0082 20.5519ZM9.44777 21.1196C9.62576 21.4936 10.0733 21.6525 10.4473 21.4745C10.8213 21.2965 10.9802 20.849 10.8022 20.475L9.44777 21.1196ZM11.361 16.4426L11.1605 15.7199L11.1605 15.7199L11.361 16.4426ZM12.6389 16.4426L12.8395 15.7199L12.8395 15.7199L12.6389 16.4426ZM10.4555 18.4587L10.4749 18.3556L9.00063 18.0789L8.98129 18.1819L10.4555 18.4587ZM13.5251 18.3556L13.5444 18.4587L15.0187 18.1819L14.9994 18.0789L13.5251 18.3556ZM13.3145 20.2296L13.1978 20.475L14.5522 21.1196L14.669 20.8742L13.3145 20.2296ZM9.33099 20.8742L9.44777 21.1196L10.8022 20.475L10.6854 20.2296L9.33099 20.8742ZM13.5444 18.4587C13.6571 19.0588 13.5764 19.6793 13.3145 20.2296L14.669 20.8742C15.0674 20.0369 15.1898 19.0937 15.0187 18.1819L13.5444 18.4587ZM8.98129 18.1819C8.81014 19.0937 8.93253 20.0369 9.33099 20.8742L10.6854 20.2296C10.4236 19.6793 10.3429 19.0588 10.4555 18.4587L8.98129 18.1819ZM11.5616 17.1652C11.8485 17.0856 12.1514 17.0856 12.4384 17.1652L12.8395 15.7199C12.2901 15.5674 11.7099 15.5674 11.1605 15.7199L11.5616 17.1652ZM14.9994 18.0789C14.7865 16.9449 13.9504 16.0281 12.8395 15.7199L12.4384 17.1652C12.9935 17.3193 13.4169 17.7794 13.5251 18.3556L14.9994 18.0789ZM10.4749 18.3556C10.583 17.7794 11.0065 17.3193 11.5616 17.1652L11.1605 15.7199C10.0496 16.0281 9.21348 16.9449 9.00063 18.0789L10.4749 18.3556Z"
      fill="#2E6DFF"
    />
    <path
      d="M20.04 9.71903C20.04 9.30482 19.7042 8.96903 19.29 8.96903C18.8758 8.96903 18.54 9.30482 18.54 9.71903H20.04ZM5.45999 9.71903C5.45999 9.30482 5.12421 8.96903 4.70999 8.96903C4.29578 8.96903 3.95999 9.30482 3.95999 9.71903H5.45999ZM14.5132 20.7071L14.3408 19.9772L14.5132 20.7071ZM9.48673 20.7071L9.65916 19.9772L9.48673 20.7071ZM14.8284 5.01423L14.2962 5.54267L14.8284 5.01423ZM20.4678 11.7582C20.7596 12.0521 21.2345 12.0538 21.5284 11.7619C21.8224 11.4701 21.8241 10.9952 21.5322 10.7013L20.4678 11.7582ZM9.17157 5.01423L9.70378 5.54267L9.17157 5.01423ZM2.46779 10.7013C2.17594 10.9952 2.17762 11.4701 2.47155 11.7619C2.76548 12.0538 3.24035 12.0521 3.53221 11.7582L2.46779 10.7013ZM18.54 9.71903V14.6374H20.04V9.71903H18.54ZM5.45999 14.6374V9.71903H3.95999V14.6374H5.45999ZM14.3408 19.9772C12.8011 20.3409 11.1988 20.3409 9.65916 19.9772L9.3143 21.437C11.0808 21.8543 12.9192 21.8543 14.6857 21.437L14.3408 19.9772ZM9.65916 19.9772C7.2026 19.3969 5.45999 17.1877 5.45999 14.6374H3.95999C3.95999 17.8765 6.17421 20.6952 9.3143 21.437L9.65916 19.9772ZM14.6857 21.437C17.8258 20.6952 20.04 17.8765 20.04 14.6374H18.54C18.54 17.1877 16.7974 19.3969 14.3408 19.9772L14.6857 21.437ZM14.2962 5.54267L20.4678 11.7582L21.5322 10.7013L15.3606 4.48578L14.2962 5.54267ZM8.63937 4.48578L2.46779 10.7013L3.53221 11.7582L9.70378 5.54267L8.63937 4.48578ZM15.3606 4.48578C14.7089 3.82945 14.1681 3.28246 13.6818 2.90885C13.1785 2.52211 12.6458 2.25 12 2.25V3.75C12.1827 3.75 12.3974 3.81359 12.7679 4.09826C13.1555 4.39606 13.6146 4.85618 14.2962 5.54267L15.3606 4.48578ZM9.70378 5.54267C10.3854 4.85618 10.8445 4.39606 11.2321 4.09826C11.6026 3.81359 11.8173 3.75 12 3.75V2.25C11.3542 2.25 10.8215 2.52211 10.3182 2.90885C9.83194 3.28246 9.29106 3.82945 8.63937 4.48578L9.70378 5.54267Z"
      fill="#003e9d"
    />
  </Svg>
)

export default Icon
