import React from 'react'
import './styles.scss'
import Page from 'components/App/Page'

const ComingSoon = () => (
  <Page>
    <div className="soon-container">
      <div className="content">
        <span>COMING SOON</span>
        <p>We will be celebrating the launch of our new site very soon!</p>
      </div>
    </div>
  </Page>
)

export default ComingSoon
