import ModalCustom from 'components/BaseModal'
import { executeRecaptchaV2, formatCode } from 'helpers'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { deleteAddress, getAddressList } from 'state/address/actions'
import ButtonPrimary from 'widgets/ButtonPrimary'
import './styles.scss'

const ModalRemoveAddress = ({ data, visible, setOpen }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const chainCode = history.location.state?.chain_code
  const handleDeleteAddress = () => {
    // console.log(recaptcha)
    dispatch(
      deleteAddress({ address: [data?.address], chain_code: data?.chain_code, project_id: data?.project_id }, () => {
        dispatch(getAddressList({ project_id: data?.project_id, chain_code: chainCode }))
        setOpen(false)
      }),
    )
  }
  return (
    <ModalCustom
      centered
      className="modal-remove-address"
      onCancel={() => setOpen(false)}
      visible={Boolean(visible)}
      title={
        <div
          className="modal-verify-cody-header"
          style={{ textAlign: 'left', fontSize: window.innerWidth >= 768 ? '32px' : '24px' }}
        >
          Remove Address
        </div>
      }
    >
      <h2 className="remove-address-title">Do you want to remove an address?</h2>
      <p> {formatCode(data?.address, 10, 10)}</p>
      <div className="generate-confirm">
        <ButtonPrimary onClick={handleDeleteAddress}>Confirm</ButtonPrimary>
      </div>
    </ModalCustom>
  )
}

export default ModalRemoveAddress
