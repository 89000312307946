import React, { useMemo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Col, Form, Input, Row } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import './styles.scss'
// import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import AuthCard from 'modules/auth/components/AuthCard'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { postSignup } from 'state/auth/actions'
import VerifyActiveAccountCode from 'modules/auth/components/VerifyActiveAccountCode'
import { getListCountry } from 'state/app/actions'
import ControlInputSelect from 'components/ControlInputSelect'
import { RegexMail, regexPhone } from '../../../../utils/Regex'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import PointSecurity from 'components/PointSecurity'
import { executeRecaptchaV2 } from 'helpers'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const ROOT_SPONSOR = ''

const SignupV2 = ({ intl, history, location }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { listCountry } = useSelector((state) => state.app)
  const [view, setView] = useState(history.location.state?.view || 0)
  const [email, setEmail] = useState(history.location.state?.email || null)
  const [inputCountry, setInputCountry] = useState('')
  const [loading, setLoading] = useState(false)
  const [checkPassword, setCheckPassword] = useState(1)

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  useEffect(() => {
    const url = new URLSearchParams(location.search)
    const sponsorKey = url.get('sponsorKey') || url.get('sponsorkey')
    form.setFieldsValue({
      sponsorKey: sponsorKey || ROOT_SPONSOR,
    })
  }, [form, location])

  const dataSelectCountry = useMemo(() => {
    if (listCountry) {
      return listCountry.map((item) => ({
        ...item,
        label: item.title,
        value: item.code,
      }))
    }
    return []
  }, [listCountry])

  useEffect(() => {
    setInputCountry(dataSelectCountry[0]?.title)
  }, [])

  const onFormSubmit = () => {
    setLoading(true)

    form.validateFields().then((values) => {
      if (!values.password || !values.confirm) return
      if (values.password !== values.confirm) return

      delete values.confirm
      const formData = {
        ...values,
        country: inputCountry,
      }

      setEmail(values.email)

      dispatch(
        postSignup(
          formData,
          () => {
            setView(1)
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    })

    // form.validateFields().then((values) => {
    //   if (!values.password || !values.confirm) return
    //   if (values.password !== values.confirm) return

    //   delete values.confirm
    //   const formData = {
    //     ...values,
    //   }

    //   setEmail(values.email)

    //   dispatch(
    //     postSignup(
    //       formData,
    //       () => {
    //         setView(1)
    //         setLoading(false)
    //       },
    //       () => {
    //         setLoading(false)
    //       },
    //     ),
    //   )
    // })
  }
  const checkPasswordStrength = (password) => {
    const regex = {
      weak: /^.{9,}$/,
      medium: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/g,
      strong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])[^\s]{10,}$/g,
      veryStrong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])(?=.*[_!@#$%^&*])[^\s]{12,}$/g,
    }

    if (regex.veryStrong.test(password)) {
      return 4
    }
    if (regex.strong.test(password)) {
      return 3
    }
    if (regex.medium.test(password)) {
      return 2
    }
    return 1
  }
  const validateStrength = (e) => {
    const checkStrength = checkPasswordStrength(e.target.value)
    setCheckPassword(checkStrength)
  }
  return (
    <AuthWrapper>
      <div className="wrapper-form-signup-v2">
        {view === 0 ? (
          <AuthCard className="form-signup fadeIn">
            <div className="form-signup-container">
              <Form layout="vertical" form={form} onFinish={onFormSubmit}>
                <div className="sign-up-info">
                  <div className="person-info">
                    <h2 className="sign-up-title">Personal Information</h2>
                    <Row gutter={[32, 16]}>
                      <Col sm={{ span: 12 }} span={24}>
                        <FormItem
                          label={<span className="form-title">First name</span>}
                          name="fistname"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter fistname field',
                            },
                          ]}
                        >
                          <Input placeholder="Please enter your first name" type="text" />
                        </FormItem>
                      </Col>
                      <Col sm={{ span: 12 }} span={24}>
                        <FormItem
                          label={<span className="form-title">Last name</span>}
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter lastname field',
                            },
                          ]}
                        >
                          <Input placeholder="Please enter your last name" type="text" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label={<span className="form-title">Email</span>}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter email field',
                            },
                            { pattern: RegexMail.email, message: 'Invalid Mail' },
                          ]}
                        >
                          <Input placeholder="evmtracker1@gmail.com" />
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                  <div className="account-secutity">
                    <h2 className="sign-up-title">Account Security</h2>
                    <Row gutter={[32, 16]}>
                      <Col span={24} sm={{ span: 12 }}>
                        <FormItem
                          label={
                            <div className="password-checked">
                              <span className="form-title">Password</span>
                              <div className="check-length">
                                <PointSecurity point={checkPassword} />
                              </div>
                            </div>
                          }
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter password field',
                            },
                            {
                              min: 10,
                              message: `The 'password' field length must be greater than or equal to 10 characters long.`,
                            },
                          ]}
                        >
                          {/* <Input placeholder="**********" type="password" /> */}
                          <Input.Password
                            onChange={validateStrength}
                            placeholder="**********"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                          />
                        </FormItem>
                      </Col>
                      <Col sm={{ span: 12 }} span={24}>
                        <FormItem
                          label={<span className="form-title">Confirm Password</span>}
                          name="confirm"
                          dependencies={['password']}
                          rules={[
                            {
                              required: true,
                              message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'))
                              },
                            }),
                          ]}
                        >
                          {/* <Input placeholder="**********" type="password" /> */}
                          <Input.Password
                            placeholder="**********"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <FormItem
                      className="form-item-checkbox"
                      name="agreement"
                      formType="checkbox"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                        },
                      ]}
                    >
                      <Checkbox>I have read and agree to User Agreement and Privacy Policy</Checkbox>
                    </FormItem>
                    <FormItem>
                      <ButtonPrimary type="submit" className="form-action" loading={loading}>
                        Register
                      </ButtonPrimary>
                    </FormItem>
                  </div>
                </div>
              </Form>

              <div className="form-links">
                <p>
                  Already registered with us?
                  <span>
                    <Link to="/login">Sign In</Link>
                  </span>
                  &nbsp;
                  <span>or</span>
                  <span>
                    <Link to="/forgot-password">Reset Password</Link>
                  </span>
                </p>
                {/* <span>Already registered with us?</span>
                <Link to="/login">Sign In</Link>
                <span style={{ marginLeft: '5px' }}>or</span>
                <Link to="/forgot-password">Reset Password</Link> */}
              </div>
            </div>
          </AuthCard>
        ) : (
          <VerifyActiveAccountCode email={email} token={view?.data?.token} />
        )}
      </div>
    </AuthWrapper>
  )
}

export default withGoogleReCaptcha(injectIntl(SignupV2))
