import { Input, Checkbox, Divider, Form } from 'antd'
import BackLink from 'components/BackLink'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createAddress, getAddressList } from 'state/address/actions'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'
import { executeRecaptchaV2 } from 'helpers'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const CheckboxGroup = Checkbox.Group
const plainOptionObject = { '- From': 'From', '- To': 'To' }
const plainOptions = ['- From', '- To']
const defaultCheckedList = []
const Addresses = ({ titleAddress, projectInfo, currentTracking }) => {
  const addressList = useSelector((state) => state.address)
  const [checkedList, setCheckedList] = useState(defaultCheckedList)
  const [indeterminate, setIndeterminate] = useState(true)
  const [form] = Form.useForm()
  const [checkAll, setCheckAll] = useState(false)
  const history = useHistory()
  const projectId = history.location.state?.project_id
  const chainCode = history.location.state?.chain_code
  const dispatch = useDispatch()
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    txhash: '',
    type: '',
    from_date: '',
    to_date: '',
    chain_code: '',
    currency: '',
    status_url: false,
  })

  useEffect(() => {
    dispatch(getAddressList({ project_id: projectId, chain_code: chainCode }))
    // setCurrencySorting(addressList[projectInfo?._id]?.rows)
  }, [dispatch, projectId])

  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
    // console.log(e.target)
  }
  const handleSync = () => {
    form.validateFields().then((values) => {
      if (checkedList.length !== 0) {
        // console.log(checkedList.map((str) => str.replace('- ', ''))[0].toUpperCase())
        executeRecaptchaV2()
          .then((tokenCaptcha) => {
            if (tokenCaptcha) {
              dispatch(
                createAddress(
                  {
                    project_id: projectInfo?._id,
                    chain_code: chainCode,
                    // transaction_watcher: checkAll ? 'BOTH' : checkedList?.[0]?.toUpperCase(),
                    transaction_watcher: checkAll ? 'BOTH' : checkedList.map((str) => str.replace('- ', ''))[0].toUpperCase(),
                    address: [values.addresses],
                    recaptcha: tokenCaptcha,
                  },
                  () => {
                    form.resetFields()
                    dispatch(getAddressList({ project_id: projectInfo?._id, txParams, chain_code: chainCode }))
                  },
                ),
              )
            }
          })
          .catch((err) => {})
      }

      // dispatch(
      //   createAddress(
      //     {
      //       project_id: projectInfo?._id,
      //       chain_code: chainCode,
      //       // transaction_watcher: checkAll ? 'BOTH' : checkedList?.[0]?.toUpperCase(),
      //       transaction_watcher: checkAll ? 'BOTH' : checkedList.map((str) => str.replace('- ', '')).toUpperCase(),
      //       address: [values.addresses],
      //     },
      //     () => {
      //       form.resetFields()
      //       dispatch(getAddressList({ project_id: projectInfo?._id, txParams }))
      //     },
      //   ),
      // )
    })
  }

  const addressTitle = {
    BEP20: 'BSC',
    ETH: 'ETH',
    POLYGON: 'POLYGON',
  }

  return (
    <div className="addresses-wrapper">
      <Form form={form}>
        <div className="addresses-top">
          <div className="addresses-top_img">
            <img src={projectInfo?.logo || '/images/trackerEdit/avatar.png'} alt="" />
          </div>
          <div className="addresses-top_content">
            <p>{projectInfo?.title}</p>
            <div className="addresses-top_content_mobile">
              <p>
                Current Tracking: <span>{currentTracking}</span>
              </p>

              <p className="line" />
              <p>
                Limit Tracking: <span>{projectInfo?.limit_address}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="addresses-title">
          <BackLink
            icon={<img src="/images/settings/left-icon.png" alt="" />}
            label={`${titleAddress} Address`}
            href={`/project/manage/${projectInfo?._id}`}
          />
        </div>

        <div className="addresses-sync">
          <div className="sync-title">
            <span>Address: </span>
          </div>
          <div className="sync-input">
            <Form.Item name="addresses">
              <Input placeholder="Input address" />
            </Form.Item>
          </div>

          <div className="sync-btn">
            <ButtonSecondary onClick={handleSync}>Sync</ButtonSecondary>
          </div>
        </div>
        <div className="adresses-trans-watcher">
          <div className="trans-watcher-title">
            <span>Transaction Watcher:</span>
          </div>
          <div className="trans-watcher-checked">
            {/* <Form.Item className="valid-checkbox" name="checkbox">
              <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
              
            </Form.Item> */}
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />

            <div className="checked-both">
              {/* <Form.Item name="checkbox" rules={[{ required: true, message: 'Please agree to the terms and conditions.' }]}>
                <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                  Both
                </Checkbox>
              </Form.Item> */}

              <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                - Both
              </Checkbox>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default withGoogleReCaptcha(Addresses)
