import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { G_SITE_KEY } from 'constants/index'

export function executeRecaptcha(CBSuccess, CBError) {
  // console.log('recaptcha')
  CBSuccess('data')
}

export function executeRecaptchaV2() {
  return new Promise((resolve, reject) => {
    window.grecaptcha
      .execute(G_SITE_KEY, { action: 'submit' })
      .then((recaptcha) => {
        resolve({ status: true, recaptcha })
      })
      .catch((error) => {
        toast.error('Execute google recaptcha error. Please try again late.')
        reject(error)
      })

    try {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(G_SITE_KEY, { action: 'submit' })
          .then((recaptcha) => {
            resolve({ status: true, recaptcha })
          })
          .catch((error) => {
            toast.error('Execute google recaptcha error. Please try again late.')
            reject(error)
          })
      })
    } catch (error) {
      // console.log('error', error)
      reject(error)
    }
  })
}

// export function useConfigRecaptcha() {
//   /**
//    * @Googlerecapcha
//    * @param
//    * @G_SITE_KEY string
//    */
//   useEffect(() => {
//     const loadScriptByURL = (id, url, callback) => {
//       const isScriptExist = document.getElementById(id)

//       if (!isScriptExist) {
//         const script = document.createElement('script')
//         script.type = 'text/javascript'
//         script.src = url
//         script.id = id
//         script.onload = () => {
//           if (callback) callback()
//         }
//         document.body.appendChild(script)
//       }

//       if (isScriptExist && callback) callback()
//     }

//     // load the script by passing the URL
//     loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${G_SITE_KEY}`, () => {
//       console.log('Recaptcha config success!')
//     })
//   }, [])
// }
