import React from 'react'
import { formatDate } from 'helpers'
import Nodata from 'components/Nodata'
import TableActive from './TableActive'
import ItemActive from './itemActive'
import './styles.scss'

const columns = [
  {
    title: <div className="columns-title left-align">Date</div>,
    dataIndex: 'last_login',
    render: (text) => (
      <div className="columns-txt txt-col">
        <div>{formatDate(text * 1000).slice(0, 10)}</div>
        <div>{formatDate(text * 1000).slice(-8, 19)}</div>
      </div>
    ),
  },
  {
    title: <div className="columns-title center-align">IP Adress</div>,
    dataIndex: 'ip',
    render: (ip) => <div className="columns-txt centered">{ip}</div>,
  },
  {
    title: <div className="columns-title center-align">Location</div>,
    dataIndex: 'location',
    render: (location) => <div className="columns-txt centered">{location || '-'}</div>,
  },
  {
    title: <div className="columns-title center-align">Device</div>,
    dataIndex: 'os',
    render: (os) => <div className="columns-txt centered">{os}</div>,
  },
  // {
  //   title: <div className="columns-title">Actions</div>,
  //   render: () => <a className="columns-txt actions">Log out</a>,
  // },
]

export const HistoryTable = ({ historyLogin, isMobile }) => {
  if (!historyLogin) return <></>

  return (
    <>
      {isMobile ? (
        <div className="history-table">
          {historyLogin.rows?.length > 0 ? (
            historyLogin.rows?.slice(0, 8).map((item) => <ItemActive data={item} key={item._id} />)
          ) : (
            <Nodata />
          )}
        </div>
      ) : (
        <TableActive dataSource={historyLogin.rows} scroll={{ y: 544 }} columns={columns} rowKey={(record) => record._id} />
        // <Table columns={columns} dataSource={historyLogin.rows} scroll={{ y: 544 }} pagination={false} />
      )}
    </>
  )
}
