import messagesEn from './translations/en.json'
import messagesVi from './translations/vi.json'

export const CACHE_LANGUAGE = 'language'
export const DEFAULT_LANGUAGE = 'EN'

export const languages = {
  EN: messagesEn,
  // vi: messagesVi,
}

export const OPTIONS_LANG = [
  { id: 'EN', label: 'English', image: '/images/flags/en.png' },
  // {
  //   id: 'vi',
  //   label: 'Tiếng việt',
  //   image: '/images/flags/vn.png',
  // },
]

export function getLanguage(lang) {
  return OPTIONS_LANG.find((language) => language.code === lang)
}
