import React from 'react'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import { formatCode, formatDate, roundNumber } from 'helpers'
import { DECIMALS } from 'constants/index'
import { getBscScanLink } from 'utils'

const ItemTransaction = ({ data, setViewDetails, setModalVerifyCode, ...props }) => (
  <div className="item-recent-transaction-1" {...props}>
    <div>
      <p>TransactionID</p>
      {/* <p>
        {data.status === 'COMPLETED' ? (
          <span href={getBscScanLink(data._id, 'transaction', data.chain)} target="_blank" rel="noreferrer">
            {formatCode(data._id, 5, 5)}
          </span>
        ) : (
          data.status
        )}
      </p> */}
      <p className="trans-id">{formatCode(data._id, 5, 5)}</p>
    </div>
    <div>
      <p>Status</p>
      <p data-status={data.status}>
        <span>{data.status}</span>
      </p>
    </div>
    <div>
      <p>Action</p>
      <p>{data.action}</p>
    </div>
    <div>
      <p>Amount</p>
      <p className="item-recent-transaction-amount">
        $
        <CurrencyFormat
          value={roundNumber(data.amount / 10 ** DECIMALS, 6)}
          displayType="text"
          thousandSeparator
          renderText={(v) => v}
        />
      </p>
    </div>
    <div>
      <p>Method</p>
      <p>{data.payment_method || '-'}</p>
    </div>

    {/* <div>
        <p>Fee</p>
        <p>{data.fee / 10 ** DECIMALS}</p>
      </div> */}
    <div>
      <p>Date</p>
      <p>{formatDate(data.createdAt * 1000)}</p>
    </div>
    <div style={{ justifyContent: 'flex-end' }}>
      {data.status !== 'CREATED' ? (
        <a
          className="item-recent-transaction-view"
          role="presentation"
          onClick={() => setViewDetails({ toggle: true, dataModal: data })}
        >
          View
        </a>
      ) : (
        <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(data)}>
          Verify
        </a>
      )}
    </div>
  </div>
)

export default ItemTransaction
