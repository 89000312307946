import React from 'react'
import { Spin } from 'antd'
import './styles.scss'

/**
 * @Dev
 * @param {@} className
 * @param {@} size small |standard | larger
 */

const ButtonPrimary = ({ className, size, disabled, loading, arrow, onClick, children, ...props }) => (
  <button
    className={`button-primary ${className}`}
    type="button"
    disabled={disabled}
    data-size={size ? size.toString() : 'standard'}
    data-loading={loading.toString()}
    onClick={() => {
      if (!loading && !disabled) {
        onClick()
      }
    }}
    {...props}
  >
    {children}
    {arrow}
    <Spin spinning={loading} />
  </button>
)

ButtonPrimary.defaultProps = {
  className: '',
  arrow: '',
  disabled: false,
  loading: false,
  onClick: () => null,
}

export default ButtonPrimary
