export const GET_PRIVATE_SALE_LIST = 'GET_PRIVATE_SALE_LIST'
export const GET_PRIVATE_SALE_LIST_SUCCESS = 'GET_PRIVATE_SALE_LIST_SUCCESS'

export const GET_PRIVATE_SALE_DETAIL = 'GET_PRIVATE_SALE_DETAIL'
export const GET_PRIVATE_SALE_DETAIL_SUCCESS = 'GET_PRIVATE_SALE_DETAIL_SUCCESS'

export const BUY_PRIVATE_SALE = 'BUY_PRIVATE_SALE'

export const GET_PRIVATE_TRANSACTION = 'GET_PRIVATE_TRANSACTION'
export const GET_PRIVATE_TRANSACTION_SUCCESS = 'GET_PRIVATE_TRANSACTION_SUCCESS'
