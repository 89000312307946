import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './styles.scss'
import TitleSection from '../titleSection'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { Col, Row } from 'antd'
import ButtonSecondary from 'widgets/ButtonSecondary'
import useIsMobile from 'hooks/useIsMobile'
import { CHPlayIcon } from 'widgets/Svg'
import RealTimeTracking from '../RealTimeTracking'

const listCrypto = [
  {
    id: 1,
    des: 'Realtime tracking transaction from/to easily',
  },
  {
    id: 2,
    des: ' Unlimited Token (Crypto Currency) supported ',
  },
  {
    id: 3,
    des: 'Cheapest cost per active address',
  },
  {
    id: 4,
    des: 'No need to watch event logs from blockchain rpc',
  },
  {
    id: 5,
    des: 'Lowest blockchain knowledge is required',
  },
  {
    id: 6,
    des: 'Zero maintenance cost',
  },
]

const Example = () => {
  const { userInfo } = useSelector((state) => state.user)
  const isMobile = useIsMobile()

  return (
    <div className="warp-exp">
      <TitleSection title="EXAMPLE USE CASE" />
      <div className="ant_row">
        <div className="col-left">
          <h2>Centralize Crypto Wallet</h2>
          <ul style={{ color: '#000000' }}>
            {listCrypto.map((item) => (
              <li key={item.id}>{item.des}</li>
            ))}
          </ul>
          <a href="https://docs.evmtracker.com/evm-tracker/" target="blank">
            <ButtonPrimary>{'Learn More >>'}</ButtonPrimary>
          </a>
        </div>
        <div className="col-right">
          <img src="/images/Home/exp/exp.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Example
