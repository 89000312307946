import { Select } from 'antd'
import React from 'react'
import './styles.scss'

const TablePagination = ({ total, page, pageSize, onChange, onChangeShow = () => {}, disableShow = false }) => {
  const totalPage = total % pageSize > 0 ? Math.floor(total / pageSize) + 1 : Math.floor(total / pageSize)
  return (
    <div className="pagination-wrapper">
      {!disableShow && (
        <div className="show-record">
          <span className="show">Show</span>
          <Select
            dropdownClassName="select-show-history"
            defaultValue={10}
            style={{ width: 70 }}
            onChange={(value) => onChangeShow(value)}
            options={[
              {
                value: 10,
                label: '10',
              },
              {
                value: 25,
                label: '25',
              },
              {
                value: 50,
                label: '50',
              },
              {
                value: 100,
                label: '100',
              },
            ]}
          />
          <span className="records">entries</span>
        </div>
      )}
      <div className={`pagination ${disableShow ? 'none-show-records' : 'have-show-records'}`}>
        {/* <button type="button" className="pagi-item first" disabled={page <= 1} onClick={() => onChange('first')}>
          First
        </button> */}
        <button type="button" className="pagi-item previous" disabled={page <= 1} onClick={() => onChange('previous')}>
          <span>previous</span>
        </button>
        <div className="number">
          <p>{page || '1'}</p>
        </div>
        <button type="button" className="pagi-item next" disabled={page >= totalPage} onClick={() => onChange('next')}>
          <span>next</span>
        </button>
        {/* <button type="button" className="pagi-item last" disabled={page >= totalPage} onClick={() => onChange('last')}>
          Last
        </button> */}
      </div>
    </div>
  )
}

export default TablePagination
