import * as types from './constants'

export const createProject = (payload, cbs, cbe) => ({
  type: types.CREATE_PROJECT,
  payload,
  cbs,
  cbe,
})
export const createProjectSuccess = (payload) => ({
  type: types.CREATE_PROJECT_SUCCESS,
  payload,
})
export const getDetailProject = (payload, cbs, cbe) => ({
  type: types.GET_PROJECT_DETAIL,
  payload,
  cbs,
  cbe,
})
export const getProjecDetailSuccess = (payload) => ({
  type: types.GET_PROJECT_DETAIL_SUCCESS,
  payload,
})
export const updateProject = (payload, cbs, cbe) => ({
  type: types.UPDATE_PROJECT,
  payload,
  cbs,
  cbe,
})
export const updateProjectSuccess = (payload) => ({
  type: types.UPDATE_PROJECT_SUCCESS,
  payload,
})
export const renewProject = (payload, cbs, cbe) => ({
  type: types.RENEW_PROJECT,
  payload,
  cbs,
  cbe,
})
export const renewProjectSuccess = (payload) => ({
  type: types.RENEW_PROJECT_SUCCESS,
  payload,
})
export const deleteProject = (payload, cbs, cbe) => ({
  type: types.DELETE_PROJECT,
  payload,
  cbs,
  cbe,
})
export const verifyDelete = (payload, cbs, cbe) => ({
  type: types.VERIFY_DELETE,
  payload,
  cbs,
  cbe,
})
export const generateKey = (payload, cbs, cbe) => ({
  type: types.GENERATE_KEY,
  payload,
  cbs,
  cbe,
})
export const verifySecretKey = (payload, cbs, cbe) => ({
  type: types.VERIFY_SECRETKEY,
  payload,
  cbs,
  cbe,
})
