import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg {...props}">
    <path
      d="M10.7721 0C2.59393 0 0.136072 7.08964 0.113572 7.16143L0 7.5L0.1125 7.83857C0.136072 7.91036 2.59393 15 10.7721 15C18.9504 15 21.4082 7.91036 21.4307 7.83857L21.5443 7.5L21.4318 7.16143C21.4082 7.08964 18.9504 0 10.7721 0ZM10.7721 11.7857C8.40857 11.7857 6.48643 9.86357 6.48643 7.5C6.48643 5.13643 8.40857 3.21429 10.7721 3.21429C13.1357 3.21429 15.0579 5.13643 15.0579 7.5C15.0579 9.86357 13.1357 11.7857 10.7721 11.7857Z"
      fill="#F3F8FF"
    />
    <path
      d="M10.7718 5.35712C9.61033 5.35712 8.62891 6.33855 8.62891 7.49997C8.62891 8.6614 9.61033 9.64283 10.7718 9.64283C11.9332 9.64283 12.9146 8.6614 12.9146 7.49997C12.9146 6.33855 11.9332 5.35712 10.7718 5.35712Z"
      fill="#F3F8FF"
    />
  </Svg>
)

export default Icon
