import * as types from './constants'

export const getPackgeList = (payload) => ({
  type: types.GET_PACKAGES_LIST,
  payload,
})
export const getPackageListSuccess = (payload) => ({
  type: types.GET_PACKAGES_LIST_SUCCESS,
  payload,
})
export const getPackageStatus = (payload, cbs, cbe) => ({
  type: types.GET_PACKAGE_STATUS,
  payload,
  cbs,
  cbe,
})
export const getPackageStatusSuccess = (payload) => ({
  type: types.GET_PACKAGE_STATUS_SUCCESS,
  payload,
})
