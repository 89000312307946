import './styles.scss'
import React from 'react'
import SelectWithImage from 'widgets/SelectWithImage'

const ControlInputSelect = ({ showSearch, label, dataSelect, placeholder, defaultValue, ...props }) => (
  <div className="input-select-item">
    {label ? <p>{label}</p> : ''}
    <SelectWithImage label={label} placeholder={placeholder} defaultValue={defaultValue} dataSelect={dataSelect} {...props} />
  </div>
)

export default React.memo(ControlInputSelect)
