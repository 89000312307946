import produce from 'immer'
import * as Types from './constants'

const initialState = {
  orderList: {
    data: undefined,
    page: 0,
    total: 0,
  },
  packageList: {
    data: undefined,
    page: 0,
    total: 0,
  },
  orderReport: '',
}

export default function StakeReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_ORDER_LIST_SUCCESS:
        draft.orderList = payload
        break
      case Types.GET_PACKAGE_LIST_SUCCESS:
        draft.packageList = payload
        break
      case Types.GET_ORDER_STAKE_REPORT_SUCCESS:
        draft.orderReport = payload
        break
      default:
        return { ...state }
    }
  })
}
