import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.74512 5.49222V21.1288C2.74488 22.6759 3.33312 24.1652 4.39053 25.2946C5.44793 26.424 6.89532 27.1089 8.43912 27.2103L8.83887 27.2225H19.598C19.346 27.9353 18.8792 28.5524 18.262 28.9889C17.6448 29.4255 16.9074 29.6599 16.1514 29.66H7.62012C5.68072 29.66 3.82076 28.8896 2.4494 27.5183C1.07804 26.1469 0.307618 24.2869 0.307618 22.3475V8.94128C0.307221 8.18486 0.541441 7.44694 0.977998 6.82921C1.41456 6.21148 2.03196 5.74436 2.74512 5.49222ZM21.0264 0.410034C21.9961 0.410034 22.926 0.795245 23.6117 1.48093C24.2974 2.1666 24.6826 3.09659 24.6826 4.06628V21.1288C24.6826 22.0985 24.2974 23.0285 23.6117 23.7141C22.926 24.3998 21.9961 24.785 21.0264 24.785H8.83887C7.86917 24.785 6.93919 24.3998 6.25351 23.7141C5.56783 23.0285 5.18262 22.0985 5.18262 21.1288V4.06628C5.18262 3.09659 5.56783 2.1666 6.25351 1.48093C6.93919 0.795245 7.86917 0.410034 8.83887 0.410034H21.0264ZM21.0264 2.84753H8.83887C8.51564 2.84753 8.20564 2.97594 7.97708 3.2045C7.74852 3.43306 7.62012 3.74305 7.62012 4.06628V21.1288C7.62012 21.452 7.74852 21.762 7.97708 21.9906C8.20564 22.2191 8.51564 22.3475 8.83887 22.3475H21.0264C21.3496 22.3475 21.6596 22.2191 21.8882 21.9906C22.1167 21.762 22.2451 21.452 22.2451 21.1288V4.06628C22.2451 3.74305 22.1167 3.43306 21.8882 3.2045C21.6596 2.97594 21.3496 2.84753 21.0264 2.84753Z"
      fill="#003e9d"
    />
  </Svg>
)

export default Icon
