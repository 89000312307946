import * as types from './constants'

export const subscription = (payload, cbs, cbe) => ({
  type: types.SUBSCRIPTION,
  payload,
  cbs,
  cbe,
})
export const upgradeSubscription = (payload, cbs, cbe) => ({
  type: types.UPGRADE_SUBSCRIPTION,
  payload,
  cbs,
  cbe,
})
export const subscriptionSuccess = (payload) => ({
  type: types.SUBSCRIPTION_SUCCESS,
  payload,
})

export const approveSubscription = (payload, cbs, cbe) => ({
  type: types.APPROVE_SUBSCRIPTION,
  payload,
  cbs,
  cbe,
})
