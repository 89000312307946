import { Tabs } from 'antd'
import Page from 'components/App/Page'
import Skeleton from 'components/Skeleton'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getPackgeList } from 'state/package/actions'
import PackagePayment from './components/package'
import './styles.scss'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const SelectPackage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { state } = history.location
  const { rows } = useSelector((state) => state.package.packagesList)
  const [isUpgrade, setIsUpgrade] = useState({ hasData: false, idProjectUpdate: null })
  const [time, setTime] = useState('YEAR')
  const timeNow = Math.floor(Date.now() / 1000)
  const startDate = timeNow
  let endDate
  if (time === 'MONTH') {
    const setTime = timeNow * 1000
    const date = new Date(setTime)
    date.setMonth(date.getMonth() + 1)
    endDate = date.getTime()
  } else if (time === 'YEAR') {
    const setTime = timeNow * 1000
    const date = new Date(setTime)
    date.setFullYear(date.getFullYear() + 1)
    endDate = date.getTime()
  }
  useEffect(() => {
    if (state?.type === 'upgrade') {
      setIsUpgrade({ hasData: true, idProjectUpdate: state.packageCurrent?._id })
    }
  }, [state])
  useEffect(() => {
    dispatch(getPackgeList())
  }, [dispatch])
  const handleRegisterFreePackage = () => {
    const result = rows.filter((item) => item.title === 'Free')
    history.push({ pathname: '/project/create', state: { packageCurrent: result[0], type: time, startDate, endDate } })
  }
  const newRowsProject = rows?.filter((item) => item.title !== 'Free' && !item.is_custom)
  return (
    <Page>
      <div className="package-wrapper">
        <div className="package-history-link" role="presentation" onClick={() => history.push('/project/history')}>
          <p>History</p>
        </div>

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane
            tab={
              <div className="package-tab" role="presentation" onClick={() => setTime('YEAR')}>
                <span>Yearly Payment</span>
              </div>
            }
            key="1"
          >
            {newRowsProject ? (
              newRowsProject.map((item) => (
                <PackagePayment
                  key={item._id}
                  data={item}
                  img="/images/package/starter.svg"
                  isUpgrade={isUpgrade}
                  packageLevel={state?.levelPackage}
                  projectId={state?.projectId}
                  updateName={state?.updateName}
                  time="YEAR"
                  nameCurrent={state?.nameCurrent}
                  hasPackageFree={state?.hasPackageFree}
                  action={state?.action}
                />
              ))
            ) : (
              <Skeleton height="240px" />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div className="package-tab" role="presentation" onClick={() => setTime('MONTH')}>
                <span>Monthly Payment</span>
              </div>
            }
            key="2"
          >
            {newRowsProject ? (
              newRowsProject.map((item) => (
                <PackagePayment
                  key={item._id}
                  isUpgrade={isUpgrade}
                  data={item}
                  img="/images/package/starter.svg"
                  projectId={state?.projectId}
                  updateName={state?.updateName}
                  packageLevel={state?.levelPackage}
                  nameCurrent={state?.nameCurrent}
                  time="MONTH"
                  hasPackageFree={state?.hasPackageFree}
                  action={state?.action}
                />
              ))
            ) : (
              <Skeleton height="240px" />
            )}
          </Tabs.TabPane>
        </Tabs>
        {!state?.hasPackageFree && (
          <div className="free-limit-address">
            <p role="presentation" style={{ cursor: 'pointer' }} onClick={handleRegisterFreePackage}>
              Or using free for limit at 10 addresses {'>'}
              {'>'}
            </p>
          </div>
        )}
      </div>
    </Page>
  )
}

export default withGoogleReCaptcha(SelectPackage)
