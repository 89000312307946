import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { linksNavigatorMobile } from 'components/Menu/config'
import './style.scss'

import { LogoutIcon, MoreIcon } from 'widgets/Svg'
import { useDispatch } from 'react-redux'
import { logout } from 'state/auth/actions'

const AVAILABLE_LINK = []
linksNavigatorMobile.forEach((item) => {
  AVAILABLE_LINK.push(item.href)
})

const NavigationMobile = ({ toggleMenu }) => {
  const history = useHistory()
  const path = history.location.pathname
  const [activePath, setActivePath] = useState(path)
  const dispatch = useDispatch()

  useEffect(() => {
    if (AVAILABLE_LINK.includes(path)) {
      setActivePath(path)
    }
  }, [path])

  const splitPathname = path.split('/').map((o) => `/${o}`)
  return (
    <div className="navigation-wrapper">
      <div className="navigation-mobile">
        <ul>
          {linksNavigatorMobile.map((item, index) => {
            const isHttp = item?.href?.startsWith('http')
            const Tag = isHttp ? 'a' : Link
            const propsLink = isHttp ? { href: item.href, target: item.target, relL: 'noreferrer' } : { to: item.href }
            const Icon = item.icon
            return (
              <li
                className={`list ${splitPathname.includes(item.href.toLowerCase()) ? 'active' : ''}`}
                key={`navigator-${index}`}
                role="presentation"
              >
                <Tag {...propsLink}>
                  <div className="border-icon">
                    <Icon />
                  </div>
                  <span>{item.label}</span>
                </Tag>
              </li>
            )
          })}
          <li role="presentation" className="list logout-mobile" onClick={() => dispatch(logout())}>
            {/* <div className="border-icon">
              <LogoutIcon />
            </div> */}
            <a>
              <div className="border-icon">
                <LogoutIcon />
              </div>
            </a>
          </li>
          <div className="indicator" />
        </ul>
      </div>
    </div>
  )
}

export default NavigationMobile
